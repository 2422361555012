(function() {
    'use strict';

    angular
        .module('inventory')
        .controller('InventoryController', Controller)
        .controller('InventoryWarningController', InventoryWarningController)
        .controller('InventoryQtyBasedController', InventoryQtyBasedController)
        .controller('InventoryHistoryController', historyController)
        .controller('InventoryStockInController', stockInController)
        .controller('InventoryStockTakeController', stockTakeController)
        .controller('InventoryForecastController', forecastController)
        .controller('RawMaterialForecastController', rawMaterialForecastController)
        .controller('MarketingInputController', marketingInputController)
        .controller('RawMaterialController', rawMaterialController)
        .controller('MaterialBookingController', MaterialBookingController)
        .controller('StockReservationController', stockReservationController)
        .controller('StockTransitController', stockTransitController)
        .controller('StockTransitBatchController', stockTransitBatchController)
        .controller('PrintingStockTransitController', printingStockTransitController)
        .controller('POItemController', poItemController)
        .controller('POController', poController)
        .controller('ProductController', productController)
        .controller('ProductTagController', productTagController)
        .controller('BOMController', BOMController)
        .controller('DSAController', DSAController)
        .controller('CustomFilterController', CustomFilterController)
        .controller('RawMaterialTagController', RawMaterialTagController)
        .controller('ReorderRequestController', ReorderRequestController)
        .controller('ProductLeadTimeController', productLeadTimeController)
        .controller('StockInSessionController', StockInSessionController)
        .controller('InputSessionController', InputSessionController)
        .controller('FinishedGoodsMappingController', FinishedGoodsMappingController)
        .controller('StockOutSessionController', StockOutSessionController)
        .controller('InputSessionController', InputSessionController)
        .controller('RobotInventoryController', RobotInventoryController)
        .controller('StockTransitBulkActionController', StockTransitBulkActionController);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    InventoryWarningController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    InventoryQtyBasedController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    MaterialBookingController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    stockReservationController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup', '$window'];
    stockTransitController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    historyController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    stockInController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    stockTakeController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    forecastController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    rawMaterialForecastController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    marketingInputController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    rawMaterialController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    poItemController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'XeroService'];
    poController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'XeroService', 'Popup'];
    productController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    productTagController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    BOMController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    productLeadTimeController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    DSAController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    ReorderRequestController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    StockInSessionController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    StockOutSessionController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    InputSessionController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    FinishedGoodsMappingController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$interval'];

    RobotInventoryController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$interval'];
    StockTransitBulkActionController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$interval'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {
        var serverGateway, vm = this, chartId = 'chart1', $chartContainer = $('#' + chartId);

        vm.userId = config.userId;
        vm.locationId = config.locationId;
        vm.requireWarning = config.requireWarning;
        vm.submit            = submit;
        vm.rows              = [];
        vm.sorts             = [];
        vm.columns           = config.columns;
        vm.setFilter         = PageViewModel.setFilter;
        vm.clearFilter       = PageViewModel.clearFilter;
        vm.sortChange        = PageViewModel.sortChange;
        vm.isLoading         = PageViewModel._isLoading;
        vm.openDetailDialog  = openDetailDialog;
        vm.isDeliveryNoteEmpty = isDeliveryNoteEmpty;
        vm.detail              = {};
        vm.initialize          = initialize;
        vm.canViewForecast     = config.canViewForecast;
        vm.canInout            = config.canInout;
        vm.canUpdateQTY        = config.canUpdateQTY;
        vm.canDeleteRecord     = config.canDeleteRecord;
        vm.canUpdateWarning     = config.canUpdateWarning;
        vm.canTransferStock     = config.canTransferStock;
        vm.canPrintLabel     = config.canPrintLabel;
        vm.canViewHistory     = config.canViewHistory;
        vm.stationList = config.stationList;
        vm.station = config.stationList[0].key;
        vm.printer          = config.printerList[0].key;

        vm.locations             = config.locations;
        vm.printerList           = config.printerList;
        vm.showStockIn           = showStockIn;
        vm.showStockOut          = showStockOut;
        vm.showInoutRecord       = showInoutRecord;
        vm.showUpdateQTY         = showUpdateQTY;
        vm.showWarningLevel = showWarningLevel;
        vm.showStockTransfer         = showStockTransfer;
        vm.showInventoryForecast = showInventoryForecast;
        vm.showPrintLabel = showPrintLabel;
        vm.showInoutRecordLabel = showInoutRecordLabel;
        vm.showScan = showScan;
        vm.scanStockin = scanStockin;
        vm.scanStockout = scanStockout;
        vm.scanHistory = scanHistory;
        vm.scanSearch = scanSearch;

        vm.performStockIn              = performStockIn;
        vm.performStockInUpdate        = performStockInUpdate;
        vm.performStockInWarningCancel = performStockInWarningCancel;
        vm.editInoutRecord             = editInoutRecord;
        vm.deleteInOutRecord           = deleteInOutRecord;
        vm.performUpdateQTY            = performUpdateQTY;
        vm.performStockTransfer = performStockTransfer;
        vm.printLabel = printLabel;
        vm.printInoutLabel = printInoutLabel;
        vm.parseBarcode = parseBarcode;
        vm.saveWarningLv = saveWarningLv;
        vm.sendWarehouseWarning = sendWarehouseWarning;

        vm.simulate                = simulate;
        vm.addDefaultSimulateInput = addDefaultSimulateInput;
        vm.simulateInput           = [];
        vm.simulatePreview         = [];
        vm.simulateProcessing      = false;
        vm.toPurchaseRequest       = toPurchaseRequest;
        vm.selectDN                = selectDN;
        vm.emptyDN                 = emptyDN;
        vm.stockInMode             = 'create';

        vm.showAreaScan = showAreaScan;
        vm.scannedSkuKeyPress = scannedSkuKeyPress;
        vm.submitAreaCode = submitAreaCode;
        vm.assignAreaCodeLoading = false;

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'inventory'           : { path: 'inventory', method: 'GET' },
                    'stockin'             : { path: 'stockin', method: 'POST' },
                    'stockin-update'      : { path: 'stockin-update', method: 'POST' },
                    'inout-record-delete' : { path: 'inout-record-delete', method: 'POST' },
                    'stocktake'           : { path: 'stocktake', method: 'POST' },
                    'simulate'            : { path: 'forecast-simulate', method: 'POST' },
                    'print-label'            : { path: 'inventory/printLabel/raw_material', method: 'POST' },
                    'print-inout-label'            : { path: 'inventory/printLabel/in_out_record', method: 'POST' },
                    'save-warehouse-warning-lv'            : { path: 'inventory/saveWarningLv', method: 'POST' },
                    'send-warehouse-warning'            : { path: 'inventory/sendWarehouseWarning', method: 'POST' },
                    'stocktransfer'           : { path: 'stocktransfer', method: 'POST' },
                    'assignAreaCode': {path: 'assignAreaCode', method: 'POST'}
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 30,
                locationId: config.locationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(rows) {
                rows.forEach((row) => {
                    if (row.sku) {
                        row.name = row.sku;
                    }
                    if (row.artwork_id) {
                        row.description = '[' + row.artwork_id + '] ' + row.product_description;
                    }
                })
                console.log(rows)
                vm.rows = rows;
            });

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();

            // for direct access of specific raw material
            var data = config.initConfig;
            if (data.rawMaterialSKU) {
                $timeout(function() {
                    PageViewModel.update(function () {
                        PageViewModel.setFilterIdAndFirst('raw_materials.name', data.rawMaterialSKU, function (row) {
                            openDetailDialog(row, data.mode ? data.mode : null);

                            $chartContainer.empty();
                            drawInventoryForecast();

                            $timeout(function () {
                                $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"raw_materials.effective_only","field":"raw_materials.effective_only","type":"boolean","input":"radio","operator":"equal","value":"true"}]});
                            });
                        });
                    });
                }, 500);
            } else {
                $timeout(function () {
                    $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"raw_materials.effective_only","field":"raw_materials.effective_only","type":"boolean","input":"radio","operator":"equal","value":"true"}]});
                    PageViewModel.setFilter();
                    PageViewModel.update(function () {
                        $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"raw_materials.effective_only","field":"raw_materials.effective_only","type":"boolean","input":"radio","operator":"equal","value":"true"},{"id":'raw_materials.name',"field":'raw_materials.name',"type":"string","input":"text","operator":"contains","value":''}]});
                        $(".rule-value-container").last().find("input").focus()
                    });
                }, 500);
            }

            setInterval(function () {
                if ($('#modal-scan').hasClass('in')) {
                    $('#hiddenScannerInput').focus();
                }
            }, 1000);

            if (typeof nativeInterface !== 'undefined') {
                vm.showScan();
            }

        }

        function drawInventoryForecast () {

            var row = vm.detail;

            $timeout(function() {

                var canvasOverlayObjects = [
                    {horizontalLine: {
                        name: 'zero-lv',
                        y: 0,
                        lineWidth: 2,
                        color: 'rgb(51, 51, 51)',
                        shadow: false
                    }},
                    {horizontalLine: {
                        name: 'critical-lv',
                        y: row.critical_stock_lv,
                        lineWidth: 2,
                        color: 'rgb(229, 57, 53)',
                        shadow: false
                    }},
                    {horizontalLine: {
                        name: 'safety-lv',
                        y: row.safety_stock_lv,
                        lineWidth: 2,
                        color: 'rgb(0, 184, 212)',
                        shadow: false
                    }}];

                if (row.production_safety_stock_lv) {
                    canvasOverlayObjects.push({horizontalLine: {
                        name: 'production-safety-lv',
                        y: row.production_safety_stock_lv + row.safety_stock_lv,
                        lineWidth: 2,
                        color: 'rgb(36, 136, 229)',
                        shadow: false
                    }});
                }
                // row.inventoryIndicators.map(function(indicator) {
                //     canvasOverlayObjects.push({verticalLine: {
                //         name: 'preorder-moq-lv-' + indicator.restock_date,
                //         x: new $.jsDate(indicator.preorder_date).getTime(),
                //         lineWidth: 2,
                //         color: 'rgb(255, 0, 0)',
                //         shadow: false
                //     }});
                // });
                // row.inventoryIndicators.map(function(indicator) {
                //     canvasOverlayObjects.push({verticalLine: {
                //         name: 'restock-lv-' + indicator.restock_date,
                //         x: new $.jsDate(indicator.restock_date).getTime(),
                //         lineWidth: 2,
                //         color: 'rgb(60, 141, 188)',
                //         shadow: false
                //     }});
                // });


                $.jqplot(chartId, [
                    // daily sale (volume graph)
                    row.inventoryForecasts.map(function(data) { return [data.date, data.daily_sale]; }),

                    // actual inventory level (historical)
                    _.filter(row.inventoryForecasts, function (row) {
                        return moment(row.date) < moment(moment().format('YYYY-MM-DD'));
                    }).map(function(data) { return [data.date, data.quantity]; }),

                    // estimate forecast inventory level (with PO data)
                    _.filter(row.inventoryForecasts, function (row) {
                        return moment(row.date) >= moment(moment().subtract(1, 'day').format('YYYY-MM-DD'));
                    }).map(function(data) { return [data.date, data.quantity]; }),

                    // actual forecast inventory level
                    _.filter(row.inventoryForecasts, function (row) {
                        return moment(row.date) >= moment(moment().subtract(1, 'day').format('YYYY-MM-DD'));
                    }).map(function(data) { return [data.date, data.quantity_raw]; }),

                    vm.simulatePreview.map(function(data) { return [data.date, data.quantity]; }),

                    vm.simulatePreview.map(function(data) { return [data.date, data.quantity_raw]; }),

                ], {
                    title: 'Inventory Forecast',
                    animate: true,
                    animateReplot: true,
                    cursor: {
                        show: true,
                        zoom: true,
                        looseZoom: true,
                        showTooltip: false,
                        tooltipLocation : 'ne',
                    },
                    seriesColors: ['#CDCDCD', '#03A9F4', '#FF9800', '#CDDC39', '#D500F9', '#1DE9B6'],
                    series:[
                        {
                            renderer: $.jqplot.BarRenderer,
                            // showHighlight: true,
                            showHighlight: config.canViewSale ? true : false,
                            label: 'Forecast Sale',
                            yaxis: 'y2axis',
                            rendererOptions: {
                                // Speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for bar series is 3000.
                                animation: {
                                    speed: 1500
                                },
                                barWidth: 15,
                                barPadding: -15,
                                barMargin: 0,
                                highlightMouseOver: false
                            }
                        },
                        {
                            markerOptions: { show: false, },
                            label: 'Historical Inventory LV',
                            rendererOptions: {
                                // speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for a line series is 2500.
                                animation: {
                                    speed: 1000
                                }
                            }
                        },
                        {
                            markerOptions: { show: false, },
                            label: 'Est. Inventory LV <br />(with DN Input)',
                            rendererOptions: {
                                // speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for a line series is 2500.
                                animation: {
                                    speed: 1000
                                }
                            }
                        },
                        {
                            markerOptions: { show: false, },
                            label: 'Est. Inventory LV',
                            rendererOptions: {
                                // speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for a line series is 2500.
                                animation: {
                                    speed: 1000
                                }
                            }
                        },
                        {
                            markerOptions: { show: false, },
                            label: 'Preview Inventory LV<br />(with DN Input)',
                            linePattern: [6, 6],
                            rendererOptions: {
                                // speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for a line series is 2500.
                                animation: {
                                    speed: 1500
                                }
                            }
                        },
                        {
                            markerOptions: { show: false, },
                            label: 'Preview Inventory LV',
                            linePattern: [6, 6],
                            rendererOptions: {
                                // speed up the animation a little bit.
                                // This is a number of milliseconds.
                                // Default for a line series is 2500.
                                animation: {
                                    speed: 1500
                                }
                            }
                        },
                    ],
                    legend: {
                        renderer: $.jqplot.EnhancedLegendRenderer,
                        show: true,
                        location: 'ew',
                        placement: 'outsideGrid',
                        marginLeft: 300
                    },
                    // seriesDefaults: {
                    //     rendererOptions: {
                    //         smooth: true
                    //     }
                    // },
                    axesDefaults: {
                        pad: 0,
                    },
                    axes: {
                        // These options will set up the x axis like a category axis.
                        xaxis: {
                            label: 'Date',
                            labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
                            tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            renderer: $.jqplot.DateAxisRenderer,
                            tickInterval: '2 weeks',
                            tickOptions: {
                                formatString: '%d %b',
                            },
                        },
                        yaxis: {
                            label: 'Inventory Level',
                            labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
                            tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            min: 0,
                            max: _.max([
                                _.max(row.inventoryForecasts.map(function (data) { return data.quantity_raw * 1; })),
                                _.max(row.inventoryForecasts.map(function (data) { return data.quantity * 1; })),
                                _.max(vm.simulatePreview.map(function (data) { return data.quantity_raw * 1; })),
                                _.max(vm.simulatePreview.map(function (data) { return data.quantity * 1; }))
                                ]) * 1.1 || 100,
                            tickOptions: {
                                formatString: "%.0f"
                            },
                        },
                        y2axis: {
                            label: 'Forecast Sale',
                            showTicks: config.canViewSale ? true : false,
                            labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
                            tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            min: 0,
                            max: _.max(row.inventoryForecasts.map(function (data) { return data.daily_sale * 1; })) * 1.1 || 100,
                            tickOptions: {
                                formatString: "%.0f"
                            },
                        }
                    },
                    highlighter: {
                        show: true,
                        showLabel: true,
                        tooltipAxes: 'yx',
                        sizeAdjust: 7.5 ,
                        tooltipLocation : 'ne'
                    },
                    grid: {
                        // gridLineWidth: 1.5,
                        // gridLineColor: 'rgb(235,235,235)',
                        drawGridlines: false
                    },
                    canvasOverlay: {
                        show: true,
                        objects: canvasOverlayObjects
                    }
                });

                vm.simulateProcessing = false;

            }, 800);

        }

        function openDetailDialog(row, mode) {
            vm.detail = row;
            vm.mode = (mode || 'historical');

            if (vm.mode == 'historical' || vm.mode == 'forecast') PageViewModel.openModal("#modal-forecast");
            else if (vm.mode == 'history') PageViewModel.openModal("#modal-inout-record");

        }

        function submit() {
        }

        function isDeliveryNoteEmpty() {

            if (vm.stockin.action == 'stock_in') {

                if (vm.stockin.deliveryNoteItems.length == 0) return true;
                for (var idx in vm.stockin.deliveryNoteItems) {
                    if (vm.stockin.deliveryNoteItems[idx].id) return false;
                }

                return true;
            }
            return false;

        }

        function showStockIn(row) {
            var location = null;
            _.each(vm.locations, function (element, index) {
                if (element.id == vm.locationId) {
                    location = element;
                }
            });
            vm.detail = row;
            vm.stockin = {
                id                : row.id,
                location          : location,
                box_quantity      : 0,
                box               : 0,
                area_code : row.lastLocation,
                default_area_code: row.allLocation,
                po_num         : '',
                deliveryNoteItems : [{}],
                expiry_date       : '',
                action            : 'stock_in',
            };
            vm.stockInMode = 'create';
            PageViewModel.openModal("#modal-stockin");
            $('input#po-input').tagEditor('destroy');
            $('input#po-input').val('');
            $('input#po-input').tagEditor({ 
                autocomplete: {
                    delay: 0, // show suggestions immediately
                    // position: { collision: 'flip' }, // automatic menu position up/down
                    source: '/function/po-autocomplete?sku=' + vm.detail.name,
                    minLength: 1
                },
                onChange: function (field, editor, tags) {
                    if (!tags || !tags.length) {
                        return;
                    }
                    $.ajax({
                        method: 'GET',
                        url: '/function/po-check?rawMaterialId=' + row.id + '&poList=' + tags.join(','),
                        success: function (ret) {
                            $('li', editor).each(function(){
                                var li = $(this);
                                for (var index in ret.unmatched) {
                                    if (li.find('.tag-editor-tag').html() == ret.unmatched[index]) li.addClass('red-tag');
                                }
                                for (var index in ret.matched) {
                                    if (li.find('.tag-editor-tag').html() == ret.matched[index]) li.removeClass('red-tag');
                                }
                            });
                        }
                    })
                }
            });
        }

        function performStockIn(forceStockIn) {
            var tempPO = null;

            if (vm.stockin.box_quantity <= 0 ||  vm.stockin.box <= 0) {
                alert("Invalid quantity");
                return;
            }
            if (vm.stockin.action == 'stock_in') {
                if ($(".tag-editor .red-tag").length) {
                    if (!confirm("There are some invalid PO number, confirm to continue?")) {
                        return;
                    }
                }
                tempPO = $('input#po-input').tagEditor('getTags')[0].tags.join(',');
                if ((!tempPO || tempPO.trim() == '') && (!vm.stockin.remark || vm.stockin.remark.trim() == '')) {
                    alert("If there is no PO, please leave the reason in remark");
                    return;
                }
            }
            if (!forceStockIn) {
                if (isDeliveryNoteEmpty()) {
                    PageViewModel.switchModal('#modal-stockin', '#modal-stockin-empty-delivery-note');
                    return;
                }
            }
            if (vm.stockin.action == 'stock_out' && (!vm.stockin.stock_transit_id || vm.stockin.stock_transit_id.trim() == '') && (!vm.stockin.remark || vm.stockin.remark.trim() == '')) {
                alert("If there is no Stock Transit Request, please leave the reason in remark");
                    return;
            }

            vm.detail.processing = true;
            vm.detail.error = '';
            if (vm.stockin.action == 'stock_in') {
                vm.stockin.po_num = tempPO
            }
            serverGateway.ajax('stockin', '', vm.stockin).then(function(response) {
                vm.detail.processing = false;
                vm.detail = response.data.data;

                // find raw material id
                var idx = _.findIndex(vm.rows, function (row) {
                    return row.id == response.data.data.id;
                });
                if (idx !== -1) {
                    vm.rows[idx] = response.data.data;
                }
                PageViewModel.hideModal("#modal-stockin");
                PageViewModel.hideModal("#modal-stockin-empty-delivery-note");
                vm.showInoutRecord(vm.detail);
            }, function (response) {
                vm.detail.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.detail.error = "The action cannot be completed. Please check the inventory level.";
            });
        }

        function performStockInUpdate() {

            vm.detail.processing = true;
            serverGateway.ajax('stockin-update', '', vm.stockin).then(function (response) {
                vm.detail.processing = false;
                vm.detail = response.data.data;
                // find raw material id
                var idx = _.findIndex(vm.rows, function (row) {
                    return row.id == response.data.data.id;
                });
                if (idx !== -1) {
                    vm.rows[idx] = response.data.data;
                }

                PageViewModel.hideModal("#modal-stockin");


            }, function (response) {
                vm.detail.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function performStockInWarningCancel() {
            PageViewModel.switchModal('#modal-stockin-empty-delivery-note', '#modal-stockin');
        }

        function showStockOut(row) {
            var location = null;
            _.each(vm.locations, function (element, index) {
                if (element.id == vm.locationId) {
                    location = element;
                }
            });
            if (vm.locationId == 4 && row.isWIPItem) {
                if (!confirm("For System Order, please use Stock Transfer instead, are you sure you want Stock Out?")) {
                    return;
                }
            }
            vm.detail = row;
            vm.stockin = {
                id           : row.id,
                location     : location,
                box_quantity : 0,
                box          : 0,
                po_num       : 'CAS-PO-',
                area_code : row.lastLocation,
                default_area_code: row.allLocation,
                expiry_date  : '',
                action       : 'stock_out',
            };
            vm.stockInMode = 'create';
            PageViewModel.openModal("#modal-stockin");
        }

        function showInoutRecord(row) {
            vm.detail = row;
            PageViewModel.openModal("#modal-inout-record");
        }

        function editInoutRecord(record) {
            vm.stockin = $.extend(true, {}, record);
            vm.stockInMode = 'update';
            PageViewModel.openModal("#modal-stockin");
        }

        function deleteInOutRecord(record) {
            if (!confirm("Confirm Delete?")) {
                return;
            }

            serverGateway.ajax('inout-record-delete', '', {'locationId': vm.locationId, 'id': record.id}).then(function(response) {
                vm.detail = response.data.data;

                // find raw material id
                var idx = _.findIndex(vm.rows, function (row) {
                    return row.id == response.data.data.id;
                });
                if (idx !== -1) {
                    vm.rows[idx] = response.data.data;
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showUpdateQTY(row) {
            vm.detail = row;
            var location = null;
            _.each(vm.locations, function (element, index) {
                if (element.id == vm.locationId) {
                    location = element;
                }
            });
            vm.stocktake = {
                id                : row.id,
                name              : row.name,
                short_description : row.short_description,
                description       : row.description,
                quantity          : row.quantity,
                allocatedQuantity : row.allocatedQuantity,
                stockTakeQuantity : row.allocatedQuantity + row.quantity,
                area_code : row.lastLocation,
                default_area_code: row.allLocation,
                location          : location,
                remark            : '',
                box_type           : row.box_type,
                mode               : 'soh',
            };

            PageViewModel.openModal("#modal-update-qty");
        }

        function showWarningLevel(row) {
            vm.detail = row;
            
            PageViewModel.openModal("#modal-warning-lv");
        }

        function showStockTransfer(row) {
            vm.detail = row;

            if (vm.locationId == 4 && !row.isWIPItem) {
                if (!confirm("For non system item, please use Stock Out instead, are you sure you want Stock Transfer?")) {
                    return;
                }
            }
            
            var location = null;
            vm.to_locations = [];
            var to_location = null;
            _.each(vm.locations, function (element, index) {
                if (element.id == vm.locationId) {
                    location = element;
                }
                var toLocationIds = [];
                var toLocationId = [];
                if (config.locationId == 4) {
                    if (config.canManageSampleInventory) {
                        toLocationId = 17;
                        toLocationIds.push(17);
                    }
                    if (config.canManageStore) {
                        toLocationId = 18;
                        toLocationIds.push(18);
                        toLocationIds.push(19);
                    }
                    if (config.canManageWIP) {
                        if (row.allow_printing) {
                            toLocationId = 13;
                            toLocationIds.push(13);
                        } else {
                            toLocationId = 14;
                            toLocationIds.push(14);
                        }
                    }
                } else if (config.locationId == 10) {
                    toLocationId = 12;
                    toLocationIds = [12];
                } else if (config.locationId == 12) {
                    toLocationId = 10;
                    toLocationIds = [10];
                } else if (config.locationId == 13) {
                    toLocationId = 4;
                    toLocationIds = [4];
                } else if (config.locationId == 14) {
                    toLocationId = 4;
                    toLocationIds = [4];
                }
                if (config.locationId == 17) {
                    toLocationId = 4;
                    toLocationIds = [4];
                    if (config.canManageWIP) {
                        if (row.allow_printing) {
                            toLocationId = 13;
                            toLocationIds.push(13);
                        } else {
                            toLocationId = 14;
                            toLocationIds.push(14);
                        }
                    }
                }

                if (config.locationId == 18) {
                    toLocationId = 4;
                    toLocationIds = [4];
                }

                if (config.locationId == 19) {
                    toLocationId = 4;
                    toLocationIds = [4];
                }
                if (toLocationIds.includes(element.id)) {
                    if (element.id == toLocationId) {
                        to_location = element;
                    }
                    vm.to_locations.push(element);
                }
            });
            vm.detail = row;
            vm.stockin = {
                id           : row.id,
                location     : location,
                to_location     : to_location,
                box_quantity : row.default_box_quantity,
                box          : 1,
                po_num       : 'CAS-PO-',
                area_code : row.lastLocation,
                default_area_code: row.allLocation,
                expiry_date  : '',
                action       : 'stock_transfer',
            };

            PageViewModel.openModal("#modal-stock-transfer");
        }

        function performUpdateQTY(row) {
            vm.stocktake.processing = true;
            serverGateway.ajax('stocktake', '', vm.stocktake).then(function(response) {
                vm.stocktake.processing = false;
                vm.detail = response.data.data;

                // find raw material id
                var idx = _.findIndex(vm.rows, function (row) {
                    return row.id == response.data.data.id;
                });
                if (idx !== -1) {
                    vm.rows[idx] = response.data.data;
                }
                PageViewModel.hideModal("#modal-update-qty");
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function performStockTransfer(row) {
            if ((!vm.stockin.stock_transit_id || vm.stockin.stock_transit_id.trim() == '') && (!vm.stockin.remark || vm.stockin.remark.trim() == '')) {
                alert("If there is no Stock Transit Request, please leave the reason in remark");
                    return;
            }
            vm.detail.processing = true;
            serverGateway.ajax('stocktransfer', '', vm.stockin).then(function(response) {
                vm.detail.processing = false;
                vm.detail = response.data.data;

                // find raw material id
                var idx = _.findIndex(vm.rows, function (row) {
                    return row.id == response.data.data.id;
                });
                if (idx !== -1) {
                    vm.rows[idx] = response.data.data;
                }
                PageViewModel.hideModal("#modal-stock-transfer");
            }, function (response) {
                vm.detail.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showInventoryForecast(row) {
            vm.detail = row;

            vm.simulatePreview = [];
            vm.simulateInput = [];
            addDefaultSimulateInput();
            PageViewModel.openModal("#modal-forecast");

            $chartContainer.empty();
            drawInventoryForecast();
        }

        function showPrintLabel(row) {
            vm.detail = row;
            vm.detail.box_qty = row.default_box_quantity;
            vm.detail.label_qty = 1;
            vm.detail.expiry_date = '';
            PageViewModel.openModal("#modal-print-label");
        }

        function showInoutRecordLabel(row) {
            vm.label = row;
            vm.label.inout_quantity = row.box > 1 ? (row.quantity + " (" + row.box + "x" + row.box_quantity + ")") : row.quantity;
            vm.label.label_qty = row.box ? row.box : 1;
            PageViewModel.openModal("#modal-print-inout-label");
        }

        function printInoutLabel() {
            if (vm.label.label_qty > 20 && !confirm("Confirm printing more than 20 labels?")) {
                return;
            }
            vm.detail.printLabelButtonLoading = true;
            serverGateway.ajax('print-inout-label', '', {...vm.label,...{'printer' : vm.station}}).then(function(response) {
                vm.detail.printLabelButtonLoading = false;
                PageViewModel.hideModal("#modal-print-inout-label");
            }, function () {
                vm.detail.printLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printLabel() {
            vm.detail.printLabelButtonLoading = true;
            serverGateway.ajax('print-label', '', {'sku': vm.detail.name, 'desc': vm.detail.description, 'expiry_date': vm.detail.expiry_date, 'label_qty': vm.detail.label_qty, 'printer' : vm.printer }).then(function(response) {
                vm.detail.printLabelButtonLoading = false;
                PageViewModel.hideModal("#modal-print-label");
                $.bootstrapGrowl('Already sent to Printer', {ele: 'body', type: 'success'});
            }, function () {
                vm.detail.printLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showScan() {
            PageViewModel.openModal("#modal-scan");
            $("#hiddenScannerInput").focus();
            vm.detail.box_qty = null;
            vm.detail.po = null;
            vm.detail.remark = null;
            vm.detail.sku = null;
            vm.detail.sku_description = null;
            if (typeof nativeInterface !== 'undefined') {
                volumnUpPressed();
            }
        }

        function scanStockin() {
            var qty = 0;
            var po = null;
            var remark = null;
            if (vm.detail.box_qty) {
                qty = vm.detail.box_qty;
            }
            if (vm.detail.po) {
                po = vm.detail.po;
            }
            if (vm.detail.remark) {
                remark = vm.detail.remark;
            }
            PageViewModel.hideModal("#modal-scan");
            $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"raw_materials.name","field":"raw_materials.name","type":"string","input":"text","operator":"contains","value":vm.detail.sku}]});
            PageViewModel.setFilter();
            PageViewModel.update(function () {
                if (vm.rows.length) {
                    vm.showStockIn(vm.rows[0]);
                    if (qty) {
                        vm.stockin.box_quantity = qty;
                    }
                    if (remark) {
                        vm.stockin.remark = remark + ';';
                    }
                    if (po) {
                        vm.stockin.po_num = po;
                        vm.stockin.remark += po + ';';
                    }
                } else {
                    $.bootstrapGrowl("Item Not Found", {ele: 'body', type: 'error'});
                }

            });
        }

        function scanStockout() {
            var qty = 0;
            var po = 0;
            if (vm.detail.box_qty) {
                qty = vm.detail.box_qty;
            }
            // if (vm.detail.po) {
            //     po = vm.detail.po;
            // }
            PageViewModel.hideModal("#modal-scan");
            $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"raw_materials.name","field":"raw_materials.name","type":"string","input":"text","operator":"contains","value":vm.detail.sku}]});
            PageViewModel.setFilter();
            PageViewModel.update(function () {
                if (vm.rows.length) {
                    vm.showStockOut(vm.rows[0]);
                    if (qty) {
                        vm.stockin.box_quantity = qty;
                    }
                    // if (po) {
                    //     vm.stockin.po_num = po;
                    // }
                } else {
                    $.bootstrapGrowl("Item Not Found", {ele: 'body', type: 'error'});
                }

            });
        }

        function scanHistory() {
            PageViewModel.hideModal("#modal-scan");
            PageViewModel.hideModal("#modal-scan");
            $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"raw_materials.name","field":"raw_materials.name","type":"string","input":"text","operator":"contains","value":vm.detail.sku}]});
            PageViewModel.setFilter();
            PageViewModel.update(function () {
                if (vm.rows.length) {
                    vm.showInoutRecord(vm.rows[0]);
                } else {
                    $.bootstrapGrowl("Item Not Found", {ele: 'body', type: 'error'});
                }

            });
        }

        function scanSearch() {
            PageViewModel.hideModal("#modal-scan");
            $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"raw_materials.name","field":"raw_materials.name","type":"string","input":"text","operator":"contains","value":vm.detail.sku}]});
            PageViewModel.setFilter();
        }

        function parseBarcode(forceClearForContent) {
            var lines = vm.detail.scannerInput.split("\n");
            for (var index in lines) {
                var isLastLine = (index == (lines.length - 1));
                var line = lines[index];
                var parsedResult = {};
                if (line[0] == '{' && line[line.length - 1] == '}') {
                    // JSON
                    var content = JSON.parse(line);
                    if (content) {
                        for (var contentKey in content) {
                            if (contentKey.toLowerCase() == "sku") {
                                parsedResult.sku = content[contentKey];
                            }
                            if (contentKey.toLowerCase() == "box_qty") {
                                parsedResult.box_qty = parseInt(content[contentKey]);
                            }
                            if (contentKey.toLowerCase() == "qty") {
                                parsedResult.box_qty = parseInt(content[contentKey]);
                            }
                            if (contentKey.toLowerCase() == "po") {
                                parsedResult.po = content[contentKey];
                            }
                        }
                    }
                } else if (line.split(" : ").length >= 2) {
                    var result = line.split(" : ");
                    var contentKey = result[0];
                    result.shift();
                    var content = result.join(" : ");
                    if (contentKey.toLowerCase() == "item") {
                        parsedResult.sku = content;
                    }
                    if (contentKey.toLowerCase() == "qty") {
                        parsedResult.box_qty = parseInt(content);
                    }
                    if (contentKey.toLowerCase() == "desc") {
                        parsedResult.sku_description = content;
                    }
                    if (contentKey.toLowerCase() == "po") {
                        parsedResult.po = content;
                    }
                    if (contentKey.toLowerCase() == "remark") {
                        parsedResult.remark = content;
                    }
                } else if (line.split(";").length >= 1 && line != "") {
                    var result = line.split(";");
                    vm.detail.sku = result[0];
                    if (result.length > 1) {
                        parsedResult.box_qty = parseInt(result[1]);
                    }
                    if (result.length > 2) {
                        parsedResult.po = result[2];
                    }
                }
                if (Object.keys(parsedResult).length) {
                    for (var key in parsedResult) {
                        vm.detail[key] = parsedResult[key];
                    }
                }
                if (isLastLine) {
                    if (forceClearForContent) {
                        if (forceClearForContent == line) {
                            vm.detail.scannerInput = '';
                        }
                    } else {
                        setTimeout(function () {vm.parseBarcode(line); }, 500);
                        vm.detail.scannerInput = line;
                    }
                }
            }
            return false;
        }

        function addDefaultSimulateInput () {
            vm.simulateInput.push({
                sku      : vm.detail.name,
                date     : moment().format('YYYY-MM-DD'),
                quantity : vm.detail.moq,
            });
        }

        function simulate() {

            if (vm.simulateProcessing) return;

            vm.simulateProcessing = true;

            serverGateway.ajax('simulate', '', {
                id: vm.detail.id,
                data: vm.simulateInput,
            }).then(function (response) {

                vm.simulatePreview = response.data.preview;
                for (var idx in vm.simulateInput) {
                    for (var idx2 in response.data.breakdown) {
                        if (vm.simulateInput[idx].date == response.data.breakdown[idx2].date && vm.simulateInput[idx].quantity == response.data.breakdown[idx2].quantity) {
                            vm.simulateInput[idx] = response.data.breakdown.splice(idx2, 1)[0];

                            var previewData = _.find(vm.simulatePreview, function (item) {
                                return item.date == vm.simulateInput[idx].restock_date;
                            });

                            if (previewData) {
                                var restockQTY = previewData.quantity - vm.simulateInput[idx].quantity;

                                if (vm.detail.production_safety_stock_lv != 0 && (vm.detail.safety_stock_lv < restockQTY && restockQTY <= vm.detail.safety_stock_lv + vm.detail.production_safety_stock_lv)) {
                                    vm.simulateInput[idx].show_warning = 'production_safety_stock_lv';
                                }
                                else if (vm.detail.critical_stock_lv != vm.detail.safety_stock_lv && (vm.detail.critical_stock_lv < restockQTY && restockQTY <= vm.detail.safety_stock_lv)) {
                                    vm.simulateInput[idx].show_warning = 'safety_stock_lv';
                                }
                                else if (restockQTY <= vm.detail.critical_stock_lv) {
                                    vm.simulateInput[idx].show_warning = 'critical_stock_lv';
                                }

                            }

                            break;
                        }
                    }
                }
                $chartContainer.empty();
                drawInventoryForecast();

            });

        }

        function toPurchaseRequest(input) {

            window.open('/product/purchase_requests?' + $.param(input), '_blank')

        }

        function selectDN(deliveryNoteItem) {

            var modifier = function (popup) {
                popup.config = {
                    initConfig : {
                        rawMaterialSKU: vm.detail.name
                    }
                };
            };

            Popup.open('/product/delivery_notes/popup', modifier).then(function (dn) {

                var valid = false;
                for (var idx in dn.deliveryNoteItems) {
                    if (dn.deliveryNoteItems[idx].sku == vm.detail.name && dn.status != 'Received' && dn.status != 'Pending') {

                        for (var idx2 in vm.stockin.deliveryNoteItems) {
                            if (vm.stockin.deliveryNoteItems[idx2] == deliveryNoteItem) {
                                vm.stockin.deliveryNoteItems[idx2] = dn.deliveryNoteItems[idx];
                            }
                        }
                        valid = true;
                    }
                }

                if (!valid) {
                    $.bootstrapGrowl('No SKU matched for the selected Delivery Note or the Delivery Note already completed', {ele: 'body', type: 'error'});
                }

            }, function () {
            });

        }

        function emptyDN(deliveryNoteItem) {

            for (var idx in vm.stockin.deliveryNoteItems) {
                if (vm.stockin.deliveryNoteItems[idx] == deliveryNoteItem) {
                    if (vm.stockin.deliveryNoteItems.length > 1)
                        vm.stockin.deliveryNoteItems.splice(idx, 1);
                    else
                        vm.stockin.deliveryNoteItems[idx] = {};
                    break;
                }
            }

        }

        function saveWarningLv() {
            var targetWarningLevel = null;
            for (var idx in vm.detail.locationWarningLevels) {
                if (vm.detail.locationWarningLevels[idx].location && vm.detail.locationWarningLevels[idx].location.id == vm.locationId) {
                    targetWarningLevel = vm.detail.locationWarningLevels[idx];
                    break;
                }
            }
            if (targetWarningLevel) {
                vm.detail.saveWarningLvLoading = true;
                serverGateway.ajax('save-warehouse-warning-lv', '', {'rawMaterialId': vm.detail.id, 'locationId': targetWarningLevel.location.id, 'warningLv': targetWarningLevel.warningLevel}).then(function(response) {
                    vm.detail.saveWarningLvLoading = false;
                    $.bootstrapGrowl('Saved', {ele: 'body', type: 'success'});
                    PageViewModel.hideModal("#modal-warning-lv");
                }, function () {
                    vm.detail.saveWarningLvLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });

            }
        }

        function sendWarehouseWarning() {
            serverGateway.ajax('send-warehouse-warning', '', {'locationId': vm.locationId}).then(function(response) {
                $.bootstrapGrowl('Report Sent', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showAreaScan() {
            vm.scannedAreaCode = '';
            vm.scannedSku = '';
            vm.scannedSkus = [];
            vm.assignAreaCodeLoading = false;
            PageViewModel.openModal("#modal-area-scan");
            $("#hiddenScannerInput").focus();
        }

        function scannedSkuKeyPress(event){
            if(event.which == 13){//is pressed enter
                if(vm.lockEnterKey){
                    clearScannedSku();
                    return;
                }
                vm.lockEnterKey = true;
                setTimeout(() => {
                    vm.lockEnterKey = false;
                }, 1000);
                addScannedSku();
            }
        }

        function addScannedSku(){
            if(!vm.scannedSku){
                return;
            }
            const barcodeData = convertWarehouseBarcode(vm.scannedSku);
            if(barcodeData == false){
                $.bootstrapGrowl('Invalid label format', {ele: 'body', type: 'error'});
                clearScannedSku();
                return;
            }
            vm.scannedSku = barcodeData.sku;
            vm.scannedSkus.unshift({
                name: vm.scannedSku
            });
            clearScannedSku();
        }

        function submitAreaCode(){
            if(!vm.scannedAreaCode){
                $.bootstrapGrowl('Please fill in area code', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.scannedSkus.length == 0){
                $.bootstrapGrowl('Empty sku', {ele: 'body', type: 'error'});
                return;
            }
            vm.assignAreaCodeLoading = true;
            serverGateway.ajax('assignAreaCode', '', {areaCode: vm.scannedAreaCode, skus: vm.scannedSkus, locationId: config.locationId}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                PageViewModel.hideModal("#modal-area-scan");
                vm.assignAreaCodeLoading = false;
            }, function (response) {
                vm.assignAreaCodeLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function clearScannedSku(){
            vm.scannedSku = '';
        }

        function convertWarehouseBarcode(value){
            let barcode = value;
            let data = {};
            let parsed = false;
            try {
                let barcodeData = JSON.parse(barcode);
                if (barcodeData) {
                    if (typeof barcodeData != 'object') throw new Error('invalid format')
                    for (var key in barcodeData) {
                        barcodeData[key.toUpperCase()] = barcodeData[key];
                    }
                    if (barcodeData['SKU']) {
                        data.sku = barcodeData['SKU'];
                    }
                    if (barcodeData['QTY']) {
                        data.perBoxCount = barcodeData['QTY'];
                        data.boxCount = 1;
                    }
                    if (barcodeData['PO']) {
                        data.po = barcodeData['PO'];
                    }
                    if (barcodeData['DATECODE']) {
                        data.remarks = barcodeData['DATECODE'];
                    }
                    if(barcodeData['TRANSITID'] && barcodeData['INPUTSESSIONITEMID']){
                        return false;
                    }
                    parsed = true;
                }
            } catch (e) {
            }
            if (!parsed) {
                let lines = barcode.split("\n");
                if (lines.length) {
                    lines.forEach((line) => {
                        let lineDatas = line.split(/[:：]+/);
                        if (lineDatas.length == 2) {
                            let contentKey = lineDatas[0].trim().toUpperCase();
                            let contentValue = lineDatas[1].trim();
                            if (contentKey == 'SKU') {
                                data.sku = contentValue;
                                parsed = true;
                            }
                            if (contentKey == 'QTY') {
                                data.perBoxCount = parseInt(contentValue);
                                data.boxCount = 1;
                            }
                            if (contentKey == 'PO') {
                                data.po = contentValue;
                            }
                            if (contentKey == 'DATECODE') {
                                data.remarks = contentValue;
                            }
                        }
                    })
                }
            }
            if (!parsed) {
                data = {
                    sku: barcode,
                    boxCount: 1,
                    perBoxCount: 1,
                }
            }
            return data;
        }
    }

    function InventoryWarningController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    // 'forecast-data': { path: 'forecast-data', method: 'GET' },
                    // 'override': { path: 'forecast-override', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();
            // setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
        }

        function openDetailDialog(row, columns) {
            return;
            // setDetail(row);
            // if (!isForecastDate(row)) return;
            // PageViewModel.openModal();
        }

    }

    function InventoryQtyBasedController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'forecast-data': { path: 'forecast-data', method: 'GET' },
                    'override': { path: 'forecast-override', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();
            // setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
        }

        function openDetailDialog(row, columns) {
            return;
            // setDetail(row);
            // if (!isForecastDate(row)) return;
            // PageViewModel.openModal();
        }

    }

    function MaterialBookingController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.showNewBookingRequestModal = showNewBookingRequestModal;
        vm.newItems = [{'name': null, 'qty': 0}];
        vm.addItem = addItem;
        vm.removeItem = removeItem;
        vm.createBookingRequest = createBookingRequest;
        vm.updateBookingRequest = updateBookingRequest;
        vm.openDetailDialog = openDetailDialog;
        vm.showLoadImportDialog = showLoadImportDialog;
        vm.showSaveImportDialog = showSaveImportDialog;
        vm.parseImportData = parseImportData;
        vm.saveImportData = saveImportData;
        vm.exportSKU = exportSKU;
        vm.deleteCancel = deleteCancel;
        vm.deleteRequest = deleteRequest;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-booking-request': { path: 'raw-material/booking', method: 'POST' },
                    'update-booking-request': { path: 'raw-material/booking/{id}', method: 'POST' },
                    'delete-booking-request': { path: 'raw-material/booking/{id}/delete', method: 'POST' },
                    // 'override': { path: 'forecast-override', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                // perPage: 10,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();
            setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
            vm.detail.expiryDate = null;
            vm.detail.remark = null;
        }

        function openDetailDialog(row, columns) {
            return;
            // setDetail(row);
            // if (!isForecastDate(row)) return;
            // PageViewModel.openModal();
        }

        function showNewBookingRequestModal() {
            PageViewModel.openModal('#modal-new-request');
        }

        function addItem() {
            vm.newItems.push({'name': null, 'qty': 0});
        }

        function removeItem(key) {
            vm.newItems.splice(key, 1);
        }

        function createBookingRequest() {
            vm.detail.createBookingRequestLoading = true;
            serverGateway.ajax('create-booking-request', '', {'items': JSON.stringify(vm.newItems), 'expiry_date': vm.detail.expiryDate, 'remark': vm.detail.remark}).then(function(response) {
                vm.detail.createBookingRequestLoading = false;
                PageViewModel.hideModal("#modal-new-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.createBookingRequestLoading = false;
                try {
                    var responseObj = JSON.parse(response.data.message);
                    if (responseObj && responseObj.type == 'wrong items') {
                        vm.newItems = responseObj.items;
                        $.bootstrapGrowl("Invalid Items", {ele: 'body', type: 'error'});
                    } else {
                        PageViewModel.update();
                    }
                } catch (e) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }

            });
        }

        function updateBookingRequest() {
            vm.detail.updateBookingRequestLoading = true;
            var allInoutIds = [];
            for (var index in vm.detail.rawMaterials) {
                if (!vm.detail.rawMaterials[index].inoutIds) continue;
                var inoutIds = vm.detail.rawMaterials[index].inoutIds.split(',');
                for (var index2 in inoutIds) {
                    if (allInoutIds.indexOf(inoutIds[index2]) != -1) {
                        $.bootstrapGrowl("Duplicated In Out Ids: " + inoutIds[index2], {ele: 'body', type: 'error'});
                        return;
                    } else {
                        allInoutIds.push(inoutIds[index2]);
                    }
                }
            }
            serverGateway.ajax('update-booking-request', {'id': vm.detail.id}, {'inoutRecords': allInoutIds, 'expiry_date': vm.detail.expiryDate, 'remark': vm.detail.remark}).then(function(response) {
                vm.detail.updateBookingRequestLoading = false;
                PageViewModel.hideModal("#modal-show-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                vm.detail.updateBookingRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // try {
                //     var responseObj = JSON.parse(response.data.message);
                //     if (responseObj && responseObj.type == 'wrong items') {
                //         vm.newItems = responseObj.items;
                //         $.bootstrapGrowl("Invalid Items", {ele: 'body', type: 'error'});
                //     }
                // } catch (e) {
                //     $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // }

            });
        }

        function openDetailDialog(row) {
            PageViewModel.openModal('#modal-show-request');
            vm.detail = row;
        }

        function showLoadImportDialog(items) {
            PageViewModel.openModal('#modal-import-load');
            vm.importData = '';
        }

        function showSaveImportDialog(items) {
            PageViewModel.openModal('#modal-import-save');
            vm.importData = '';
            for (var i in vm.detail.items) {
                var item = vm.detail.items[i];
                if (item.name && item.quantity) {
                    vm.importData += "CTF-0000000-" + item.name + "," + item.quantity + ",1\n";
                }
            }
        }

        function parseImportData() {
            var lines = vm.importData.split("\n");
            var dict = [];
            for (var i in lines) {
                var line = lines[i];
                if (!line) continue;
                var data = line.split(",");
                if (!data[0] || !data[1]) continue;
                var sku = data[0].trim();
                var qty = data[1].trim();
                var parts = sku.split("-");
                if (!parts[2]) continue;
                var productName = parts[2].trim();
                if (!dict[productName]) {
                    dict[productName] = 0;
                }
                dict[productName] += parseInt(qty);
            }
            vm.newItems = [];
            for (var key in dict) {
                if (!dict[key]) continue;
                vm.newItems.push({'name': key, 'qty': dict[key]});
            }
            PageViewModel.hideModal('#modal-import-load');
        }

        function saveImportData() {
            if (!confirm("Confirm?")) return;
            var lines = vm.importData.split("\n");
            var dict = [];
            for (var i in lines) {
                var line = lines[i];
                if (!line) continue;
                var data = line.split(",");
                if (!data[0] || !data[1]) continue;
                var sku = data[0].trim();
                var qty = data[1].trim();
                var parts = sku.split("-");
                if (!parts[2]) continue;
                var productName = parts[2].trim();
                if (!dict[productName]) {
                    dict[productName] = 0;
                }
                dict[productName] += parseInt(qty);
            }
            vm.newItems = [];
            for (var key in dict) {
                if (!dict[key]) continue;
                vm.newItems.push({'name': key, 'qty': dict[key]});
            }
            var allInoutIds = [];
            for (var index in vm.detail.rawMaterials) {
                if (!vm.detail.rawMaterials[index].inoutIds) continue;
                var inoutIds = vm.detail.rawMaterials[index].inoutIds.split(',');
                for (var index2 in inoutIds) {
                    if (allInoutIds.indexOf(inoutIds[index2]) != -1) {
                        $.bootstrapGrowl("Duplicated In Out Ids: " + inoutIds[index2], {ele: 'body', type: 'error'});
                        return;
                    } else {
                        allInoutIds.push(inoutIds[index2]);
                    }
                }
            }
            vm.detail.updateBookingRequestLoading = true;
            serverGateway.ajax('update-booking-request', {'id': vm.detail.id}, {'items': JSON.stringify(vm.newItems), 'inoutRecords': allInoutIds}).then(function(response) {
                vm.detail.updateBookingRequestLoading = false;
                PageViewModel.hideModal('#modal-import-save');
                PageViewModel.hideModal("#modal-show-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                vm.detail.updateBookingRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // try {
                //     var responseObj = JSON.parse(response.data.message);
                //     if (responseObj && responseObj.type == 'wrong items') {
                //         vm.newItems = responseObj.items;
                //         $.bootstrapGrowl("Invalid Items", {ele: 'body', type: 'error'});
                //     }
                // } catch (e) {
                //     $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // }

            });
        }

        function exportSKU() {
            vm.exportData = '';
            for (var id in vm.detail.rawMaterials) {
                var rawMaterial = vm.detail.rawMaterials[id];
                vm.exportData = vm.exportData + rawMaterial.quantity + "\t" + rawMaterial.sku + "\t" + rawMaterial.name + "\n";
            }
            PageViewModel.openModal('#modal-export');
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-show-request');
        }

        function deleteRequest() {
            vm.detail.deleteBookingRequestLoading = true;
            serverGateway.ajax('delete-booking-request', {'id': vm.detail.id}).then(function(response) {
                vm.detail.deleteBookingRequestLoading = false;
                PageViewModel.hideModal("#modal-show-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.detail.updateBookingRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // try {
                //     var responseObj = JSON.parse(response.data.message);
                //     if (responseObj && responseObj.type == 'wrong items') {
                //         vm.newItems = responseObj.items;
                //         $.bootstrapGrowl("Invalid Items", {ele: 'body', type: 'error'});
                //     }
                // } catch (e) {
                //     $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                // }

            });
        }
    }

    function stockReservationController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup, $window) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.createStockReservationRequest = createStockReservationRequest;
        vm.showImportOverwriteMode = showImportOverwriteMode;
        vm.showImportIncrementMode = showImportIncrementMode;
        vm.showStockReservationEdit = showStockReservationEdit;
        vm.showStockReservationEditItem = showStockReservationEditItem;
        vm.resetStockReservationSearch = resetStockReservationSearch;
        vm.deleteStockReservation = deleteStockReservation;
        vm.deleteStockReservationItem = deleteStockReservationItem;
        vm.messageCallback = messageCallback;
        vm.exportReservationByItemOption = exportReservationByItemOption;
        vm.exportReservationBySku = exportReservationBySku;
        vm.batchImportReservation = batchImportReservation;

        vm.openDetailDialog = openDetailDialog;
        vm.openReadOnlyDetailPage = openReadOnlyDetailPage;
        vm.isDisabled = false;

        vm.salesChannelList = config.salesChannelList;
        vm.locationList = config.locationList;
        vm.statusList = config.statusList;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-reservation-request': { path: 'inventory/reservation-requests', method: 'POST' },
                    'edit-reservation-request': { path: 'inventory/stock-reservation/{id}/edit', method: 'GET' },
                    'stock-reservation-info' : { path: 'inventory/stock-reservation-info/{id}', method: 'GET'},
                    'delete-reservation' : { path: 'inventory/stock-reservation/{id}', method: 'DELETE'},
                    'delete-reservation-item' : { path: 'inventory/stock-reservation-item/{itemId}', method: 'DELETE'}, 
                    'reservation-list' : { path: 'inventory/reservation-list', method: 'GET'},
                }
            });

            if (config.id > 0) {
                if (config.showEditPanel) {
                    vm.showStockReservationEdit()
                }
                return;
            }

            if (!config.dataUrl) return

            let query = {
                id: config.id,
            }
            vm.gridDataSource = new GridDataSource({
                perPage: 50,
                resoucesUrl: (config.dataUrl + '?v=1' + ((config.salesChannelId != '') ? '&salesChannelId=' + config.salesChannelId : '') + ((config.showExpired != '') ? '&showExpired=' + config.showExpired : '')),
                gateway: serverGateway
            });

            vm.detail.redirectUrl = '';

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();

            setInterval(PageViewModel.update, 5000);

            $scope.$watch(function() {
                return vm.detail && vm.detail.items ? JSON.stringify(vm.detail.items) : null;
            }, function(items) {

            });
        }

        function openDetailDialog(row) {
            $window.location.href = '/inventory/stock-reservation/' + row.id + '/edit';
        }

        function openReadOnlyDetailPage(row) {
            $window.location.href = '/inventory/reservation/' + row.id;
        }

        function createStockReservationRequest() {
            vm.detail.createStockReservationRequestLoading = true;
            let self = this;
            serverGateway.ajax('create-reservation-request', '', vm.detail).then(function(response) {
                vm.detail.createStockReservationRequestLoading = false;
                PageViewModel.update();
                PageViewModel.hideModal("#modal-new-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                self.openDetailDialog({id: response.data.data.id});
            }, function (response) {
                vm.detail.createStockReservationRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function resetStockReservationSearch(id) {
            $window.location.href = '/inventory/stock-reservation/' + id + '/edit';
        }

        function messageCallback(id) {
            $window.location.href = vm.detail.redirectUrl;
        }

        function showImportOverwriteMode() {
            PageViewModel.openModal('#modal-import-overwrite-mode');
        }

        function showImportIncrementMode() {
            PageViewModel.openModal('#modal-import-increment-mode');
        }

        function showStockReservationEdit() {
            PageViewModel.openModal('#modal-stock-reservation-edit');
        }

        function showStockReservationEditItem(itemId, itemOptionId, description, qty) {
            vm.detail.itemId = itemId;
            vm.detail.itemOptionId = itemOptionId;
            vm.detail.description = description;
            vm.detail.qty = qty;
            PageViewModel.openModal('#modal-stock-reservation-edit-item');
        }

        function deleteStockReservation(id) {
            if (!confirm("Are you sure to delete the Reservation - #" + id + "?")) return;

            serverGateway.ajax('delete-reservation', {id: id}, vm.detail).then(function(response){
                if (response.data.status == 'success') {
                    vm.detail.message = 'Reservation - #' + id + ' was removed successfully.';
                    vm.detail.redirectUrl = '/inventory/stock-reservation/';
                    PageViewModel.openModal('#modal-message');
                } else {
                    vm.detail.message = 'Fail to delete Reservation - ' + id;
                }
            })
        }

        function deleteStockReservationItem(id, itemId, itemOption) {

            if (!confirm("Are you sure to delete the Item Option - " + itemOption + "?")) return;

            serverGateway.ajax('delete-reservation-item', {itemId: itemId}, vm.detail).then(function(response){
                if (response.data.status == 'success') {
                    vm.detail.message = 'Item Option - ' + itemOption + ' was removed successfully.';
                    vm.detail.redirectUrl = '/inventory/stock-reservation/' + id + '/edit';
                    PageViewModel.openModal('#modal-message');
                } else {
                    vm.detail.message = 'Fail to delete Item Option - ' + itemOption;
                }
            })
        }

        function exportReservationByItemOption() {
//            PageViewModel.openModal('#modal-export-by-item-option');
            $window.location.href = '/inventory/stock-reservation/export-reservation-by-item-option';
        }

        function exportReservationBySku() {
//            PageViewModel.openModal('#modal-export-by-sku');
            $window.location.href = '/inventory/stock-reservation/export-reservation-by-sku';
        }

        function batchImportReservation() {
            
        }
    }

    function stockTransitController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.printer          = config.defaultPrinterId ? config.defaultPrinterId : config.printerList[0].key;
        vm.isPrinting       = false;
        vm.currentFromRequestedAt = '';
        vm.currentToRequestedAt = '';
        vm.unfulfillmentReason = '';
        

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.showTransitRequestModal = showTransitRequestModal;
        vm.createStockTransitRequest = createStockTransitRequest;
        vm.updateStockTransitRequest = updateStockTransitRequest;
        vm.fulfillStockTransitRequest = fulfillStockTransitRequest;
        vm.fulfillStockTransitRequestWithoutUpdate = fulfillStockTransitRequestWithoutUpdate;
        vm.receivedStockTransitRequest = receivedStockTransitRequest;
        vm.autofillReceivedStockTransitRequest = autofillReceivedStockTransitRequest;
        vm.confirmStockTransitRequest = confirmStockTransitRequest;
        vm.rejectStockTransitRequest = rejectStockTransitRequest;
        vm.cancelStockTransitRequest = cancelStockTransitRequest;
        vm.clearAllOSQty = clearAllOSQty;
        vm.sendApprovalRequest = sendApprovalRequest;
        vm.showStockTransitImport = showStockTransitImport;
        vm.stockTransitImport = stockTransitImport;
        vm.showReceivingImport = showReceivingImport;
        vm.receivingImport = receivingImport;
        vm.addItem = addItem;
        vm.removeItem = removeItem;
        vm.printLabel = printLabel;
        vm.stockOutVM = stockOutVM;
        vm.convertReceivedFGToRM = convertReceivedFGToRM;

        vm.deleteCancel = deleteCancel;
        vm.openDetailDialog = openDetailDialog;
        vm.fromLocationList = config.fromLocationList;
        vm.printerList = config.printerList;
        vm.toLocationList = config.toLocationList;
        vm.locationList = config.locationList;
        vm.jpwhDeliveryTimeZoneList = config.jpwhDeliveryTimeZoneList;
        vm.salesChannelList = config.salesChannelList;
        vm.seniorList = config.seniorList;
        vm.isDisabled = false;
        vm.isProcessing = {};
        vm.unfulfillmentReason = config.unfulfillmentReason;

        vm.simplyMode = localStorage.getItem('stockTransitSimplyMode') == 'true';
        vm.changeStockTransitSimplyMode = changeStockTransitSimplyMode;
        vm.showBacklog = localStorage.getItem('stockTransitShowBacklog') == 'true';
        vm.changeShowBacklog = changeShowBacklog;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'check-duplicate-request': {path: 'inventory/check-duplicate-request/{id}', method:'POST'},
                    'create-transit-request': { path: 'inventory/transit-requests', method: 'POST' },
                    'show-transit-request': { path: 'inventory/transit-requests/{id}', method: 'GET' },
                    'update-transit-request': { path: 'inventory/transit-requests/{id}', method: 'POST' },
                    'fulfill-transit-request': { path: 'inventory/transit-requests/{id}/fulfill', method: 'POST' },
                    'confirm-transit-request': { path: 'inventory/transit-requests/{id}/confirm', method: 'POST' },
                    'reject-transit-request': { path: 'inventory/transit-requests/{id}/reject', method: 'POST'},
                    'received-transit-request': { path: 'inventory/transit-requests/{id}/received', method: 'POST' },
                    'cancel-transit-request': { path: 'inventory/transit-requests/{id}/cancel', method: 'POST' },
                    'send-approval-request': { path: 'inventory/transit-requests/{id}/approval-request/send', method: 'POST' },
                    'print-label': { path: 'inventory/transit-requests/print-label/{id}', method: 'POST' },
                    'send-inbound-pre-alert': { path: 'inventory/stock-transit/{id}/inbound-pre-alert/send', method: 'POST' },
                    'import-transit-request':  { path: 'inventory/stock-transit/{id}/import', method: 'POST' },
                    'import-receiving':  { path: 'inventory/stock-transit/{id}/import', method: 'POST' },
                    'clear-all-os-qty' : { path: 'inventory/transit-requests/{id}/clearAllOSQty', method:'POST' },
                    'create-vm-stockout' : { path: 'inventory/transit-requests/{id}/createVMStockout', method:'POST' },
                    'convert-received-fg-to-rm' : { path: 'inventory/transit-requests/{id}/convertReceivedFGToRM', method:'POST' }
                }
            });
            let query = {
                status: config.currentStatus,
                transitId: config.currentTransitId,
                fromRequestedAt: config.currentFromRequestedAt,
                toRequestedAt: config.currentToRequestedAt,
                fromLocationId: config.fromLocationId,
                toLocationId: config.toLocationId,
                createdBy: config.createdBy,
            }
            vm.gridDataSource = new GridDataSource({
                // perPage: 10,
                stationId: config.stationId,
                orderId: config.currentOrderId,
                resoucesUrl: config.dataUrl + "?" + (new URLSearchParams(query).toString()),
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $('#modal-show-request')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/inventory/stock-transit");
                });
            PageViewModel.update();
            if (config.stockTransitRequestId) {
                vm.openDetailDialog({id: config.stockTransitRequestId})
            }
            setInterval(PageViewModel.update, 5000);

            $scope.$watch(function() {
                return vm.detail && vm.detail.items ? JSON.stringify(vm.detail.items) : null;
            }, function(items) {
                items = JSON.parse(items);
                vm.detail.totalRequestQTY = 0;
                vm.detail.totalFulfiledQTY = 0;
                vm.detail.totalReceivedQTY = 0;
                items.forEach((item) => {
                    if (item.requestQTY) {
                        vm.detail.totalRequestQTY += item.requestQTY;
                    }
                    if (item.fulfilledQTY) {
                        vm.detail.totalFulfiledQTY += item.fulfilledQTY;
                    }
                    if (item.receivedQTY) {
                        vm.detail.totalReceivedQTY += item.receivedQTY;
                    }
                    vm.detail.unfulfillReason = item.unfulfillReason;
                })
            });
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
            vm.detail.expiryDate = null;
            vm.detail.remark = null;
        }

        function openDetailDialog(row) {
            window.history.pushState(null, null, "/inventory/stock-transit/" + row.id);
            PageViewModel._isLoading = true;
            vm.isDisabled = false;
            vm.totalRequestQTY = 0;
            vm.totalFulfiledQTY = 0;
            vm.totalReceivedQTY = 0;
            serverGateway.ajax('show-transit-request', {id: row.id}, {simplyMode: vm.simplyMode, showBacklog: vm.showBacklog}).then(function(response) {
                PageViewModel._isLoading = false;
                PageViewModel.openModal('#modal-show-request');
                vm.detail = response.data.data;
                vm.detail.fromLocation = vm.detail.fromLocation.id;
                vm.detail.toLocation = vm.detail.toLocation.id;
                vm.detail.allocateItems = true;
                vm.detail.hasFG = false;
                if(vm.detail.estimateArriveDate){
                    let estimateArriveDate = vm.detail.estimateArriveDate.replace(/\s/, 'T');
                    vm.detail.estimateArriveDate = new Date(estimateArriveDate);
                    vm.detail.estimateArriveDateDisplay = moment(vm.detail.estimateArriveDate).format('YYYY-MM-DD HH:mm:ss');
                }
                if(vm.detail.jpwhSpecifiedDeliveryDate){
                    let jpwhSpecifiedDeliveryDate = vm.detail.jpwhSpecifiedDeliveryDate.replace(/\s/, 'T');
                    vm.detail.jpwhSpecifiedDeliveryDate = new Date(jpwhSpecifiedDeliveryDate);
                    vm.detail.jpwhSpecifiedDeliveryDateDisplay = moment(vm.detail.jpwhSpecifiedDeliveryDate).format('YYYY-MM-DD');
                }
                vm.detail.items = [];
                vm.detail.hasReceivedItems = false;
                response.data.data.requestItems.forEach((item) => {
                    if (item.rawMaterial) {
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.rawMaterial.name + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.rawMaterial.name;
                        }
                        
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                id: item.id,
                                name: item.rawMaterial.name,
                                description: item.rawMaterial.description,
                                ref_id: item.ref_id,
                                sale_channel_id: item.sale_channel_id,
                                fromInventoryQTY: 0,
                                allocatedQTY: 0,
                                transitInventoryQTY: 0,
                                toInventoryQTY: 0,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0,
                                alternateSKU: item.alternateSku ? 'Finished Good SKU: ' + item.alternateSku : null,
                                unfulfillReason: item.unfulfillReason,
                            }
                            if (item.fromInventoryQTY) {
                                vm.detail.items[skuKey].fromInventoryQTY += item.fromInventoryQTY;
                            }
                            if (item.toInventoryQTY) {
                                vm.detail.items[skuKey].toInventoryQTY += item.toInventoryQTY;
                            }
                            if (item.allocatedQTY) {
                                vm.detail.items[skuKey].allocatedQTY += item.allocatedQTY;
                            }
                            if (item.transitInventoryQTY) {
                                vm.detail.items[skuKey].transitInventoryQTY += item.transitInventoryQTY;
                            }
                            let lookupFromLocation = vm.detail.fromLocation
                            if (vm.detail.fromLocation == 10) {
                                lookupFromLocation = 11
                            }
                            let lookupToLocation = vm.detail.toLocation
                            if (vm.detail.toLocation == 10) {
                                lookupToLocation = 11
                            }
                            if(item.rawMaterial.backlog && item.rawMaterial.backlog[lookupFromLocation]){
                                vm.detail.items[skuKey].fromQTY = item.rawMaterial.backlog[lookupFromLocation];
                            }
                            if(item.rawMaterial.backlog && item.rawMaterial.backlog[lookupToLocation]){
                                vm.detail.items[skuKey].toQTY = item.rawMaterial.backlog[lookupToLocation];
                            }
                        }
                        vm.detail.items[skuKey].allow_printing = item.rawMaterial.allow_printing;
                        vm.detail.items[skuKey].isWIPItem = item.rawMaterial.isWIPItem;
                        // if (item.fromInventoryQTY) {
                        //     vm.detail.items[item.rawMaterial.name].fromInventoryQTY += item.fromInventoryQTY;
                        // }
                        if (item.quantity) {
                            vm.detail.items[skuKey].requestQTY += item.quantity;
                        }
                        if (item.inOutBoxQty){
                            vm.detail.items[skuKey].inOutBoxQty = item.inOutBoxQty;
                        }
                    }
                    if (item.finishedGood) {
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.finishedGood.sku + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.finishedGood.sku;
                        }
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                id: item.id,
                                name: item.finishedGood.sku,
                                description: (typeof item.productName != 'undefined' && item.productName) ? item.productName : item.finishedGood.sku,
                                ref_id: item.ref_id,
                                sale_channel_id: item.sale_channel_id,
                                fromInventoryQTY: 0,
                                allocatedQTY: 0,
                                transitInventoryQTY: 0,
                                toInventoryQTY: 0,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                alternateSKU: item.alternateSku ? 'Raw Material SKU: ' + item.alternateSku : null,
                                unfulfillReason: item.unfulfillReason,
                            }
                            if (item.fromInventoryQTY) {
                                vm.detail.items[skuKey].fromInventoryQTY += item.fromInventoryQTY;
                            }
                            if (item.toInventoryQTY) {
                                vm.detail.items[skuKey].toInventoryQTY += item.toInventoryQTY;
                            }
                            if (item.allocatedQTY) {
                                vm.detail.items[skuKey].allocatedQTY += item.allocatedQTY;
                            }
                            if (item.transitInventoryQTY) {
                                vm.detail.items[skuKey].transitInventoryQTY += item.transitInventoryQTY;
                            }
                        }
                        if (item.quantity) {
                            vm.detail.items[skuKey].requestQTY += item.quantity;
                        }
                        if(item.inOutBoxQty){
                            vm.detail.items[skuKey].inOutBoxQty = item.inOutBoxQty;
                        }
                    }
                })

                response.data.data.fulfillmentRecords.forEach((item) => {
                    if (item.fromInoutRecord.sku) {
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.fromInoutRecord.sku + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.fromInoutRecord.sku;
                        }
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                name: item.fromInoutRecord.sku,
                                description: item.fromInoutRecord.rawMaterialDesc,
                                ref_id: item.ref_id,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0,
                            }
                        }
                        if (item.fromInoutRecord.quantity) {
                            vm.detail.items[skuKey].fulfilledQTY += Math.abs(item.fromInoutRecord.quantity);
                        }
                        if(item.fromInoutRecord.backlog && item.fromInoutRecord.backlog[vm.detail.fromLocation]){
                            vm.detail.items[skuKey].fromQTY = item.fromInoutRecord.backlog[vm.detail.fromLocation];
                        }
                        if(item.fromInoutRecord.backlog && item.fromInoutRecord.backlog[vm.detail.toLocation]){
                            vm.detail.items[skuKey].toQTY = item.fromInoutRecord.backlog[vm.detail.toLocation];
                        }
                    }
                    if (item.fromInoutRecord.finishedGoodSKU) {
                        vm.detail.hasFG = true;
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.fromInoutRecord.finishedGoodSKU + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.fromInoutRecord.finishedGoodSKU;
                        }
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                name: item.fromInoutRecord.finishedGoodSKU,
                                description: item.fromInoutRecord.finishedGoodSKU,
                                ref_id: item.ref_id,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                            }
                        }
                        if (item.fromInoutRecord.quantity) {
                            vm.detail.items[skuKey].fulfilledQTY += Math.abs(item.fromInoutRecord.quantity);
                        }
                    }
                    
                })
                response.data.data.completionRecords.forEach((item) => {
                    if (item.toInoutRecord && item.toInoutRecord.quantity) {
                        vm.detail.hasReceivedItems = true;
                    }
                    if (item.toInoutRecord.sku) {
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.toInoutRecord.sku + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.toInoutRecord.sku;
                        }
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                name: item.toInoutRecord.sku,
                                description: item.toInoutRecord.rawMaterialDesc,
                                ref_id: item.ref_id,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0
                            }
                        }
                        if (item.toInoutRecord.quantity) {
                            vm.detail.items[skuKey].receivedQTY += Math.abs(item.toInoutRecord.quantity);
                        }
                        if(item.toInoutRecord.backlog && item.toInoutRecord.backlog[vm.detail.fromLocation]){
                            vm.detail.items[skuKey].fromQTY = item.toInoutRecord.backlog[vm.detail.fromLocation];
                        }
                        if(item.toInoutRecord.backlog && item.toInoutRecord.backlog[vm.detail.toLocation]){
                            vm.detail.items[skuKey].toQTY = item.toInoutRecord.backlog[vm.detail.toLocation];
                        }
                    }
                    if (item.toInoutRecord.finishedGoodSKU) {
                        let skuKey = null;
                        if (vm.detail.status == 'draft') {
                            skuKey = item.ref_id + " | " + item.toInoutRecord.finishedGoodSKU + " | " + item.sale_channel_id;
                        } else {
                            skuKey = item.toInoutRecord.finishedGoodSKU;
                        }
                        if (!vm.detail.items[skuKey]) {
                            vm.detail.items[skuKey] = {
                                name: item.toInoutRecord.finishedGoodSKU,
                                description: item.toInoutRecord.finishedGoodSKU,
                                ref_id: item.ref_id,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                            }
                        }
                        if (item.toInoutRecord.quantity) {
                            vm.detail.items[skuKey].receivedQTY += Math.abs(item.toInoutRecord.quantity);
                        }
                    }
                })
                vm.detail.items = Object.values(vm.detail.items);
                vm.detail.canFulfill = vm.detail.fulfillmentRecords.length > 0;
                vm.detail.canReceive = vm.detail.completionRecords.length == 0 || (vm.detail.status == 'completed' && !vm.detail.receivedBy) || vm.detail.isCompleted;
                // $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showTransitRequestModal() {
            vm.detail = {};
            if (config.fromLocationList && config.fromLocationList.length) {
                vm.detail.fromLocation = config.fromLocationList[0].key;
            }
            if (config.toLocationList && config.toLocationList.length) {
                vm.detail.toLocation = config.toLocationList[0].key;
            }
            PageViewModel.openModal('#modal-new-request');
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-new-request');
            PageViewModel.hideModal('#modal-show-request');
        }

        function createStockTransitRequest() {
            vm.detail.createStockTransitRequestLoading = true;
            let self = this;
            serverGateway.ajax('create-transit-request', '', vm.detail).then(function(response) {
                vm.detail.createStockTransitRequestLoading = false;
                PageViewModel.update();
                PageViewModel.hideModal("#modal-new-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                console.log(response.data)
                self.openDetailDialog({id: response.data.data.id});
            }, function (response) {
                vm.detail.createStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateStockTransitRequest(callback, autofill = false, skipAutoComplete = false) {
            // if (vm.detail.status == 'draft') {
            //     vm.detail.isDrafted = !confirm('Is request finalized?');
            // }
            if(checkIsProcessing(vm.detail.id)){
                return;
            }
            startProcessing(vm.detail.id);
            if(vm.detail.estimateArriveDate){
                vm.detail.estimateArriveDateValue = moment(vm.detail.estimateArriveDate).format('YYYY-MM-DD HH:mm:ss');
            }
            if(vm.detail.jpwhSpecifiedDeliveryDate){
                vm.detail.jpwhSpecifiedDeliveryDateValue = moment(vm.detail.jpwhSpecifiedDeliveryDate).format('YYYY-MM-DD');
            }
            vm.detail.updateStockTransitRequestLoading = true;
            let self = this;
            serverGateway.ajax('check-duplicate-request', {id: vm.detail.id},vm.detail).then(function(response){
                
                if(response.data.status == 'warning'){
                    let duplicates = "";
                    let res = response.data.duplicate;
                    Object.keys(res).forEach(function(key,val){
                        duplicates += key + ": " + res[key].join(',') + "\n";
                    });
                    if(!confirm("There are existing items in the following request(s):\n" + duplicates + "Do you wish to continue anyway? ")) return;
                }
                if (autofill == 1) {
                    vm.detail.autofill = true;
                    vm.detail.autofillReceived = false;
                } else if (autofill == 2) {
                    vm.detail.autofill = false;
                    vm.detail.autofillReceived = true;
                } else {
                    vm.detail.autofill = false;
                    vm.detail.autofillReceived = false;
                }
                
                vm.detail.skipAutoComplete = skipAutoComplete;
                serverGateway.ajax('update-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                    if (callback) {
                        vm.detail.updateStockTransitRequestLoading = false;
                        endProcessing(vm.detail.id);
                        callback();
                    } else {
                        vm.detail.updateStockTransitRequestLoading = false;
                        endProcessing(vm.detail.id);
                        // PageViewModel.hideModal("#modal-show-request");
                        PageViewModel.update();
                        self.openDetailDialog(vm.detail);
                        $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    }
                }, function (response) {
                    vm.detail.updateStockTransitRequestLoading = false;
                    endProcessing(vm.detail.id);
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });

            }, function(response){
                vm.detail.updateStockTransitRequestLoading = false;
                endProcessing(vm.detail.id);
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function addItem() {
            vm.detail.items.push({'name': null, 'requestQTY': 0});
        }

        function removeItem(key) {
            vm.detail.items.splice(key, 1);
        }

        function fulfillStockTransitRequest(autofill = false) {
            if (!vm.detail.canFulfill && !autofill) {
                return false;
            }

            if (vm.detail.fromLocation == 10) {
                for (var i = 0; i < vm.detail.items.length; ++i) {

                    if (autofill) {
                        vm.detail.items[i]['unfulfillReason'] = '';
                        continue;
                    }

                    if (typeof(vm.detail.items[i]['fulfilledQTY']) === 'undefined') {
                        vm.detail.items[i]['fulfilledQTY'] = 0;
                    }

                    if (typeof(vm.detail.items[i]['unfulfillReason']) === 'undefined') {
                        vm.detail.items[i]['unfulfillReason'] = '';
                    }

                    if (vm.detail.items[i]['fulfilledQTY'] < vm.detail.items[i]['requestQTY'] && vm.detail.items[i]['unfulfillReason'] === null && (vm.detail.toLocation != 11 && vm.detail.toLocation != 128)) {
                        $.bootstrapGrowl('Please select unfulfillment reason!', {ele: 'body', type: 'error'});
                        return false;
                    }
                }
            }
            if (!confirm('Confirm?')) {
                return false;
            }
            let self = this;
            vm.isDisabled = true;
            vm.updateStockTransitRequest(function () {
                if(autofill){
                    PageViewModel.update();
                    self.openDetailDialog(vm.detail);
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    vm.isDisabled = false;
                    return;
                }
                vm.detail.updateStockTransitRequestLoading = true;
                serverGateway.ajax('fulfill-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) { 
                    vm.detail.updateStockTransitRequestLoading = false;
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    vm.isDisabled = false;
                }, function (response) {
                    vm.detail.updateStockTransitRequestLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    vm.isDisabled = false;
                });
            }, autofill)
            
        }

        function fulfillStockTransitRequestWithoutUpdate(){
            if (!vm.detail.canFulfill) {
                return false;
            }
            if (!confirm('Confirm?')) {
                return false;
            }
            let self = this;
            vm.isDisabled = true;
            vm.detail.updateStockTransitRequestLoading = true;
            serverGateway.ajax('fulfill-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.updateStockTransitRequestLoading = false;
                PageViewModel.update();
                self.openDetailDialog({id: vm.detail.id});
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                vm.isDisabled = false;
            }, function (response) {
                vm.detail.updateStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isDisabled = false;
            });
        }

        function confirmStockTransitRequest() {
            if (!confirm('Confirm?')) {
                return false;
            }
            let self = this;
            vm.isDisabled = true;
            vm.updateStockTransitRequest(function () {
                if(checkIsProcessing(vm.detail.id)){
                    return;
                }
                startProcessing(vm.detail.id);
                vm.detail.confirmStockTransitRequestLoading = true;
                serverGateway.ajax('confirm-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                    vm.detail.confirmStockTransitRequestLoading = false;
                    endProcessing(vm.detail.id);
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    vm.isDisabled = false;
                }, function (response) {
                    vm.detail.confirmStockTransitRequestLoading = false;
                    endProcessing(vm.detail.id);
                    if (response.data.message == 'Please approve the request by senior.') {
                        PageViewModel.openModal('#approvalRequestDialog');
                    } else {
                        $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    }
                    vm.isDisabled = false;
                });
            })
        }

        function rejectStockTransitRequest() {
            console.log('rejectStockTransitRequest START');
            if(!vm.detail.canReject){
                return false;
            }
            let reason = prompt("Reject reason: ", '');
            let self = this;
            vm.updateStockTransitRequest(function (){
                vm.detail.updateStockTransitRequestLoading = true;
                serverGateway.ajax('reject-transit-request', {id: vm.detail.id}, {reason: reason}).then(function(response){
                    vm.detail.updateStockTransitRequestLoading = false;
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }, function(response){
                    vm.detail.updateStockTransitRequestLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            });
            
        }

        function autofillReceivedStockTransitRequest() {
            if (!vm.detail.canReceive) {
                return false;
            }
            let self = this;
            for (let i in vm.detail.items) {
                vm.detail.items[i].receivedQTY = vm.detail.items[i].fulfilledQTY;
            }
            vm.detail.receivedStockTransitRequestLoading = true;
            updateStockTransitRequest(() => {
                vm.detail.receivedStockTransitRequestLoading = false;
                $.bootstrapGrowl("Updated", {ele: 'body', type: 'success'});
            }, false, true)
        }

        function receivedStockTransitRequest() {
            if (!vm.detail.canReceive) {
                return false;
            }
            if (!confirm('Confirm receiving all items?')) {
                return false;
            }
            let self = this;
            vm.isDisabled = true;
            vm.detail.receivedStockTransitRequestLoading = true;
            serverGateway.ajax('received-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.receivedStockTransitRequestLoading = false;
                PageViewModel.update();
                self.openDetailDialog({id: vm.detail.id});
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                vm.isDisabled = false;
            }, function (response) {
                vm.detail.receivedStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isDisabled = false;
            });
            
        }

        function cancelStockTransitRequest() {
            if (vm.detail.status != 'draft' && vm.detail.status != 'rejected') {
                return false;
            }
            if (!confirm('Confirm delete this request?')) {
                return false;
            }
            vm.detail.cancelStockTransitRequestLoading = true;
            serverGateway.ajax('cancel-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.cancelStockTransitRequestLoading = false;
                PageViewModel.update();
                PageViewModel.hideModal("#modal-show-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.cancelStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function clearAllOSQty() {
            if (!confirm('Confirm to clear All O/S Qty?')) {
                return false;
            }
            let self = this;
            vm.isDisabled = true;
            vm.detail.clearAllOSQtyLoading = true;
            serverGateway.ajax('clear-all-os-qty', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.clearAllOSQtyLoading = false;
                PageViewModel.update();
                self.openDetailDialog({id: vm.detail.id});
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                vm.isDisabled = false;
            }, function (response) {
                vm.detail.clearAllOSQtyLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isDisabled = false;
            });
        }

        function sendApprovalRequest() {
            if (!vm.approvalPerson) {
                $.bootstrapGrowl('Invalid user', {ele: 'body', type: 'error'});
                return false;
            }
            vm.detail.sendApprovalRequestLoading = false;
            serverGateway.ajax('send-approval-request', {id: vm.detail.id}, {email: vm.approvalPerson}).then(function(response) {
                vm.detail.sendApprovalRequestLoading = false;
                PageViewModel.hideModal("#approvalRequestDialog");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.sendApprovalRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function stockOutVM() {
            let self = this;
            if (vm.detail.stockOutVMLoading) return false;
            vm.detail.stockOutVMLoading = true;
            serverGateway.ajax('create-vm-stockout', {id: vm.detail.id}).then(function(response) {
                vm.detail.stockOutVMLoading = false;
                PageViewModel.update();
                self.openDetailDialog(vm.detail);
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.stockOutVMLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function convertReceivedFGToRM() {
            let self = this;
            if (vm.detail.convertReceivedFGToRMLoading) return false;
            vm.detail.convertReceivedFGToRMLoading = true;
            serverGateway.ajax('convert-received-fg-to-rm', {id: vm.detail.id}).then(function(response) {
                vm.detail.convertReceivedFGToRMLoading = false;
                PageViewModel.update();
                self.openDetailDialog(vm.detail);
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.convertReceivedFGToRMLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printLabel() {
            if (!confirm('The system takes around 5 seconds to generate 1 label and send it to the printer. \nConfirm to print label?')) {
                return false;
            }
            vm.isPrinting = true;
            $.bootstrapGrowl('The labels are generating and will be printed shortly.', {ele: 'body', type: 'success'});

            serverGateway.ajax('print-label', {id: vm.detail.id}, { 'printer' :vm.printer }).then(function(response) {
                vm.isPrinting = false;
            }, function (response) {
                vm.isPrinting = false;
            });
        }

        function showStockTransitImport() {
            PageViewModel.openModal('#modal-stock-transit-import');
        }

        function stockTransitImport() {
            vm.detail.processing = true;
            serverGateway.ajax('import-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {

                PageViewModel.hideModal();
                delete vm.detail.processing;
                PageViewModel.update();
            });
        }

        function showReceivingImport() {
            PageViewModel.openModal('#modal-receiving-import');
        }

        function receivingImport() {
            vm.detail.processing = true;
            serverGateway.ajax('import-receiving', {id: vm.detail.id}, vm.detail).then(function(response) {

                PageViewModel.hideModal();
                delete vm.detail.processing;
                PageViewModel.update();
            });
        }

        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        function checkIsProcessing(id){
            if(vm.isProcessing[id]){
                $.bootstrapGrowl('Transit is still processing', {ele: 'body', type: 'error'});
                return true;
            }
            return false;
        }

        function startProcessing(id){
            vm.isProcessing[id] = true;
        }

        function endProcessing(id){
            vm.isProcessing[id] = false;
        }

        function changeStockTransitSimplyMode(){
            localStorage.setItem('stockTransitSimplyMode', vm.simplyMode?'true':'false');
            if(vm.simplyMode){
                vm.showBacklog = false;
                changeShowBacklog();
            }
        }

        function changeShowBacklog(){
            localStorage.setItem('stockTransitShowBacklog', vm.showBacklog?'true':'false');
        }
    }

    function stockTransitBatchController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.confirmButtonLoading = false;
        vm.resetButtonLoading = false;

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.deleteStockTransitBatch = deleteStockTransitBatch;
        vm.confirmStockTransitBatch = confirmStockTransitBatch;
        vm.resetStockTransitBatch = resetStockTransitBatch;
        vm.createStockOutFromOustanding = createStockOutFromOustanding;
        vm.createStockTransitFromOustanding = createStockTransitFromOustanding;
        
        vm.openDetailDialog = openDetailDialog;
        

        initialize();

        function initialize() {
            
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                  'delete-stock-transit-batch'  : { path: 'inventory/stock-transit-batches/{id}', method: 'DELETE' },
                  'confirm-stock-transit-batch'  : { path: 'inventory/stock-transit-batches/{id}/confirm', method: 'POST' },
                  'reset-stock-transit-batch'  : { path: 'inventory/stock-transit-batches/{id}/reset', method: 'POST' },
                  'create-stock-out-session-from-oustanding'  : { path: 'inventory/stockout-sessions/{id}/create-new-from-outstanding', method: 'POST' },
                  'create-stock-transit-from-oustanding'  : { path: 'inventory/stock-transit/{id}/create-new-from-outstanding', method: 'POST' },
                  
                }
            });

            if (!config.dataUrl) return
            let query = {
                status: config.status,
                id: config.id,
                // transitId: config.currentTransitId,
                // fromRequestedAt: config.currentFromRequestedAt,
                // toRequestedAt: config.currentToRequestedAt,
                // fromLocationId: config.fromLocationId,
                // toLocationId: config.toLocationId
            }
            vm.gridDataSource = new GridDataSource({
                // perPage: 10,
                stationId: config.stationId,
                orderId: config.orderId,
                resoucesUrl: config.dataUrl + "?" + (new URLSearchParams(query).toString()),
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            
            PageViewModel.update();
            
            setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
            vm.detail.expiryDate = null;
            vm.detail.remark = null;
        }

        function openDetailDialog(row) {
            window.location.href="/inventory/stock-transit-batches/" + row.id;
        }

        function deleteStockTransitBatch(id) {
            serverGateway.ajax('delete-stock-transit-batch', {id: id}).then(function(response){
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.href="/inventory/stock-transit-batches";
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function confirmStockTransitBatch(id) {
            vm.confirmButtonLoading = true;
            serverGateway.ajax('confirm-stock-transit-batch', {id: id}).then(function(response){
                // dont release btn
                // vm.confirmButtonLoading = false;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function(response){
                vm.confirmButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function resetStockTransitBatch(id) {
            vm.resetButtonLoading = true;
            serverGateway.ajax('reset-stock-transit-batch', {id: id}).then(function(response){
                // dont release btn
                // vm.resetButtonLoading = false;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function(response){
                vm.resetButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function createStockOutFromOustanding(id) {
            serverGateway.ajax('create-stock-out-session-from-oustanding', {id: id}).then(function(response){
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function createStockTransitFromOustanding(id) {
            serverGateway.ajax('create-stock-transit-from-oustanding', {id: id}).then(function(response){
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

    }

    function printingStockTransitController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.printer          = config.defaultPrinterId ? config.defaultPrinterId : config.printerList[0].key;
        vm.isPrinting       = false;
        vm.currentFromRequestedAt = '';
        vm.currentToRequestedAt = '';

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.showTransitRequestModal = showTransitRequestModal;
        vm.updateStockTransitRequest = updateStockTransitRequest;
        vm.fulfillStockTransitRequest = fulfillStockTransitRequest;
        vm.receivedStockTransitRequest = receivedStockTransitRequest;
        vm.confirmStockTransitRequest = confirmStockTransitRequest;
        vm.cancelStockTransitRequest = cancelStockTransitRequest;
        vm.sendApprovalRequest = sendApprovalRequest;
        vm.rejectStockTransitRequest = rejectStockTransitRequest;
        vm.printPickingLabel = printPickingLabel;
        vm.printLabel = printLabel;
        vm.deleteCancel = deleteCancel;
        vm.openDetailDialog = openDetailDialog;
        vm.fromLocationList = config.fromLocationList;
        vm.printerList = config.printerList;
        vm.toLocationList = config.toLocationList;
        vm.locationList = config.locationList;
        vm.jpwhDeliveryTimeZoneList = config.jpwhDeliveryTimeZoneList;
        vm.seniorList = config.seniorList;
        vm.exportSummary = exportSummary;
        vm.exportDefectReport = exportDefectReport;
        vm.fulfillAll = fulfillAll;
        vm.receiveAll = receiveAll;
        vm.bulkAction = bulkAction;
        vm.formatItemDescription = formatItemDescription;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'check-duplicate-request': {path: 'inventory/check-duplicate-request/{id}', method:'POST'},
                    'create-transit-request': { path: 'inventory/transit-requests', method: 'POST' },
                    'show-transit-request': { path: 'inventory/transit-requests/{id}', method: 'GET' },
                    'update-transit-request': { path: 'inventory/transit-requests/{id}', method: 'POST' },
                    'fulfill-transit-request': { path: 'inventory/transit-requests/{id}/fulfill', method: 'POST' },
                    'confirm-transit-request': { path: 'inventory/transit-requests/{id}/confirm', method: 'POST' },
                    'reject-transit-request': { path: 'inventory/transit-requests/{id}/reject', method: 'POST'},
                    'received-transit-request': { path: 'inventory/transit-requests/{id}/received', method: 'POST' },
                    'cancel-transit-request': { path: 'inventory/transit-requests/{id}/cancel', method: 'POST' },
                    'send-approval-request': { path: 'inventory/transit-requests/{id}/approval-request/send', method: 'POST' },
                    'print-label': { path: 'inventory/transit-requests/print-label/{id}', method: 'POST' },
                    'print-picking-label': { path: 'inventory/transit-requests/{id}/picking-label/print', method: 'POST' },
                    'exportSummary': {path: 'inventory/export-transit-summary', method: 'POST'},
                    'exportDefectReport': {path: 'inventory/export-defect-report', method: 'POST'},
                    'fulfillAll': {path: 'inventory/printing-transit-requests/fulfill-all', method: 'POST'},
                    'receiveAll': {path: 'inventory/printing-transit-requests/receive-all', method: 'POST'},
                    'send-inbound-pre-alert': { path: 'inventory/stock-transit/{id}/inbound-pre-alert/send', method: 'POST' },
                }
            });
            let query = {
                status: config.currentStatus,
                printStation: config.currentPrintStation,
                batchId: config.currentBatchId,
                id: config.currentId,
                fromRequestedAt: config.currentFromRequestedAt,
                toRequestedAt: config.currentToRequestedAt,
                labelPrinted: config.currentLabelPrinted,
            }
            vm.gridDataSource = new GridDataSource({
                perPage: config.dataUrl == 'inventory/printing-transit-requests' ? 99999 : 10,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl + "?" + (new URLSearchParams(query).toString()),
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $('#modal-show-request')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/inventory/printing-stock-transit");
                });
            PageViewModel.update();
            if (config.stockTransitRequestId) {
                vm.openDetailDialog({id: config.stockTransitRequestId})
            }
            setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
            vm.detail.expiryDate = null;
            vm.detail.remark = null;
        }

        function exportSummary(){
            //Get filtered 
            serverGateway.ajax('exportSummary',null,{id : vm.rows.map((row) => row.id)}).then(function(response){
                console.log(response.data.status);
                if(response.data.status == 'OK'){
                    let url = '/inventory/file/'+response.data.filename;
                    window.location.href = url;
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function exportDefectReport(){
            serverGateway.ajax('exportDefectReport',null,{from: vm.currentFromRequestedAt, to: vm.currentToRequestedAt}).then(function(response){
                console.log(response.data.status);
                if(response.data.status == 'OK'){
                    let url = '/inventory/file/'+response.data.filename;
                    window.location.href = url;
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDetailDialog(row) {
            window.history.pushState(null, null, "/inventory/printing-stock-transit/" + row.id);
            PageViewModel._isLoading = true;
            serverGateway.ajax('show-transit-request', {id: row.id}).then(function(response) {
                PageViewModel._isLoading = false;
                PageViewModel.openModal('#modal-show-request');
                vm.detail = response.data.data;
                vm.detail.cloudPrinterId = vm.detail.fromLocation.cloud_printer_id;
                vm.detail.fromLocation = vm.detail.fromLocation.id;
                vm.detail.toLocation = vm.detail.toLocation.id;
                vm.detail.stockBatch = vm.detail.stockBatch;
                vm.detail.items = [];
                response.data.data.requestItems.forEach((item) => {
                    if (item.rawMaterial) {
                        if (!vm.detail.items[item.rawMaterial.name]) {
                            vm.detail.items[item.rawMaterial.name] = {
                                name: item.rawMaterial.name,
                                description: item.rawMaterial.description,
                                fromInventoryQTY: 0,
                                transitInventoryQTY: 0,
                                toInventoryQTY: 0,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0,
                                lastAreaCode: '',
                                wipAreaCode: '',
                            }
                            if (item.fromInventoryQTY) {
                                vm.detail.items[item.rawMaterial.name].fromInventoryQTY += item.fromInventoryQTY;
                            }
                            // if (item.toInventoryQTY) {
                            //     vm.detail.items[item.rawMaterial.name].toInventoryQTY += item.toInventoryQTY;
                            // }
                            // if (item.transitInventoryQTY) {
                            //     vm.detail.items[item.rawMaterial.name].transitInventoryQTY += item.transitInventoryQTY;
                            // }
                            // let lookupFromLocation = vm.detail.fromLocation
                            // if (vm.detail.fromLocation == 10) {
                            //     lookupFromLocation = 11
                            // }
                            // let lookupToLocation = vm.detail.toLocation
                            // if (vm.detail.toLocation == 10) {
                            //     lookupToLocation = 11
                            // }
                            // if(item.rawMaterial.backlog[lookupFromLocation]){
                            //     vm.detail.items[item.rawMaterial.name].fromQTY = item.rawMaterial.backlog[lookupFromLocation];
                            // }
                            // if(item.rawMaterial.backlog[lookupToLocation]){
                            //     vm.detail.items[item.rawMaterial.name].toQTY = item.rawMaterial.backlog[lookupToLocation];
                            // }
                        }
                        vm.detail.items[item.rawMaterial.name].allow_printing = item.rawMaterial.allow_printing;
                        vm.detail.items[item.rawMaterial.name].isWIPItem = item.rawMaterial.isWIPItem;
                        vm.detail.items[item.rawMaterial.name].lastAreaCode = item.lastAreaCode;
                        vm.detail.items[item.rawMaterial.name].wipAreaCode = item.wipAreaCode;
                        // if (item.fromInventoryQTY) {
                        //     vm.detail.items[item.rawMaterial.name].fromInventoryQTY += item.fromInventoryQTY;
                        // }
                        if (item.quantity) {
                            vm.detail.items[item.rawMaterial.name].requestQTY += item.quantity;
                        }
                        if (item.inOutBoxQty){
                            vm.detail.items[item.rawMaterial.name].inOutBoxQty = item.inOutBoxQty;
                        }
                    }
                    if (item.finishedGood) {
                        if (!vm.detail.items[item.finishedGood.sku]) {
                            vm.detail.items[item.finishedGood.sku] = {
                                name: item.finishedGood.sku,
                                description: item.finishedGood.sku,
                                fromInventoryQTY: 0,
                                transitInventoryQTY: 0,
                                toInventoryQTY: 0,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                            }
                            // if (item.fromInventoryQTY) {
                            //     vm.detail.items[item.finishedGood.sku].fromInventoryQTY += item.fromInventoryQTY;
                            // }
                            // if (item.toInventoryQTY) {
                            //     vm.detail.items[item.finishedGood.sku].toInventoryQTY += item.toInventoryQTY;
                            // }
                            // if (item.transitInventoryQTY) {
                            //     vm.detail.items[item.finishedGood.sku].transitInventoryQTY += item.transitInventoryQTY;
                            // }
                        }
                        if (item.quantity) {
                            vm.detail.items[item.finishedGood.sku].requestQTY += item.quantity;
                        }
                        if (item.inOutBoxQty){
                            vm.detail.items[item.finishedGood.sku].inOutBoxQty = item.inOutBoxQty;
                        }
                    }
                })

                response.data.data.fulfillmentRecords.forEach((item) => {
                    if (item.fromInoutRecord.sku) {
                        if (!vm.detail.items[item.fromInoutRecord.sku]) {
                            vm.detail.items[item.fromInoutRecord.sku] = {
                                name: item.fromInoutRecord.sku,
                                description: item.fromInoutRecord.rawMaterialDesc,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0
                            }
                        }
                        if (item.fromInoutRecord.quantity) {
                            vm.detail.items[item.fromInoutRecord.sku].fulfilledQTY += Math.abs(item.fromInoutRecord.quantity);
                        }
                        // if(item.fromInoutRecord.backlog[vm.detail.fromLocation]){
                        //     vm.detail.items[item.fromInoutRecord.sku].fromQTY = item.fromInoutRecord.backlog[vm.detail.fromLocation];
                        // }
                        // if(item.fromInoutRecord.backlog[vm.detail.toLocation]){
                        //     vm.detail.items[item.fromInoutRecord.sku].toQTY = item.fromInoutRecord.backlog[vm.detail.toLocation];
                        // }
                    }
                    if (item.fromInoutRecord.finishedGoodSKU) {
                        if (!vm.detail.items[item.fromInoutRecord.finishedGoodSKU]) {
                            vm.detail.items[item.fromInoutRecord.finishedGoodSKU] = {
                                name: item.fromInoutRecord.finishedGoodSKU,
                                description: item.fromInoutRecord.finishedGoodSKU,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                            }
                        }
                        if (item.fromInoutRecord.quantity) {
                            vm.detail.items[item.fromInoutRecord.finishedGoodSKU].fulfilledQTY += Math.abs(item.fromInoutRecord.quantity);
                        }
                    }
                    
                })
                response.data.data.completionRecords.forEach((item) => {
                    if (item.toInoutRecord.sku) {
                        if (!vm.detail.items[item.toInoutRecord.sku]) {
                            vm.detail.items[item.toInoutRecord.sku] = {
                                name: item.toInoutRecord.sku,
                                description: item.toInoutRecord.rawMaterialDesc,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                                fromQTY: 0,
                                toQTY: 0
                            }
                        }
                        if (item.toInoutRecord.quantity) {
                            vm.detail.items[item.toInoutRecord.sku].receivedQTY += Math.abs(item.toInoutRecord.quantity);
                        }
                        // if(item.toInoutRecord.backlog[vm.detail.fromLocation]){
                        //     vm.detail.items[item.toInoutRecord.sku].fromQTY = item.toInoutRecord.backlog[vm.detail.fromLocation];
                        // }
                        // if(item.toInoutRecord.backlog[vm.detail.toLocation]){
                        //     vm.detail.items[item.toInoutRecord.sku].toQTY = item.toInoutRecord.backlog[vm.detail.toLocation];
                        // }
                    }
                    if (item.toInoutRecord.finishedGoodSKU) {
                        if (!vm.detail.items[item.toInoutRecord.finishedGoodSKU]) {
                            vm.detail.items[item.toInoutRecord.finishedGoodSKU] = {
                                name: item.toInoutRecord.finishedGoodSKU,
                                description: item.toInoutRecord.finishedGoodSKU,
                                requestQTY: 0,
                                inOutBoxQty: 0,
                                fulfilledQTY: 0,
                                receivedQTY: 0,
                            }
                        }
                        if (item.toInoutRecord.quantity) {
                            vm.detail.items[item.toInoutRecord.finishedGoodSKU].receivedQTY += Math.abs(item.toInoutRecord.quantity);
                        }
                    }
                });
                vm.detail.canFulfill = true;
                vm.detail.canReceive = true;
                console.log(vm.detail.items)
                Object.keys(vm.detail.printingBatch.items).forEach((key) => {
                    var targetSKU = vm.detail.printingBatch.items[key].productionItem.rawMaterial.name;
                    if(vm.detail.items[targetSKU] !== undefined){
                        vm.detail.printingBatch.items[key].lastAreaCode = vm.detail.items[targetSKU].lastAreaCode;
                        vm.detail.printingBatch.items[key].wipAreaCode = vm.detail.items[targetSKU].wipAreaCode;
                        vm.detail.printingBatch.items[key].fromInventoryQTY = vm.detail.items[targetSKU].fromInventoryQTY;
                        vm.detail.printingBatch.items[key].valid = true
                    } else {
                        var targetSKU = vm.detail.printingBatch.items[key].productionItem.rawMaterial.name;
                        $.bootstrapGrowl('Printing Batch Item '+targetSKU+' does not match with it\'s corresponding stock transit. Please request again', {ele: 'body', type: 'error'});
                        vm.detail.canFulfill = false;
                        vm.detail.canReceive = false;
                        vm.detail.printingBatch.items[key].valid = false
                    }
                });
                vm.detail.items = Object.values(vm.detail.items);
                // $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showTransitRequestModal() {
            vm.detail = {};
            if (config.fromLocationList && config.fromLocationList.length) {
                vm.detail.fromLocation = config.fromLocationList[0].key;
            }
            if (config.toLocationList && config.toLocationList.length) {
                vm.detail.toLocation = config.toLocationList[0].key;
            }
            PageViewModel.openModal('#modal-new-request');
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-new-request');
            PageViewModel.hideModal('#modal-show-request');
        }

        function updateStockTransitRequest(callback) {
            // if (vm.detail.status == 'draft') {
            //     vm.detail.isDrafted = !confirm('Is request finalized?');
            // }
            vm.detail.updateStockTransitRequestLoading = true;
            let self = this;
            serverGateway.ajax('check-duplicate-request', {id: vm.detail.id},vm.detail).then(function(response){
                
                if(response.data.status == 'warning'){
                    let duplicates = "";
                    let res = response.data.duplicate;
                    Object.keys(res).forEach(function(key,val){
                        duplicates += key + ": " + res[key].join(',') + "\n";
                    });
                    if(!confirm("There are existing items in the following request(s):\n" + duplicates + "Do you wish to continue anyway? ")) return;
                }
                serverGateway.ajax('update-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                    if (callback) {
                        vm.detail.updateStockTransitRequestLoading = false;
                        callback();
                    } else {
                        vm.detail.updateStockTransitRequestLoading = false;
                        // PageViewModel.hideModal("#modal-show-request");
                        PageViewModel.update();
                        self.openDetailDialog(vm.detail);
                        $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    }
                }, function (response) {
                    vm.detail.updateStockTransitRequestLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });

            }, function(response){
                vm.detail.updateStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function rejectStockTransitRequest() {
            console.log('rejectStockTransitRequest START');
            if(!vm.detail.canReject){
                return false;
            }
            let reason = prompt("Reject reason: ", '');
            let self = this;
            vm.updateStockTransitRequest(function (){
                vm.detail.updateStockTransitRequestLoading = true;
                serverGateway.ajax('reject-transit-request', {id: vm.detail.id}, {reason: reason}).then(function(response){
                    vm.detail.updateStockTransitRequestLoading = false;
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }, function(response){
                    vm.detail.updateStockTransitRequestLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            });
            
        }

        function fulfillStockTransitRequest() {
            if (!vm.detail.canFulfill) {
                return false;
            }
            if (!confirm('Confirm?')) {
                return false;
            }
            let self = this;
            for (let i in vm.detail.items) {
                vm.detail.items[i].fulfilledQTY = vm.detail.items[i].requestQTY;
            }
            vm.updateStockTransitRequest(function () {
                vm.detail.updateStockTransitRequestLoading = true;
                serverGateway.ajax('fulfill-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                    vm.detail.updateStockTransitRequestLoading = false;
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }, function (response) {
                    vm.detail.updateStockTransitRequestLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            })
            
        }

        function confirmStockTransitRequest() {
            if (!confirm('Confirm?')) {
                return false;
            }
            let self = this;
            vm.updateStockTransitRequest(function () {
                vm.detail.confirmStockTransitRequestLoading = true;
                serverGateway.ajax('confirm-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                    vm.detail.confirmStockTransitRequestLoading = false;
                    PageViewModel.update();
                    self.openDetailDialog({id: vm.detail.id});
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }, function (response) {
                    vm.detail.confirmStockTransitRequestLoading = false;
                    if (response.data.message == 'Please approve the request by senior.') {
                        PageViewModel.openModal('#approvalRequestDialog');
                    } else {
                        $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    }
                });
            })
        }

        function receivedStockTransitRequest() {
            if (!vm.detail.canReceive) {
                return false;
            }
            if (!confirm('Confirm receiving all items?')) {
                return false;
            }
            let self = this;
            for (let i in vm.detail.items) {
                vm.detail.items[i].fulfilledQTY = vm.detail.items[i].requestQTY;
            }
            vm.detail.receivedStockTransitRequestLoading = true;
            serverGateway.ajax('received-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.receivedStockTransitRequestLoading = false;
                PageViewModel.update();
                self.openDetailDialog({id: vm.detail.id});
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.receivedStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function cancelStockTransitRequest() {
            if (vm.detail.status != 'draft' && vm.detail.status != 'rejected') {
                return false;
            }
            if (!confirm('Confirm delete this request?')) {
                return false;
            }
            vm.detail.cancelStockTransitRequestLoading = true;
            serverGateway.ajax('cancel-transit-request', {id: vm.detail.id}, vm.detail).then(function(response) {
                vm.detail.cancelStockTransitRequestLoading = false;
                PageViewModel.update();
                PageViewModel.hideModal("#modal-show-request");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.cancelStockTransitRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sendApprovalRequest() {
            if (!vm.approvalPerson) {
                $.bootstrapGrowl('Invalid user', {ele: 'body', type: 'error'});
                return false;
            }
            vm.detail.sendApprovalRequestLoading = false;
            serverGateway.ajax('send-approval-request', {id: vm.detail.id}, {email: vm.approvalPerson}).then(function(response) {
                vm.detail.sendApprovalRequestLoading = false;
                PageViewModel.hideModal("#approvalRequestDialog");
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.detail.sendApprovalRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        function printPickingLabel(id) {
            if (vm.detail.printLabelButtonLoading) {
                return false;
            }
            vm.detail.printLabelButtonLoading = true;
            serverGateway.ajax('print-picking-label', {id: id}).then(function(response) {
                vm.detail.printLabelButtonLoading = false;
                $.bootstrapGrowl('Already sent to Printer', {ele: 'body', type: 'success'});
            }, function () {
                vm.detail.printLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }


        function printLabel() {
            if (!confirm('The system takes around 5 seconds to generate 1 label and send it to the printer. \nConfirm to print label?')) {
                return false;
            }
            vm.isPrinting = true;
            $.bootstrapGrowl('The labels are generating and will be printed shortly.', {ele: 'body', type: 'success'});

            serverGateway.ajax('print-label', {id: vm.detail.id}, { 'printer' :vm.printer }).then(function(response) {
                vm.isPrinting = false;
            }, function (response) {
                vm.isPrinting = false;
            });
        }

        function bulkAction() {
            window.open("/inventory/printing-stock-transit/bulk-actions?ids=" + JSON.stringify({ids: vm.rows.map((row) => row.id)}), '_blank') 
        }

        function fulfillAll() {
            if (!confirm('Confirm fulfill all transit request in current page?')) {
                return false;
            }
            //Get filtered 
            serverGateway.ajax('fulfillAll',null,{id : vm.rows.map((row) => row.id)}).then(function(response){
                $.bootstrapGrowl('Fulfilled all.', {ele: 'body', type: 'success'});
                PageViewModel.update();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function receiveAll() {
            if (!confirm('Confirm receive all transit request in current page?')) {
                return false;
            }
            //Get filtered 
            serverGateway.ajax('receiveAll',null,{id : vm.rows.map((row) => row.id)}).then(function(response){
                $.bootstrapGrowl('Received all.', {ele: 'body', type: 'success'});
                PageViewModel.update();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function formatItemDescription(description){
            const redWords = ['MagSafe', 'Magsafe', 'magSafe', 'magsafe', 'Card Case', 'Blue Camera Ring', 'Magnetic', 'magnetic', '(Action Button)', 'Ring Stand Mirror'];
            redWords.forEach(redWord => {
                description = description.replace(redWord, `<b style='color:red;'>${redWord}</b>`);
            });
            const rainbows = ['Rainbow'];
            rainbows.forEach(rainbow => {
                description = description.replace(rainbow, `<b class="rainbow-text">${rainbow}</b>`);
            });
            return description;
        }
    }

    function stockInController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;
        // vm.materials         = config.materials;
        // vm.locations         = config.locations;
        // vm.selectedMaterial  = vm.materials.length ? vm.materials[0] : null;
        // vm.selectedLocation  = vm.locations.length ? vm.locations[0] : null;
        vm.rows                     = [];
        vm.sorts                    = [];
        vm.columns                  = config.columns;
        vm.locations                = config.locations;
        vm.setFilter                = PageViewModel.setFilter;
        vm.clearFilter              = PageViewModel.clearFilter;
        vm.sortChange               = PageViewModel.sortChange;
        vm.isLoading                = PageViewModel._isLoading;
        vm.submit                   = submit;
        vm.openDetailDialog         = openDetailDialog;
        vm.detail                   = {};
        vm.subdetail                = {};
        vm.stockin                  = {};
        vm.mode                     = 'create';
        vm.submode                  = 'update';
        vm.inoutShow                = false;
        vm.editInOutRecord          = editInOutRecord;
        vm.deleteInOutRecord        = deleteInOutRecord;
        vm.editInOutRecordSubmit    = editInOutRecordSubmit;
        vm.editInOutRecordCancel    = editInOutRecordCancel;
        vm.initialize               = initialize;

        function initialize(data) {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'raw-material-info'   : { path: 'raw-material-info', method: 'GET' },
                    'stockin'             : { path: 'stockin', method: 'POST' },
                    'inout-record-update' : { path: 'inout-record-update', method: 'POST' },
                    'inout-record-delete' : { path: 'inout-record-delete', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();
            // setInterval(PageViewModel.update, 5000);

            serverGateway.ajax('raw-material-info').then(function (response) {
                vm.categories      = response.data.categories;
                vm.tags            = response.data.tags;
            });

            // for direct access of specific raw material
            var data = config.initConfig;
            if (data && data.rawMaterialId) {
                PageViewModel.setFilterIdAndFirst('raw_materials.id', data.rawMaterialId, function (row) {
                    openDetailDialog(row, 'update');
                    vm.inoutShow = true;

                    var record = _.find(vm.detail.inoutRecords, function (row) {
                        return row.id == data.inoutRecordId;
                    });
                    if (typeof record !== 'undefined') {
                        editInOutRecord(record);
                    }
                });
            }
        }


        function submit() {
            vm.detail.processing = true;
            vm.detail.error = '';

            serverGateway.ajax('stockin', '', vm.stockin).then(function(response) {
                vm.detail.processing = false;
                if (response.data.status == 'success') {
                    // find raw material id
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.stockin.id;
                    });
                    if (idx !== -1) {
                        vm.rows[idx].inoutRecords = vm.detail.inoutRecords = response.data.inoutRecords;
                        vm.rows[idx].quantity = _.sumBy(vm.detail.inoutRecords[idx], function (o) { return o.quantity; });
                    }
                    PageViewModel.hideModal();
                }
                else {
                    vm.detail.error = "The action cannot be completed. Please check the inventory level.";
                }
            });

        }

        function openDetailDialog(row, mode) {
            vm.mode = (mode, 'create');
            vm.detail  = row;
            vm.stockin = {
                id           : row.id,
                location     : vm.locations[0],
                box_quantity : row.default_box_quantity,
                box          : 1,
                po_num       : 'CAS-PO-',
                expiry_date  : '',
                action       : 'stock_in',
            };
            PageViewModel.openModal();
        }

        function editInOutRecord(record) {
            editInOutRecordCancel();
            vm.mode = 'update';
            vm.submode = 'update';
            record.active = true;
            vm.subdetail = $.extend(true, { boxQuantity: record.quantity / record.box }, record);
        }

        function editInOutRecordSubmit() {
            // inout-record-delete
            vm.subdetail.processing = true;
            serverGateway.ajax(vm.submode == 'update' ? 'inout-record-update' : 'inout-record-delete', '', vm.subdetail).then(function(response) {
                if (response.data.status == 'success') {
                    // find in out record id
                    var idx = _.findIndex(vm.detail.inoutRecords, function(record) {
                        return record.id == vm.subdetail.id;
                    });
                    if (vm.submode == 'delete') vm.detail.inoutRecords.splice(idx, 1);
                    else if (idx != -1) vm.detail.inoutRecords[idx] = response.data.inoutRecord;

                    // find raw material id
                    idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });
                    if (idx !== -1) {
                        vm.detail.quantity = vm.rows[idx].quantity = _.sumBy(vm.detail.inoutRecords, function (o) { return o.quantity; });
                    }
                }

                vm.subdetail.processing = false;
                vm.submode = '';
                editInOutRecordCancel();
                vm.mode = 'update';
            });
        }

        function editInOutRecordCancel() {
            vm.submode = '';
            _.forEach(vm.detail.inoutRecords, function(record) {
                record.active = false;
            });
        }

        function deleteInOutRecord(record) {
            editInOutRecordCancel();
            vm.mode = 'update';
            vm.submode = 'delete';
            record.active = true;
            vm.subdetail = $.extend(true, {}, record);
        }

    }

    function stockTakeController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;
        vm.rows                     = [];
        vm.sorts                    = [];
        vm.columns                  = config.columns;
        vm.locations                = config.locations;
        vm.setFilter                = PageViewModel.setFilter;
        vm.clearFilter              = PageViewModel.clearFilter;
        vm.sortChange               = PageViewModel.sortChange;
        vm.isLoading                = PageViewModel._isLoading;
        vm.submit                   = submit;
        vm.openDetailDialog         = openDetailDialog;
        vm.detail                   = {};
        vm.initialize               = initialize;

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'stocktake' : { path: 'stocktake', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();
        }

        function openDetailDialog(row) {
            vm.detail = row;
            vm.detail.location = vm.locations[0];

            PageViewModel.openModal();
        }

        function submit(e) {
            e.preventDefault();
            vm.detail.processing = true;
            vm.detail.error = '';
            serverGateway.ajax('stocktake', '', vm.detail).then(function(response) {
                vm.detail.processing = false;
                if (response.data.status == 'success') {
                    // // find raw material id
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });
                    if (idx !== -1) {
                        vm.rows[idx].quantity = response.data.rawMaterial.quantity;
                    }
                    PageViewModel.hideModal();
                }
            });
        }

    }

    function forecastController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'forecast-data': { path: 'forecast-data', method: 'GET' },
                    'override': { path: 'forecast-override', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();
            // setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
        }

        function openDetailDialog(row, columns) {
            setDetail(row);
            if (!isForecastDate(row)) return;
            PageViewModel.openModal();
        }

        function done() {
            if (isNormalInteger(vm.detail.override_sales_count) || vm.detail.empty) {
                serverGateway.ajax('override', {}, {date: vm.detail.date, override_sale: vm.detail.empty ? '' : vm.detail.override_sales_count});
                $('#modal').modal('hide');
                for (var idx in vm.rows) {
                    if (vm.rows[idx].date == vm.detail.date)
                        if (vm.detail.empty) vm.rows[idx].override_sales_count = '';
                        else vm.rows[idx].override_sales_count = vm.detail.override_sales_count;
                }
            }
            else {
                vm.detail.error = true;
            }
        }

        function isForecastDate(row) {
            if (row.sales_count == null) return true;
            return false;
        }

    }

    function rawMaterialForecastController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        vm.isLoading        = PageViewModel._isLoading;
        vm.setFilter         = PageViewModel.setFilter;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'forecast-data': { path: 'forecast-data', method: 'GET' },
                    'override': { path: 'forecast-override', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            PageViewModel.update();
            // setInterval(PageViewModel.update, 5000);
        }

        function setDetail(row) {
            vm.detail = $.extend(true, {}, row);
        }

        function openDetailDialog(row, columns) {
            setDetail(row);
            // PageViewModel.openModal();
        }

    }

    function marketingInputController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        vm.detail               = {};
        vm.mode                 = '';
        vm.rows                 = [];
        vm.openDetailDialog     = openDetailDialog;
        vm.submit               = submit;
        vm.columns              = config.columns;
        vm.searchProduct        = searchProduct;
        vm.removeProduct        = removeProduct;
        vm.addProduct           = addProduct;
        vm.createMarketingInput = createMarketingInput;
        vm.removeMarketingInput = removeMarketingInput;
        vm.isLoading            = PageViewModel._isLoading;

        initialize();
        initializeCustomAutocomplete();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'marketing-input-product-search':  { path: 'marketing-input-product-search', method: 'GET' },
                    'marketing-input-create':  { path: 'marketing-input-create', method: 'POST' },
                    'marketing-input-update':  { path: 'marketing-input-update', method: 'POST' },
                    'marketing-input-delete':  { path: 'marketing-input-delete', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();

        }

        function initializeCustomAutocomplete() {
            $('.result-search').focus(function() {
                $timeout(function() {
                    vm.detail.showResult = true;
                });
            });
            $(window).click(function(evt) {
                if (!$.contains($('.result-container')[0], evt.target)) $timeout(function() {
                    vm.detail.showResult = false;
                });
            });
        }

        function removeProduct(inputProduct) {
            var idx = _.findIndex(vm.detail.products, function(product) {
                return inputProduct.id == product.id;
            });

            if (idx !== -1) vm.detail.products.splice(idx, 1);
        }

        function addProduct(inputProduct) {
            var idx = _.findIndex(vm.detail.products, function(product) {
                return inputProduct.id == product.id;
            });

            if (idx == -1) {
                vm.detail.products.push(inputProduct);
                idx = _.findIndex(vm.detail.productResult, function(product) {
                    return inputProduct.id == product.id;
                });
                if (idx !== -1) vm.detail.productResult.splice(idx, 1);
            }
        }

        function openDetailDialog(row, mode) {
            vm.mode = mode || 'update';
            vm.detail = $.extend(true, {}, row);
            if (row.sale_percentage_change) vm.detail.percentage = 1;
            else vm.detail.percentage = 0;
            PageViewModel.openModal();
        }

        function submit(e) {
            e.preventDefault();

            vm.detail.processing = true;
            serverGateway.ajax(vm.mode == 'create' ? 'marketing-input-create' : 'marketing-input-update', '', {
                id           : vm.detail.id,
                name         : vm.detail.name,
                remark       : vm.detail.remark,
                date_start   : vm.detail.date_start,
                date_end     : vm.detail.date_end,
                item_options : _.map(vm.detail.products, function(product) {
                    return product.name;
                }).join(','),
                enabled                : vm.detail.enabled,
                sale_number_change     : vm.detail.percentage == 0 ? vm.detail.sale_number_change : '',
                sale_percentage_change : vm.detail.percentage == 1 ? vm.detail.sale_percentage_change : '',

            }).then(function (response) {
                try {
                    if (response.data.status == 'success') {
                        if (vm.mode == 'create') {
                            window.location.reload();
                        }
                        else if (vm.mode == 'update') {
                            var row = _.find(vm.rows, function (row) {
                                return vm.detail.id == row.id;
                            });
                            for (var key in response.data.marketingEvent) {
                                if (typeof row[key] !== 'undefined')
                                    row[key] = response.data.marketingEvent[key];
                            }
                        }
                    }
                    $('#modal').modal('hide');
                } catch(e) { /* form submission error */ vm.formError = true; }
                delete vm.detail.processing;
            });
        }

        function searchProduct() {
            if (typeof vm.detail.keyword !== 'undefined' && vm.detail.keyword.length) {
                serverGateway.ajax('marketing-input-product-search', '', {
                    query      : vm.detail.keyword,
                    excludeIds : _.map(vm.detail.products, function(product) {
                        return product.id;
                    })
                }).then(function(response) {
                    vm.detail.showResult = true;
                    vm.detail.productResult = response.data.products;
                });
            }
        }

        function createMarketingInput() {
            openDetailDialog({ products: [] }, 'create');
        }

        function removeMarketingInput() {
            vm.detail.processing = true;
            serverGateway.ajax('marketing-input-delete', '', {
                id : vm.detail.id,
            }).then(function(response) {
                window.location.reload();
            });
        }

    }

    function rawMaterialController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {
        var serverGateway, vm = this;

        vm.submit            = submit;
        vm.rows              = [];
        vm.openDetailDialog  = openDetailDialog;
        vm.columns           = config.columns;
        vm.isFormCompleted   = isFormCompleted;
        vm.createRawMaterial = createRawMaterial;
        vm.printingGroups        = config.printingGroups;
        vm.printingBatchGroups        = config.printingBatchGroups;
        vm.categories        = {};
        vm.detail            = {};
        vm.setFilter         = PageViewModel.setFilter;
        vm.clearFilter       = PageViewModel.clearFilter;
        vm.sortChange        = PageViewModel.sortChange;
        vm.isLoading         = PageViewModel._isLoading;
        vm.sorts             = [];
        vm.initialize        = initialize;
        vm.addPair           = addPair;
        vm.removePair        = removePair;
        vm.delete            = rawMaterialDelete;
        vm.deleteConfirm     = rawMaterialDeleteConfirm;
        vm.deleteCancel      = rawMaterialDeleteCancel;
        vm.duplicate         = rawMaterialDuplicate;
        vm.recoverConflict   = recoverConflict;
        vm.popupSelectSKU    = popupSelectSKU;
        vm.updateSaleAvg = updateSaleAvg;
        vm.validateForm = validateForm;
        vm.restockActions = [
            {key: 'undetermined', value: 'Undetermined'},
            {key: 'restock', value: 'Restock'},
            {key: 'no_restock', value: 'No Restock'},
            {key: 'discontinue', value: 'Discontinue'},
            {key: 'upgrade', value: 'Upgrade'},
        ]
        // vm.deleteConflict    = deleteConflict;


        function initialize(data) {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'raw-material-info':  { path: 'raw-material-info', method: 'GET' },
                    'raw-material-create':  { path: 'raw-material-create', method: 'POST' },
                    'raw-material-update':  { path: 'raw-material-update', method: 'POST' },
                    'raw-material-delete':  { path: 'raw-material-delete', method: 'POST' },
                    'raw-material-conflict-recover': { path: 'raw-material/{id}/recover', method: 'POST' },
                    'raw-material-show':  { path: 'raw-materials/{id}/show', method: 'GET' },
                    // 'raw-material-conflict-delete': { path: 'raw-material/{id}/hardDelete', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 20,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $scope.$watch(function () {
                return vm.detail.is_fadeout;
            }, vm.validateForm);
            $scope.$watch(function () {
                return vm.detail.leadTime && vm.detail.leadTime.length;
            }, vm.validateForm);
            $scope.$watch(function () {
                return vm.detail.restock_action;
            }, vm.validateForm);
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();
            // setInterval(PageViewModel.update, 5000);

            serverGateway.ajax('raw-material-info').then(function (response) {
                vm.categories      = response.data.categories;
                // vm.reorderPolicies = response.data.reorderPolicies;
                vm.tags            = response.data.tags;
            });

            // for direct access of specific raw material
            var data = config.initConfig;
            if (data && data.rawMaterialSKU) {
                PageViewModel.update(function () {
                    PageViewModel.setFilterIdAndFirst('raw_materials.name', data.rawMaterialSKU, function (row) {
                        openDetailDialog(row, 'update');
                    });
                });
            }
        }

        function updateSaleAvg (data) {
            var sum = 0;
            var isInRange = false;
            for (var index in vm.detail.salesHistory) {
                if (index == data.from_value) {
                    isInRange = true;
                }

                if (isInRange) {
                    sum += vm.detail.salesHistory[index];
                }
                if (index == data.to_value) {
                    isInRange = false;
                }
            }
            $(".sales-avg").text("" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)");
            // vm.salesAvg = "" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)";
        }

        function validateForm() {
            // validate form
            var skuWarning = [];
            if (vm.detail.name && vm.detail.name.length > 30) {
                skuWarning.push('SKU length should be <= 30 character');
            }
            
            if (vm.detail.name && vm.detail.name.includes(' ')) {
                skuWarning.push('SKU should not contains space');
            }
            if (vm.detail.name && vm.detail.name.includes('_')) {
                skuWarning.push('SKU should use - instead of _');
            }
            var regex = /^[a-z0-9\.-]+$/i;
            if (!regex.test(vm.detail.name)) {
                skuWarning.push('SKU contains invalid character');
            }
            
            if (vm.detail.name && vm.detail.name.toUpperCase() != vm.detail.name) {
                skuWarning.push('SKU should be all uppercase');
            }

            $('#sku-label a.custom-warning').remove();
            if (skuWarning && skuWarning.length) {
                $('#sku-label').addClass('bg-warning');
                $('#sku-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+skuWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#sku-label').removeClass('bg-warning');
            }


            var commonNameWarning = [];
            if (vm.detail.short_description && vm.detail.short_description.length > 50) {
                commonNameWarning.push('Common Name length should be <= 50 character');
            }

            $('#common-name-label a.custom-warning').remove();
            if (commonNameWarning && commonNameWarning.length) {
                $('#common-name-label').addClass('bg-warning');
                $('#common-name-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+commonNameWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#common-name-label').removeClass('bg-warning');
            }

            var descriptionWarning = [];
            if (vm.detail.description && vm.detail.description.length > 50) {
                descriptionWarning.push('Common Name length should be <= 50 character');
            }

            $('#description-label a.custom-warning').remove();
            if (descriptionWarning && descriptionWarning.length) {
                $('#description-label').addClass('bg-warning');
                $('#description-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+descriptionWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#description-label').removeClass('bg-warning');
            }

            var alertWarning = [];
            if (vm.detail.has_warning_qty_based && vm.detail.warning_qty_based == 0) {
                alertWarning.push('QTY based warning require qty input');
            }
            if (vm.detail.has_warning_time_based && vm.detail.warning_time_based == 0) {
                alertWarning.push('Time based warning require day input');
            }
            $('#alert-type-label a.custom-warning').remove();
            if (alertWarning && alertWarning.length) {
                $('#alert-type-label').addClass('bg-warning');
                $('#alert-type-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+alertWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#alert-type-label').removeClass('bg-warning');
            }

            var fadeoutWarning = [];
            if (vm.detail.is_fadeout == 1 && vm.detail.stock_quantity) {
                fadeoutWarning.push('Should not set as fadeout as there are stock in inventory')
            }

            $('#fadeout-label a.custom-warning').remove();
            if (fadeoutWarning && fadeoutWarning.length) {
                $('#fadeout-label').addClass('bg-warning');
                $('#fadeout-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+fadeoutWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#fadeout-label').removeClass('bg-warning');
            }

            var restockActionWarning = [];
            if (vm.detail.restock_action == 'upgrade' && (!vm.detail.restock_action_data || vm.detail.restock_action_data == '')) {
                restockActionWarning.push('Please provide upgrade SKU');
            }

            $('#restock-action-label a.custom-warning').remove();
            if (restockActionWarning && restockActionWarning.length) {
                $('#restock-action-label').addClass('bg-warning');
                $('#restock-action-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+restockActionWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#restock-action-label').removeClass('bg-warning');
            }

            var leadTimeWarning = [];
            if (vm.detail.restock_action == 'restock' && (!vm.detail.leadTime || vm.detail.leadTime.filter((leadTime) => leadTime.lead_time && leadTime.order_quantity).length <= 0)) {
                leadTimeWarning.push('Missing Lead Time');
            }

            $('#lead-time-label a.custom-warning').remove();
            if (leadTimeWarning && leadTimeWarning.length) {
                $('#lead-time-label').addClass('bg-warning');
                $('#lead-time-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+leadTimeWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#lead-time-label').removeClass('bg-warning');
            }
        }


        function openDetailDialog(row, mode) {
            vm.showRelatedProduct = false;
            vm.mode = (mode || 'update');
            vm.detail = $.extend(true, {}, row);
            if (row.reorder_lv > 0) vm.detail.reorder_lv_override = true;
            if (vm.detail.leadTime.length == 0) vm.detail.leadTime.push(_getDefaultPair());
            vm.detail.restock_date = vm.detail.restock_date ? new Date(vm.detail.restock_date) : null;
            PageViewModel.openModal();

            if (mode == 'update') {
                vm.detail.loadingDetail = true;
                serverGateway.ajax('raw-material-show', {id: row.id}).then(function (response) {
                    if (response.data.data.id != row.id) return;
                    vm.detail = $.extend(true, {}, response.data.data);
                    var idx = _.findIndex(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    vm.rows[idx] = vm.detail;
                    vm.detail.loadingDetail = false;
                    if (vm.detail.finishedGoods) vm.detail.sku = vm.detail.finishedGoods.sku;
                    if (row.reorder_lv > 0) vm.detail.reorder_lv_override = true;
                    if (vm.detail.leadTime.length == 0) vm.detail.leadTime.push(_getDefaultPair());
                    vm.detail.restock_date = vm.detail.restock_date ? new Date(vm.detail.restock_date) : null;

                    $timeout(function() {
                        if ($("#sale-slider").data("ionRangeSlider")) {
                            $("#sale-slider").data("ionRangeSlider").destroy();
                        }
                        if (vm.detail.salesHistoryDates && vm.detail.salesHistoryDates.length) {
                            $("#sale-slider").ionRangeSlider({
                                type: 'double',
                                values: vm.detail.salesHistoryDates,
                                onChange: vm.updateSaleAvg,
                                onFinish: vm.updateSaleAvg,
                                onUpdate: vm.updateSaleAvg,
                                onStart: vm.updateSaleAvg
                            });
                        }

                        $('input').keyup(vm.validateForm)
                        $('input').change(vm.validateForm)
                        vm.validateForm()
                    }, 1000);
                }, function () {
                    vm.detail.loadingDetail = false;
                    $.bootstrapGrowl('Error', {ele: 'body', type: 'error'});
                });
            } else {
                $timeout(function() {
                    if ($("#sale-slider").data("ionRangeSlider")) {
                        $("#sale-slider").data("ionRangeSlider").destroy();
                    }
                    if (row.salesHistoryDates && row.salesHistoryDates.length) {
                        $("#sale-slider").ionRangeSlider({
                            type: 'double',
                            values: row.salesHistoryDates,
                            onChange: vm.updateSaleAvg,
                            onFinish: vm.updateSaleAvg,
                            onUpdate: vm.updateSaleAvg,
                            onStart: vm.updateSaleAvg
                        });
                    }

                    $('input').keyup(vm.validateForm)
                    $('input').change(vm.validateForm)
                    vm.validateForm()
                }, 1000);
            }
        }

        function createRawMaterial() {
            vm.mode = 'create';
            vm.detail = {};
            vm.detail.leadTime = [_getDefaultPair()];
            vm.detail.warehousePermissions = [];

            $(config.locations).each(function (index, element) {
                vm.detail.warehousePermissions.push({
                    location: element, 
                    hasPermission: true
                })
            })
            PageViewModel.openModal();
        }

        function isFormCompleted() {
            try {
                var isSuccess = true;
                // if (vm.detail.po_num.length == 0) isSuccess = false;

                // for (var idx in vm.addedItems) {
                //     delete vm.detail.error[vm.addedItems[idx].id];
                //     if (!isNormalInteger(vm.detail.quantity[vm.addedItems[idx].id])) {
                //         if (vm.detail.quantity[vm.addedItems[idx].id].length) vm.detail.error[vm.addedItems[idx].id] = true;
                //         isSuccess = false;
                //     }
                // }

                return isSuccess;
            } catch (e) {
                return false;
            }
        }

        function submit(e) {
            e.preventDefault();

            vm.detail.error = '';
            vm.detail.processing = true;

            // set the moq and leadtime before submit
            var minPair = {moq: '', lead_time:''};
            for (var idx in vm.detail.leadTime) {
                if (minPair.moq == '') {
                    minPair.moq = vm.detail.leadTime[idx].order_quantity;
                    minPair.lead_time = vm.detail.leadTime[idx].lead_time;
                }
                else {
                    if (vm.detail.leadTime[idx].order_quantity < minPair.moq) {
                        minPair.moq = vm.detail.leadTime[idx].order_quantity;
                        minPair.lead_time = vm.detail.leadTime[idx].lead_time;
                    }
                }
            }
            vm.detail.moq = (minPair.moq == '' ? vm.detail.moq : minPair.moq);
            vm.detail.lead_time = (minPair.lead_time == '' ? vm.detail.lead_time : minPair.lead_time);
            var params = JSON.parse(JSON.stringify(vm.detail))
            if (vm.detail.restock_date) {
                params.restock_date = vm.detail.restock_date.dateFormat('Y-m-d 00:00:00')
            }
            serverGateway.ajax(vm.mode == 'create' ? 'raw-material-create' : 'raw-material-update', '', params).then(function (response) {
                if (response.data.status == 'success') {
                    if (vm.mode == 'create') {
                        window.location.reload();
                    }
                    else {
                        var row = _.find(vm.rows, function (row) {
                            return vm.detail.id == row.id;
                        });
                        if (row) {
                            for (var key in response.data.rawMaterial) {
                                if (typeof row[key] !== 'undefined')
                                    row[key] = response.data.rawMaterial[key];
                            }
                        }
                    }
                    PageViewModel.hideModal('#modal');
                }
                else if (response.data.status == 'fail' && response.data.error == 'soft_delete_conflict') {
                    PageViewModel.openModal('#modal-conflict');
                    vm.detail.conflictId = response.data.conflictId;
                }
                else {
                    vm.detail.error = 'The Raw Material coannot be created, please check if there are existed Raw Material.';
                }
                delete vm.detail.processing;
            }, function (response) {
                vm.detail.processing = false;
                $.bootstrapGrowl(response.data.message ? response.data.message : 'Error', {ele: 'body', type: 'error'});
            });
        }

        function recoverConflict() {
            serverGateway.ajax('raw-material-conflict-recover', {id: vm.detail.conflictId}).then(function (response) {
                if (response.data.status == 'success') {
                    PageViewModel.hideModal('#modal-conflict');
                    window.location.reload();
                }
                else {
                    $.bootstrapGrowl('Cannot recover conflict', {ele: 'body', type: 'error'});
                }
            });
        }

        // function deleteConflict() {
        //     serverGateway.ajax('raw-material-conflict-delete', {id: vm.detail.conflictId}).then(function (response) {
        //         if (response.data.status == 'success') {
        //             PageViewModel.hideModal('#modal-conflict');
        //         }
        //         else {
        //             $.bootstrapGrowl('Cannot force delete conflict', {ele: 'body', type: 'error'});
        //         }
        //     });
        // }

        function _getDefaultPair() {

            return {lead_time: 0, order_quantity: 0, moq: false};

        }

        function addPair() {

            vm.detail.leadTime.push(_getDefaultPair());

        }

        function removePair(inputLeadTime) {

            var idx = _.findIndex(vm.detail.leadTime, function (leadTime) {
                return leadTime == inputLeadTime;
            });

            vm.detail.leadTime.splice(idx, 1);

        }

        function rawMaterialDelete() {
            PageViewModel.switchModal('#modal', '#modal-delete');
        }

        function rawMaterialDeleteCancel() {
            PageViewModel.switchModal('#modal-delete', '#modal');
        }

        function rawMaterialDeleteConfirm() {
            serverGateway.ajax('raw-material-delete', '', vm.detail).then(function (response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    if (idx != -1) vm.rows.splice(idx, 1);
                    PageViewModel.hideModal('#modal-delete');
                }
                delete vm.detail.processing;
            });
        }

        function rawMaterialDuplicate() {
            vm.mode = 'create';
            // vm.detail.predecessor_short_name = vm.detail.name;
            vm.detail.name += '-COPY';
            vm.detail.description += ' - Copy';
            vm.detail.short_description += ' - Copy';
        }

        function popupSelectSKU(row) {

            Popup.setReturnAndClose(row);

        }

    }

    function poItemController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, XeroService) {
        var serverGateway, vm = this, po = {};

        vm.submit              = submit;
        vm.rows                = [];
        vm.columns             = config.columns;
        vm.addToList           = addToList;
        vm.removeFromList      = removeFromList;
        vm.openDetailDialog    = openDetailDialog;
        vm.isFormCompleted     = isFormCompleted;
        vm.openConfirmDialog   = openConfirmDialog;
        vm.close               = close;
        vm.addedItems          = [];
        vm.detail              = {};
        vm.setFilter           = PageViewModel.setFilter;
        vm.clearFilter         = PageViewModel.clearFilter;
        vm.sortChange          = PageViewModel.sortChange;
        vm.isLoading           = PageViewModel._isLoading;
        vm.sorts               = [];
        vm.currencies          = [];
        vm.locations           = [];
        vm.moneyFormat         = moneyFormat;
        vm.totalAmount         = totalAmount;
        vm.cancelConfirmation  = cancelConfirmation;
        vm.initialize          = initialize;
        vm.rawMaterialPOMapper = rawMaterialPOMapper;
        vm.xeroAuth            = xeroAuth;
        vm.isAuthenticated     = XeroService.isAuthenticated;
        vm.isAuthenticating    = XeroService.isAuthenticating;
        vm.isLoadingContacts   = false;
        vm.contacts            = [];
        vm.addContact          = addContact;
        vm.searchContact       = searchContact;

        // historicalPrices
        vm.historicalPrices    = {};
        vm.getHistoricalPrices = getHistoricalPrices;
        vm.hasHistoricalPrices = hasHistoricalPrices;

        function initialize(data) {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'currencies'     : { path: 'currencies', method: 'GET' },
                    'po-item-create' : { path: 'po-item-create', method: 'POST' },
                    'po-item-update' : { path: 'po-item-update', method: 'POST' },
                    'po-info'        : { path: 'po-info', method: 'GET' },
                    'po-item-info'   : { path: 'po-item-info', method: 'GET' },
                    'locations'      : { path: 'locations', method: 'GET' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {

                vm.rows = n;
                updateAddItemList();

            });
            $timeout(function() { PageViewModel.update(function() { updateAddItemList(); }); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();

            serverGateway.ajax('currencies').then(function(response) {
                vm.currencies = response.data.currencies;
            });

            serverGateway.ajax('locations').then(function(response) {
                vm.locations = response.data.locations;
            });

            vm.mode = 'create';
            var data = config.initConfig;
            if (data.purchaseOrderId) {
                vm.mode = 'update';
                serverGateway.ajax('po-info', '', {id: data.purchaseOrderId}).then(function(response) {
                    po = vm.detail = response.data.po;

                    for (var idx in vm.detail.items)
                        addToList(vm.detail.items[idx].rawMaterial);
                });
            }
            else if (data.purchaseOrderItemId) {
                PageViewModel.setFilterIdAndFirst('raw_materials.name', data.purchaseOrderItemSKU, function (row) {
                    addToList(row);
                });
            }
        }

        initializeCustomAutocomplete();
        function initializeCustomAutocomplete() {
            $('.result-search').focus(function() {
                $timeout(function() {
                    vm.detail.showResult = true;
                });
            });
            $(window).click(function(evt) {
                if (!$.contains($('.result-container')[0], evt.target)) $timeout(function() {
                    vm.detail.showResult = false;
                });
            });
        }

        function updateAddItemList() {
            for (var idx in vm.rows) {
                delete vm.rows[idx].added;
            }
            for (var idx in vm.addedItems) {
                var item = _.find(vm.rows, function(item) {
                    return item.id == vm.addedItems[idx].id;
                });
                if (typeof item !== 'undefined') item.added = true;
            }
        }

        function addToList(row) {
            var item = _.find(vm.addedItems, function(item) {
                return item.name == row.name;
            });
            if (typeof item === 'undefined') {
                vm.addedItems.push(row);
                row.added = true;
            }
        }

        function removeFromList(row) {
            var idx = _.findIndex(vm.addedItems, function(item) {
                return item.name == row.name;
            });
            if (typeof idx !== -1) {
                vm.addedItems.splice(idx, 1);
                updateAddItemList();
                PageViewModel.refreshWaypoint();
            }
        }

        function openDetailDialog() {

            if (vm.isAuthenticated()) {

                getContacts();

                if (vm.mode == 'create') {
                    vm.detail = { po_num: '', preorder_date: moment().format('YYYY-MM-DD'), currency: vm.currencies[0], delivery_address: vm.locations[0], error: {}, quantity: {}, unit_price: {} };
                }
                else if (vm.mode == 'update') {
                    vm.detail.quantity   = {};
                    vm.detail.unit_price = {};
                    vm.detail.error      = {};
                    for (var idx in vm.detail.items) {
                        var item = rawMaterialPOMapper(vm.detail.items[idx].rawMaterial);
                        vm.detail.quantity[vm.detail.items[idx].rawMaterial.id]   = item.quantity;
                        vm.detail.unit_price[vm.detail.items[idx].rawMaterial.id] = item.unit_price;
                    }
                }
                PageViewModel.openModal();

            }
            else {

                PageViewModel.openModal('#modal-auth');

            }
        }

        function xeroAuth() {

            XeroService.auth().then(function() {
                getContacts();
                $timeout(function() {
                    openDetailDialog();
                }, 500);
            })
            .finally(function() {
                PageViewModel.hideModal('#modal-auth');
            });

        }

        function getContacts() {

            if (vm.contacts && !vm.contacts.length) {

                vm.isLoadingContacts = true;
                XeroService.getContacts().then(function(e) {
                    vm.isLoadingContacts = false;
                    vm.contacts = e.data.Contacts;
                    vm.detail.contactResult = vm.contacts;

                    if (vm.mode == 'update') {
                        vm.detail.contact = _.find(vm.contacts, function (contact) {
                            return vm.detail.contact_id == contact.externalId;
                        });
                        if (typeof vm.detail.contact !== 'undefined') {
                            vm.detail.keyword = vm.detail.contact.name;
                            vm.detail.showResult = false;
                        }
                    }
                });

            }
        }

        function addContact(result) {
            vm.detail.contact = result;
            vm.detail.keyword = result.name;
            vm.detail.showResult = false;
        }

        function searchContact() {
            if (typeof vm.detail.keyword !== 'undefined' && vm.detail.keyword.length) {
                vm.detail.showResult = true;
                vm.detail.contactResult = _.filter(vm.contacts, function(item) {
                    var regexp = new RegExp(vm.detail.keyword.split(' ').join('|'), 'i');
                    return item.name.match(regexp);
                });
            }
        }

        function isFormCompleted() {
            try {
                var isSuccess = true;

                for (var idx in vm.addedItems) {
                    delete vm.detail.error[vm.addedItems[idx].id];

                    // no contact
                    if (typeof vm.detail.contact === 'undefined') isSuccess = false;

                    if (vm.detail.preorder_date == '') isSuccess = false;

                    if (typeof vm.detail.delivery_date === 'undefined' || vm.detail.delivery_date == '') isSuccess = false;

                    // check quantity
                    if (!isNormalInteger(vm.detail.quantity[vm.addedItems[idx].id])) {
                        if (vm.detail.quantity[vm.addedItems[idx].id].length) vm.detail.error[vm.addedItems[idx].id] = true;
                        isSuccess = false;
                    }


                }

                return isSuccess;
            } catch (e) {
                return false;
            }
        }

        function close() {

        }

        function openConfirmDialog() {
            PageViewModel.switchModal('#modal', '#modal-confirm');
        }

        function submit() {

            if (vm.mode == 'update' && !vm.isAuthenticated()) {
                PageViewModel.switchModal('#modal-confirm', '#modal-auth');
                return;
            }

            vm.detail.processing = true;
            delete vm.detail.contactResult;
            for (var idx in vm.detail.quantity) {
                if (typeof _.find(vm.addedItems, function (item) { return item.id == idx; }) === 'undefined') {
                    delete vm.detail.quantity[idx];
                    delete vm.detail.unit_price[idx];
                }
            }

            serverGateway.ajax(vm.mode == 'create' ? 'po-item-create' : 'po-item-update', '', vm.detail).then(function(response) {
                if (response.data.status == 'success') {
                    if (vm.mode == 'update') {
                        XeroService.savePurchaseOrder({id: vm.detail.id}).then(function(e) {
                            location.href = "/product/po/" + vm.detail.id;
                        }, function(e) {
                            vm.detail.errors = e.data.errors;
                        })
                        .finally(function() {
                            vm.detail.processing = false;
                        });
                    }
                    else location.href = "/product/po/" + (response.data.purchaseOrderId);
                }
            });
        }

        function totalAmount() {
            var amount = 0;
            for (var idx in vm.detail.quantity) {
                amount += vm.detail.quantity[idx] * vm.detail.unit_price[idx];
            }
            return amount;
        }

        function cancelConfirmation() {
            PageViewModel.switchModal('#modal-confirm', '#modal');
        }

        function rawMaterialPOMapper(rawMaterial) {
            var obj = _.find(po.items, function (item) {
                return item.rawMaterial.id == rawMaterial.id;
            });

            if (typeof obj != 'undefined') {
                return obj;
            }
            return {};
        }

        function getHistoricalPrices(rawMaterialId, poId) {
            if (!hasHistoricalPrices(rawMaterialId)) {
                vm.historicalPrices[rawMaterialId] = {
                    loading : true,
                    hasData : true
                };

                serverGateway.ajax('po-item-info', '', {id: rawMaterialId, excludePOId: vm.detail.id}).then(function(response) {
                    if (response.data.status == 'success')
                        vm.historicalPrices[rawMaterialId] = {
                            open    : true,
                            loading : false,
                            hasData : response.data.items.length > 0,
                            items   : response.data.items
                        };
                });
            }
            else {
                vm.historicalPrices[rawMaterialId].open = !vm.historicalPrices[rawMaterialId].open;
            }
        }

        function hasHistoricalPrices(rawMaterialId) {
            if (typeof vm.historicalPrices[rawMaterialId] === 'undefined') return false;
            return true;
        }

    }

    function poController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, XeroService, Popup) {
        var serverGateway, vm = this;

        vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.sorts            = [];
        vm.moneyFormat      = moneyFormat;
        vm.totalAmount      = totalAmount;
        vm.isAuthenticated  = XeroService.isAuthenticated;
        vm.isAuthenticating = XeroService.isAuthenticating;
        vm.xeroAuth         = xeroAuth;
        vm.download         = download;
        vm.downloadUrl      = downloadUrl;
        vm.delete           = poDelete;
        vm.deleteConfirm    = poDeleteConfirm;
        vm.deleteCancel     = poDeleteCancel;
        vm.initialize       = initialize;
        vm.popupSelectPO    = popupSelectPO;
        vm.refetchPO        = refetchPO;

        function initialize(data) {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'po-data-update' : { path: 'po-data-update', method: 'POST' },
                    'po-data-delete' : { path: 'po-data-delete', method: 'POST' },
                    'po-item-info'   : { path: 'po-item-info', method: 'GET' },
                    'po-refetch'   : { path: 'po-refetch', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();
            // setInterval(PageViewModel.update, 5000);

            var data = config.initConfig;
            if (data && data.purchaseOrderId) {
                $timeout(function() {
                    // PageViewModel.update(function () {
                        PageViewModel.setFilterIdAndFirst('purchase_orders.id', data.purchaseOrderId, function (row) {
                            openDetailDialog(row);
                        });
                    // });
                }, 500);
            }
            else if (config.popup && data.rawMaterialSKU) {
                PageViewModel.setFilterRules([
                    {"id":'raw_materials.name',"field":'raw_materials.name',"type":"string","input":"text","operator":"equal","value":data.rawMaterialSKU},
                    {"id":'purchase_orders.status',"field":'purchase_orders.status',"type":"string","input":"text","operator":"in","value":['modified', 'uploaded to xero', 'uploaded to netsuite']},
                ]);
            }

            $('#modal')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/product/po");
                });
        }

        function openDetailDialog(row) {
            var counter = [];
            var previous_received_qty = 0;
            vm.detail = $.extend(true, {}, row);
            vm.detail.items = vm.detail.items.map((item) => {
                var length = 0;
                for(var i = 0; i < vm.detail.items.length; ++i){
                    if(vm.detail.items[i]['item_code'] == item.item_code)
                        length++;
                }
                //console.log('length: ' + length);
                //console.log('item_code: ' + item.item_code);
                if (counter[item.item_code] !== undefined) {
                    counter[item.item_code]["i"]++;
                } else {
                    counter[item.item_code] = {i:1,previous_received_qty:0};
                } 
                //console.log('counter: ' + counter[item.item_code]["i"]);

                item.good_received = 0;
                item.ttl_good_received = 0;
                vm.detail.inoutRecords.forEach((inoutRecord) => {
                    if (inoutRecord && item.rawMaterial && inoutRecord.skuId == item.rawMaterial.id) {
                        item.ttl_good_received += inoutRecord.quantity;
                    }
                    //console.log('a: ' + inoutRecord.pivot.inout_record_id);
                })
                //console.log(vm.detail.inoutRecords);
                //console.log(vm.detail.items);
                //console.log('item: ' + item.quantity);
                //console.log('ttl_good_received: ' + item.ttl_good_received);
                if ((item.quantity <= item.ttl_good_received) && (counter[item.item_code]["i"] !== length)) {
                    if ((item.ttl_good_received - counter[item.item_code]["previous_received_qty"]) >= item.quantity) {
                        item.good_received = item.quantity;
                    } else {
                        item.good_received = item.ttl_good_received - counter[item.item_code]["previous_received_qty"];
                    }
                    
                    counter[item.item_code]["previous_received_qty"] = counter[item.item_code]["previous_received_qty"] + item.good_received;
                    //console.log('item_previous_received_qty: ' + counter[item.item_code]["previous_received_qty"]);
                } else {
                    item.good_received = item.ttl_good_received - counter[item.item_code]["previous_received_qty"];
                }
                //console.log('good_received: ' + item.good_received);
                item.balance = (item.override_quantity ? item.override_quantity : item.quantity) - (item.override_good_received ? item.override_good_received : item.good_received);
                return item;
            })
            window.history.pushState(null, null, "/product/po/" + vm.detail.id);
            PageViewModel.openModal();
        }

        function xeroAuth() {

            XeroService.auth().then(function() {
                PageViewModel.switchModal('#modal-auth', '#modal');
            })
            .finally(function() {
                PageViewModel.hideModal('#modal-auth');
            });

        }

        function submit() {
            vm.detail.processing = true;
            serverGateway.ajax('po-data-update', '', vm.detail).then(function(response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    if (idx !== -1) {
                        vm.rows[idx] = vm.detail;
                    }
                    
                    $.bootstrapGrowl("Saved.", {ele: 'body', type: 'success'});
                    window.location.reload();
                } else {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }

                
                delete vm.detail.processing;
            });

            // if (XeroService.isAuthenticated()) {
            //     vm.detail.error = [];
            //     vm.detail.processing = true;
            //     XeroService.savePurchaseOrder({id: vm.detail.id}).then(function(e) {
            //         var idx = _.findIndex(vm.rows, function(row) {
            //             return row.id == vm.detail.id;
            //         });
            //         if (idx !== -1) {
            //             vm.rows[idx] = e.data.po;
            //             vm.detail = $.extend(true, {}, vm.rows[idx]);
            //         }

            //     }, function(e) {
            //         vm.detail.errors = e.data.errors;
            //     })
            //     .finally(function() {
            //         vm.detail.processing = false;
            //     });

            // }
            // else {
            //     PageViewModel.switchModal('#modal', '#modal-auth');
            // }
        }

        function download() {
            if (XeroService.isAuthenticated()) {
                window.open(downloadUrl());
            }
            else {
                PageViewModel.switchModal('#modal', '#modal-auth');
            }
        }

        function downloadUrl() {
            return XeroService.downloadPurchaseOrder({id: vm.detail.id, po_number: vm.detail.po_number });
        }

        function totalAmount(items) {
            var amount = 0;
            for (var idx in items) {
                amount += items[idx].quantity * items[idx].unit_price;
            }
            return amount;
        }

        function poDelete() {
            PageViewModel.switchModal('#modal', '#modal-delete');
        }

        function poDeleteConfirm() {
            if (XeroService.isAuthenticated()) {

                vm.detail.processing = true;
                XeroService.deletePurchaseOrder({id: vm.detail.id, po_number: vm.detail.po_number });
                serverGateway.ajax('po-data-delete', '', vm.detail).then(function(response) {
                    if (response.data.status == 'success') {
                        var idx = _.findIndex(vm.rows, function (row) {
                            return vm.detail.id == row.id;
                        });
                        if (idx != -1) {
                            vm.rows.splice(idx, 1);
                        }
                        PageViewModel.hideModal('#modal-delete');
                    }
                    delete vm.detail.processing;
                });

            }
            else {
                PageViewModel.switchModal('#modal-delete', '#modal-auth');
            }
        }

        function poDeleteCancel() {
            PageViewModel.switchModal('#modal-delete', '#modal');
        }

        function popupSelectPO(row) {

            Popup.setReturnAndClose(row);

        }

        function refetchPO(po) {
            vm.detail.refetchPOLoading = true;
            serverGateway.ajax('po-refetch', '', {po : po}).then(function(response) {
                vm.detail.refetchPOLoading = false;
                $.bootstrapGrowl("Reload complete.", {ele: 'body', type: 'success'});
                window.location.reload();
            });
        }
    }

    function productController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.sorts            = [];
        vm.addTag           = addTag;
        vm.removeTag        = removeTag;
        vm.searchTag        = searchTag;
        vm.mode             = '';
        vm.duplicate        = productDuplicate;
        vm.delete           = productDelete;
        vm.deleteConfirm    = productDeleteConfirm;
        vm.deleteCancel     = productDeleteCancel;
        vm.create           = productCreate;
        vm.notifyOutOfStock = notifyOutOfStock;
        vm.notifyRestock = notifyRestock;
        vm.updateSaleAvg = updateSaleAvg;
        vm.validateForm = validateForm;
        vm.refreshStockControlStatus = refreshStockControlStatus;
        vm.manualFadeout = manualFadeout;

        initialize();

        initializeCustomAutocomplete();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'product-tag-search' : { path: 'product-tag-search', method: 'GET' },
                    'product-update'     : { path: 'product-update', method: 'POST' },
                    'product-show'     : { path: 'products/{id}/show', method: 'GET' },
                    'product-create'     : { path: 'product-create', method: 'POST' },
                    'product-delete'     : { path: 'product-delete', method: 'POST' },
                    'product-notify-out-of-stock'     : { path: 'products/{id}/notifyOutOfStock', method: 'POST' },
                    'product-notify-restock'     : { path: 'products/{id}/notifyRestock', method: 'POST' },
                    'product-refresh-stock-control-status'     : { path: 'products/{id}/refreshStockControlStatus', method: 'POST' },
                    'product-manual-fadeout' : {path: 'products/{id}/manualFadeout', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 20,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });

            $scope.$watch(function() {
                return vm.detail.is_non_print;
            }, vm.validateForm);

            $scope.$watch(function() {
                return vm.detail.should_auto_out_of_stock;
            }, vm.validateForm);

            $scope.$watch(function() {
                return vm.detail.should_auto_fadeout;
            }, vm.validateForm);

            $scope.$watch(function() {
                return vm.detail.should_stock_status_sync_with_client;
            }, vm.validateForm);


            // for direct access of specific raw material
            var data = config.initConfig;
            if (data.itemOption) {
                $timeout(function() {
                    PageViewModel.update(function () {
                        PageViewModel.setFilterIdAndFirst('products.name', data.itemOption, function (row) {
                            openDetailDialog(row, 'update');
                        });
                    });
                }, 500);
                PageViewModel.initializeQueryBuilder(config.filtersConfig);
            } else {
                $timeout(function() { PageViewModel.update(); }, 500);

                PageViewModel.initializeQueryBuilder(config.filtersConfig);
                // setInterval(PageViewModel.update, 5000);
                $(".rule-value-container").last().find("input").focus()
            }
        }

        function initializeCustomAutocomplete() {
            $('.result-search').focus(function() {
                $timeout(function() {
                    vm.detail.showResult = true;
                });
            });
            $(window).click(function(evt) {
                if (!$.contains($('.result-container')[0], evt.target)) $timeout(function() {
                    vm.detail.showResult = false;
                });
            });
        }

        function validateForm() {
            // validate form
            var weightWarning = [];
            if (vm.detail.weight <= 0) {
                weightWarning.push('Weight should be > 0')
            }

            $('#weight-label a.custom-warning').remove();
            if (weightWarning && weightWarning.length) {
                $('#weight-label').addClass('bg-warning');
                $('#weight-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+weightWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#weight-label').removeClass('bg-warning');
            }

            var itemOptionWarning = [];
            if (!/^\d+$/.test(vm.detail.name)) {
                itemOptionWarning.push('Should be digit only')
            }

            $('#item-option-label a.custom-warning').remove();
            if (itemOptionWarning && itemOptionWarning.length) {
                $('#item-option-label').addClass('bg-warning');
                $('#item-option-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+itemOptionWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#item-option-label').removeClass('bg-warning');
            }

            var tagWarning = [];
            var deviceFound = false;
            var productFound = false;
            $(vm.detail.tags).each(function (index, item) {
                if (item.category == 'Device') {
                    deviceFound = true;
                }
                if (item.category == 'Product') {
                    productFound = true;
                }
            })
            if (vm.detail.is_non_print == 0 && !deviceFound) {
                tagWarning.push('Device tag is missing');
            }
            if (vm.detail.is_non_print == 0 && !productFound) {
                tagWarning.push('Product tag is missing');
            }
            $('#tag-label a.custom-warning').remove();
            if (tagWarning && tagWarning.length) {
                $('#tag-label').addClass('bg-warning');
                $('#tag-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+tagWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#tag-label').removeClass('bg-warning');
            }


            var bomWarning = [];
            var nonPrintWarning = [];
            var hasFadeoutItem = false;
            var hasPrintingItem = false;
            $(vm.detail.bom).each(function (index, item) {
                if (item.rawMaterial.is_fadeout) {
                    hasFadeoutItem = true;
                }
                if (item.rawMaterial.allow_printing) {
                    hasPrintingItem = true;
                }
            })
            if (vm.detail.bom && !vm.detail.bom.length) {
                bomWarning.push('BOM should not be empty');
            }

            if (hasFadeoutItem) {
                bomWarning.push('BOM should not contains fadeout items');
            }

            if (vm.detail.is_non_print == 1 && hasPrintingItem) {
                nonPrintWarning.push('Non print item should not contains printing material');
            }

            if (vm.detail.is_non_print == 0 && !hasPrintingItem) {
                nonPrintWarning.push('Printing item should contains printing material');
            }

            $('#bom-label a.custom-warning').remove();
            if (bomWarning && bomWarning.length) {
                $('#bom-label').addClass('bg-warning');
                $('#bom-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+bomWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#bom-label').removeClass('bg-warning');
            }

            $('#non-print-label a.custom-warning').remove();
            if (nonPrintWarning && nonPrintWarning.length) {
                $('#non-print-label').addClass('bg-warning');
                $('#non-print-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+nonPrintWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#non-print-label').removeClass('bg-warning');
            }

            var autoFadeoutWarning = [];
            var autoOOSWarning = [];
            if (vm.detail.should_auto_fadeout == 1 && vm.detail.should_auto_out_of_stock == 1) {
                autoFadeoutWarning.push('Auto out of Stock conflict with auto fadeout');
                autoOOSWarning.push('Auto out of Stock conflict with auto fadeout');
            }

            $('#auto-fadeout-label a.custom-warning').remove();
            if (autoFadeoutWarning && autoFadeoutWarning.length) {
                $('#auto-fadeout-label').addClass('bg-warning');
                $('#auto-fadeout-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+autoFadeoutWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#auto-fadeout-label').removeClass('bg-warning');
            }

            $('#auto-oos-label a.custom-warning').remove();
            if (autoOOSWarning && autoOOSWarning.length) {
                $('#auto-oos-label').addClass('bg-warning');
                $('#auto-oos-label').prepend('<a href="#" class="custom-warning" data-toggle="popover" data-html="true" data-content="'+autoOOSWarning.join('<br/>')+'"><i class="fa fa-info-circle fa-2" aria-hidden="true"></i></a>');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            } else {
                $('#auto-oos-label').removeClass('bg-warning');
            }
        }

        function updateSaleAvg (data) {
            var sum = 0;
            var isInRange = false;
            for (var index in vm.detail.salesHistory) {
                if (index == data.from_value) {
                    isInRange = true;
                }

                if (isInRange) {
                    sum += vm.detail.salesHistory[index];
                }
                if (index == data.to_value) {
                    isInRange = false;
                }
            }
            $(".sales-avg").text("" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)");
            // vm.salesAvg = "" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)"; // dont know why angular is very slow
        }

        function openDetailDialog(row, mode) {
            vm.mode = (mode || 'update');
            vm.detail = $.extend(true, { tags: [], bom: [] }, row);
            PageViewModel.openModal();
            
            if (vm.mode == 'update') {
                vm.detail.loadingDetail = true;
                serverGateway.ajax('product-show', {id: row.id}).then(function(response) {
                    if (response.data.data.id != row.id) return;
                    vm.detail = $.extend(true, { tags: [], bom: [] }, response.data.data);
                    delete vm.detail.loadingDetail;
                    var idx = _.findIndex(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    vm.rows[idx] = vm.detail;
                    $timeout(function() {
                        if ($("#sale-slider").data("ionRangeSlider")) {
                            $("#sale-slider").data("ionRangeSlider").destroy();
                        }

                        if (vm.detail.salesHistoryDates && vm.detail.salesHistoryDates.length) {
                            $("#sale-slider").ionRangeSlider({
                                type: 'double',
                                values: vm.detail.salesHistoryDates,
                                onChange: vm.updateSaleAvg,
                                onFinish: vm.updateSaleAvg,
                                onUpdate: vm.updateSaleAvg,
                                onStart: vm.updateSaleAvg
                            });
                        }
                        $('input').keyup(vm.validateForm)
                        $('input').change(vm.validateForm)
                        vm.validateForm()

                    });
                });
            } else {
                $timeout(function() {
                    if ($("#sale-slider").data("ionRangeSlider")) {
                        $("#sale-slider").data("ionRangeSlider").destroy();
                    }

                    if (vm.detail.salesHistoryDates && vm.detail.salesHistoryDates.length) {
                        $("#sale-slider").ionRangeSlider({
                            type: 'double',
                            values: vm.detail.salesHistoryDates,
                            onChange: vm.updateSaleAvg,
                            onFinish: vm.updateSaleAvg,
                            onUpdate: vm.updateSaleAvg,
                            onStart: vm.updateSaleAvg
                        });
                    }
                    $('input').keyup(vm.validateForm)
                    $('input').change(vm.validateForm)
                    vm.validateForm()

                });
            }
            
            
            // $timeout(function() { $('.inlinesparkline').sparkline(); });
        }

        function submit() {
            vm.detail.processing = true;
            serverGateway.ajax(vm.mode == 'update' ? 'product-update' : 'product-create', '', vm.detail).then(function(response) {
                if (response.data.status == 'success') {
                    var row = _.find(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    if (typeof row !== 'undefined') {
                        row.name         = response.data.product.name;
                        row.description  = response.data.product.description;
                        row.weight  = response.data.product.weight;
                        row.shipment_description  = response.data.product.shipment_description;
                        row.shipment_description_zh  = response.data.product.shipment_description_zh;
                        row.is_non_print  = response.data.product.is_non_print;
                        row.tags         = response.data.product.tags;
                        row.display_tags = response.data.product.display_tags;
                        row.is_fadeout  = response.data.product.is_fadeout;
                        row.should_auto_fadeout  = response.data.product.should_auto_fadeout;
                        row.should_auto_out_of_stock  = response.data.product.should_auto_out_of_stock;
                        row.should_auto_restock  = response.data.product.should_auto_restock;
                        row.should_stock_status_sync_with_client  = response.data.product.should_stock_status_sync_with_client;
                    }
                    if (vm.mode == 'update') {
                        PageViewModel.hideModal();
                    }
                    if (vm.mode == 'create') {
                        vm.mode = 'update';
                        vm.detail = response.data.product;
                        PageViewModel.hideModal('#modal');
                        $timeout(function() { PageViewModel.openModal('#modal'); }, 1000);
                    }
                }
                delete vm.detail.processing;
            });
        }

        function addTag(inputTag) {
            var idx = _.findIndex(vm.detail.tags, function(tag) {
                return inputTag.id == tag.id;
            });

            if (idx == -1) {
                idx = _.findIndex(vm.detail.tagResult, function(tag) {
                    return inputTag.id == tag.id;
                });
                var tag = null
                if (idx !== -1) vm.detail.tagResult.splice(idx, 1);
                vm.detail.tags.push(tag ? tag : inputTag);
            }
            vm.validateForm()
        }

        function removeTag(inputTag) {
            var idx = _.findIndex(vm.detail.tags, function(tag) {
                return inputTag.id == tag.id;
            });

            if (idx !== -1) vm.detail.tags.splice(idx, 1);
            vm.validateForm()
        }

        function searchTag() {
            if (typeof vm.detail.keyword !== 'undefined' && vm.detail.keyword.length) {
                serverGateway.ajax('product-tag-search', '', {
                    query      : vm.detail.keyword,
                    excludeIds : _.map(vm.detail.tags, function(tag) {
                        return tag.id;
                    })
                }).then(function(response) {
                    vm.detail.showResult = true;
                    vm.detail.tagResult = response.data.tags;
                });
            }
        }

        function productDuplicate() {
            vm.mode = 'create';
            vm.detail.description += ' - Copy';
        }

        function productDelete() {
            PageViewModel.switchModal('#modal', '#modal-delete');
        }

        function productDeleteCancel() {
            PageViewModel.switchModal('#modal-delete', '#modal');
        }

        function productDeleteConfirm() {
            vm.detail.processing = true;
            serverGateway.ajax('product-delete', '', vm.detail).then(function(response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return vm.detail.id == row.id;
                    });
                    if (idx != -1) vm.rows.splice(idx, 1);
                    PageViewModel.hideModal('#modal-delete');
                }
                delete vm.detail.processing;
            });
        }

        function productCreate() {
            vm.mode = 'create';
            openDetailDialog({}, vm.mode);
        }

        function notifyOutOfStock(locationGroupId) {
            vm.outOfStockProcessing = true;
            serverGateway.ajax('product-notify-out-of-stock', {id: vm.detail.id}, {locationGroupId: locationGroupId}).then(function(response) {
                vm.outOfStockProcessing = false;
                $.bootstrapGrowl("Notified", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.outOfStockProcessing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function notifyRestock(locationGroupId) {
            vm.restockProcessing = true;
            serverGateway.ajax('product-notify-restock', {id: vm.detail.id}, {locationGroupId: locationGroupId}).then(function(response) {
                vm.restockProcessing = false;
                $.bootstrapGrowl("Notified", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.restockProcessing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function refreshStockControlStatus() {
            vm.refreshStockControlStatusProcessing = true;
            serverGateway.ajax('product-refresh-stock-control-status', {id: vm.detail.id}).then(function(response) {
                vm.refreshStockControlStatusProcessing = false;
                $.bootstrapGrowl("Refreshed", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.refreshStockControlStatusProcessing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function manualFadeout(value) {
            if (!confirm('Are you sure?')) return;

            serverGateway.ajax('product-manual-fadeout', {id: vm.detail.id}, {flag: value}).then(function(response){

                $.bootstrapGrowl("Product updated", {ele: 'body', type: 'success'});
                window.location.reload();
            },function(response){

            });
        }
    }

    function productTagController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.sorts            = [];
        vm.deleteProductTag = deleteProductTag;
        vm.deleteConfirm    = deleteConfirm;
        vm.deleteCancel     = deleteCancel;
        vm.create           = create;
        vm.mode             = '';

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'product-tag-update'     : { path: 'product-tag-update', method: 'POST' },
                    'product-tag-create'     : { path: 'product-tag-create', method: 'POST' },
                    'product-tag-delete'     : { path: 'product-tag-delete', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 100,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // setInterval(PageViewModel.update, 5000);
            $(".rule-value-container").last().find("input").focus()
        }

        function openDetailDialog(row, mode) {
            vm.mode = mode || 'create';
            vm.detail = row;

            PageViewModel.openModal();
        }

        function deleteProductTag() {
            PageViewModel.switchModal('#modal', '#modal-delete');
        }

        function deleteCancel() {
            PageViewModel.switchModal('#modal-delete', '#modal');
        }

        function deleteConfirm() {

            serverGateway.ajax('product-tag-delete', '', vm.detail).then(function (response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });

                    if (idx != -1) vm.rows.splice(idx, 1);

                    PageViewModel.hideModal('#modal-delete');
                }
            });

        }

        function create() {
            openDetailDialog({}, 'create');
        }

        function submit() {

            serverGateway.ajax(vm.mode == 'create' ? 'product-tag-create' : 'product-tag-update', '', vm.detail).then(function (response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });

                    if (vm.mode == 'create') {
                        window.location.reload();
                    }
                    else if (vm.mode == 'update') {
                        if (idx != -1)
                            vm.rows[idx] = response.data.tag;
                    }

                    PageViewModel.hideModal();
                }
                else
                    vm.detail.error = true;
            });

        }

    }

    function BOMController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        vm.rows             = [];
        vm.columns          = config.columns;
        vm.categories       = {};
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.sorts            = [];
        vm.initialize       = initialize;
        vm.bom              = [];
        vm.product          = {};
        vm.bomStatus        = [];
        vm.removeFromList   = removeFromList;
        vm.addToList        = addTolist;
        vm.resetProductBom  = resetProductBom;
        vm.openDetailDialog = openDetailDialog;
        vm.submit           = submit;

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'bom-info':  { path: 'bom-info', method: 'GET' },
                    // 'bom-data':  { path: 'bom-data', method: 'GET' },
                    'bom-update':  { path: 'bom-update', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 20,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;

                for (var idx in vm.bom) {
                    var item = _.find(vm.rows, function(item) {
                        return item.id == vm.bom[idx].rawMaterial.id;
                    });
                    if (typeof item !== 'undefined') item.added = true;
                }
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            $(".rule-value-container").last().find("input").focus();
            // setInterval(PageViewModel.update, 5000);

            var data = config.initConfig;
            if (data.id) {
                serverGateway.ajax('bom-info', '', {
                    id: data.id
                }).then(function(response) {
                    if (response.data.status == 'success') {
                        vm.product   = response.data.product;
                        vm.bomStatus = response.data.bomStatus;
                        vm.bom       = vm.product.bom.slice();
                        vm.isRequired = response.data.isRequired;
                    }
                });
            }

            $timeout(function () {
                $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"raw_materials.effective_only","field":"raw_materials.effective_only","type":"boolean","input":"radio","operator":"equal","value":"true"}]});
                PageViewModel.setFilter();
                PageViewModel.update(function () {
                    $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"raw_materials.effective_only","field":"raw_materials.effective_only","type":"boolean","input":"radio","operator":"equal","value":"true"},{"id":'raw_materials.name',"field":'raw_materials.name',"type":"string","input":"text","operator":"contains","value":''}]});
                    $(".rule-value-container").last().find("input").focus()
                });
            }, 500);

        }

        function removeFromList(inputRawMaterial) {
            var idx = _.findIndex(vm.product.bom, function(bom) {
                return inputRawMaterial.id == bom.rawMaterial.id;
            });

            if (idx !== -1) {
                vm.product.bom.splice(idx, 1)[0].rawMaterial.added = false;
                PageViewModel.refreshWaypoint();
            }
        }

        function addTolist(inputRawMaterial) {
            vm.product.bom.push({
                quantity: 1,
                status: 'normal',
                isRequired: '1',
                rawMaterial: inputRawMaterial
            });
            inputRawMaterial.added = true;
        }

        function resetProductBom() {
            vm.product.bom = vm.bom.slice();
            vm.clearFilter();
        }

        function openDetailDialog() {
            PageViewModel.openModal();
        }

        function submit(e) {
            e.preventDefault();
            serverGateway.ajax('bom-update', '', {
                id: vm.product.id,
                bom: _.map(vm.product.bom, function (bom) {
                    return {
                        raw_material_id : bom.rawMaterial.id,
                        quantity        : bom.quantity,
                        status          : bom.status,
                        is_required          : bom.isRequired,
                        printing_artwork_id          : bom.printingArtworkId,
                        printing_product_id          : bom.printingProductId,
                    };
                })
            }).then(function(response) {
                if (response.data.status == 'success') {
                    window.location.reload();
                }
            });
        }

        return vm;
    }

    function productLeadTimeController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.isCommitting     = false;
        vm.sorts            = [];
        vm.commit           = commit;
        vm.mode             = '';

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'product-lead-time-update'     : { path: 'product-lead-time-update', method: 'POST' },
                    'product-lead-time-commit'     : { path: 'product-lead-time-commit', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 20,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // setInterval(PageViewModel.update, 5000);
            $(".rule-value-container").last().find("input").focus()
        }

        function openDetailDialog(row, mode) {
            vm.mode = mode || 'create';
            vm.detail = row;

            PageViewModel.openModal();
        }

        function submit() {

            serverGateway.ajax('product-lead-time-update', '', vm.detail).then(function (response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });

                    if (idx != -1) {
                        _.forEach(vm.detail.leadTime, function(leadTime, tier) {
                            vm.rows[idx]["leadTime_"+tier] = ( leadTime.external_lead_lower==leadTime.external_lead_upper ? leadTime.external_lead_lower : leadTime.external_lead_lower+'-'+leadTime.external_lead_upper ) + ' day(s)';
                        });
                    }
                    PageViewModel.hideModal();
                }
                else
                    vm.detail.error = true;
            });

        }

        function commit() {

            vm.isCommitting = true;
            serverGateway.ajax('product-lead-time-commit').then(function (response) {
                vm.isCommitting = false;
                if (response.data.status == 'success') {
                    alert('Committed successfully!');
                }
                else
                    alert('The commit is failed.');
            });

        }
    }

    function historyController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {
        var serverGateway, vm = this;

        // vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.sorts            = [];

        vm.downloadInventoryList = downloadInventoryList;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'bom-info':  { path: 'bom-info', method: 'GET' },
                    // 'bom-data':  { path: 'bom-data', method: 'GET' },
                    'bom-update':  { path: 'bom-update', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 150,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                var disableStockLv = false;
                if(vm.gridDataSource.rules.rules){
                    vm.gridDataSource.rules.rules.forEach(rule => {
                        if(rule.type == 'datetime'){//not support if search by datetime range
                            disableStockLv = true;
                        }
                    });
                }
                var originalQty = {};
                PageViewModel.rows.forEach(row => {
                    if(!row.skuId || disableStockLv){
                        row.stock_lv = '';
                        return;
                    }
                    const key = `location_id_${row.location.id}|raw_material_id_${row.skuId}`;
                    if(!(key in originalQty)){
                        originalQty[key] = row.originalQty;
                    }
                    const currentQty = originalQty[key] - row.quantity;
                    row.stock_lv = `${currentQty} => ${originalQty[key]}`;
                    originalQty[key] = currentQty;
                });
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // setInterval(PageViewModel.update, 5000);
            $(".rule-value-container").last().find("input").focus()

            if (config.sku) {
                $timeout(function () {
                    $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"raw_materials.name","field":"raw_materials.name","type":"string","input":"text","operator":"contains","value":config.sku}]});
                    PageViewModel.setFilter();
                    PageViewModel.update();
                });

            }
            if (config.fgSku) {
                $timeout(function () {
                    $('#builder-basic').queryBuilder('setRules', {"condition":"AND","rules":[{"id":"finished_goods.sku","field":"finished_goods.sku","type":"string","input":"text","operator":"contains","value":config.fgSku}]});
                    PageViewModel.setFilter();
                    PageViewModel.update();
                });

            }
        }

        function openDetailDialog(row, mode) {
            vm.mode = mode || 'create';
            vm.detail = row;

            PageViewModel.openModal();
        }

        function downloadInventoryList() {
            vm.downloadhistoryListLoading = true;
            Popup.open('/inventory/download?locationId='+config.locationId + (vm.inventoryListDateBack ? ('&date=' + vm.inventoryListDateBack) : ''), null).then(function () {
                vm.downloadhistoryListLoading = false;
            });
        }

    }

    function DSAController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {
        var serverGateway, vm = this;

        // vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.submit = submit;
        vm.create = create;
        vm.deliveryScheduleDelete = deliveryScheduleDelete;
        vm.showCreateFromPO = showCreateFromPO;
        vm.showCreate = showCreate;
        vm.showDeliveryScheduleImport = showDeliveryScheduleImport;
        vm.deliveryScheduleExport = deliveryScheduleExport;
        vm.sorts            = [];

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'delivery-schedule-update':  { path: 'delivery-schedule-update', method: 'POST' },
                    'delivery-schedule-delete':  { path: 'delivery-schedule-delete/{id}', method: 'POST' },
                    'delivery-schedule-create':  { path: 'delivery-schedule-create', method: 'POST' },
                    'findPo':  { path: 'findPo/{id}', method: 'GET' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 150,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // setInterval(PageViewModel.update, 5000);
            $(".rule-value-container").last().find("input").focus()
            var data = config.initConfig;
            if (data && data.id) {
                PageViewModel.update(function () {
                    PageViewModel.setFilterIdAndFirst('delivery_schedules.id', data.id, function (row) {
                        openDetailDialog(row, 'update');
                    });
                });
            }
        }

        function openDetailDialog(row, mode) {
            vm.detail = row;

            PageViewModel.openModal();
        }

        function showDeliveryScheduleImport() {
            // $("#import-form").attr('action', '/product/delivery-schedule/' + vm.detail.id + '/import');
            PageViewModel.openModal('#modal-delivery-schedule-import');
        }

        function deliveryScheduleExport() {
            // $("#import-form").attr('action', '/product/delivery-schedule/' + vm.detail.id + '/import');
            window.location.href = '/product/delivery-schedule/' + vm.detail.id + '/export';
        }
        
        function submit() {
            vm.detail.processing = true;
            serverGateway.ajax('delivery-schedule-update', '', vm.detail).then(function(response) {

                PageViewModel.hideModal();
                delete vm.detail.processing;
                PageViewModel.update();
            });
        }

        function create() {
            vm.detail.processing = true;
            serverGateway.ajax('delivery-schedule-create', '', vm.newDeliverySchedule).then(function(response) {

                PageViewModel.hideModal('#modal-from-po');
                delete vm.detail.processing;
                $.bootstrapGrowl('Delivery schedule created', {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                delete vm.detail.processing;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deliveryScheduleDelete() {
            vm.detail.processing = true;
            serverGateway.ajax('delivery-schedule-delete', {id: vm.detail.id}).then(function(response) {

                PageViewModel.hideModal();
                delete vm.detail.processing;
                $.bootstrapGrowl('Delivery schedule deleted', {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                delete vm.detail.processing;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showCreateFromPO() {
            vm.fetchPOLoading = true;
            serverGateway.ajax('findPo', {'id': vm.po_number}).then(function (response) {
                vm.fetchPOLoading = false;
                vm.newDeliverySchedule = response.data.data;
                vm.newDeliverySchedule = {};
                vm.newDeliverySchedule.po_number = response.data.data.po_number;
                vm.newDeliverySchedule.target = 'ready';
                vm.newDeliverySchedule.items = response.data.data.items;

                PageViewModel.openModal('#modal-from-po');
            }, function (response) {
                vm.fetchPOLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showCreate() {
            vm.newDeliverySchedule = {};
            PageViewModel.openModal('#modal-create');
        }
    }

    function CustomFilterController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        // vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.showCreateCustomFilter = showCreateCustomFilter;
        vm.createCustomFilter = createCustomFilter;
        vm.updateCustomFilter = updateCustomFilter;
        vm.deleteCustomFilter = deleteCustomFilter;
        vm.deleteCancel = deleteCancel;
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.submit = submit;
        vm.sorts            = [];

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-custom-filter':  { path: 'inventory/custom-filters/create', method: 'POST' },
                    'update-custom-filter':  { path: 'inventory/custom-filters/{id}/update', method: 'POST' },
                    'delete-custom-filter':  { path: 'inventory/custom-filters/{id}/delete', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 150,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);


            // setInterval(PageViewModel.update, 5000);

        }

        function showCreateCustomFilter() {
            vm.detail.category = 'raw_material';
            vm.detail.displayName = 'iPad Case';
            vm.detail.filterContent = 'CTF-T-CWA-1811169.7WHX,IPD-FOLIOCSE-AIR-BLK,IPD-FOLIOCSE-MIN3-BLK,IPD-FOLIOCSE-MIN4-BLK,IPD-FOLIOCSE-PRO10.5-BLK,CTF-T-CWA-181116P11WHX,IPD-FOLIOCSE-PRO12.9-BLK,CTF-T-CWA-181116P12WHX,IPD-FOLIOCSE-PRO-BLK';
            PageViewModel.openModal('#modal-new-filter');
        }

        function createCustomFilter() {
            vm.createCustomFilterLoading = true;
            serverGateway.ajax('create-custom-filter', '', vm.detail).then(function (response) {
                $.bootstrapGrowl('Filter Created.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-new-filter');
            }, function (response) {
                vm.createCustomFilterLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateCustomFilter() {
            vm.updateCustomFilterLoading = true;
            serverGateway.ajax('update-custom-filter', {id: vm.detail.id}, vm.detail).then(function (response) {
                $.bootstrapGrowl('Filter Updated.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-show-filter');
            }, function (response) {
                vm.updateCustomFilterLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteCustomFilter() {
            if (!confirm("Confirm delete?")) {
                return;
            }
            vm.deleteCustomFilterLoading = true;
            serverGateway.ajax('delete-custom-filter', {id: vm.detail.id}).then(function (response) {
                $.bootstrapGrowl('Filter Deleted.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-show-filter');
            }, function (response) {
                vm.deleteCustomFilterLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDetailDialog(row, mode) {
            vm.detail = row;
            PageViewModel.openModal('#modal-show-filter');
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-new-filter');
            PageViewModel.hideModal('#modal-show-filter');
        }

        
        function submit() {
            vm.detail.processing = true;
        }
    }

    function RawMaterialTagController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        // vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.showCreateRawMaterialTag = showCreateRawMaterialTag;
        vm.createRawMaterialTag = createRawMaterialTag;
        vm.updateRawMaterialTag = updateRawMaterialTag;
        vm.deleteRawMaterialTag = deleteRawMaterialTag;
        vm.deleteCancel = deleteCancel;
        vm.showDeleteRawMaterialTag = showDeleteRawMaterialTag;
        vm.deleteRawMaterialTagByCategory = deleteRawMaterialTagByCategory;
        vm.delete_category = '';
        
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.submit = submit;
        vm.sorts            = [];

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-raw-material-tag':  { path: 'inventory/raw-material-tag/create', method: 'POST' },
                    'update-raw-material-tag':  { path: 'inventory/raw-material-tag/{id}/update', method: 'POST' },
                    'delete-raw-material-tag':  { path: 'inventory/raw-material-tag/{id}/delete', method: 'POST' },
                    'delete-raw-material-tag-by-category':  { path: 'inventory/raw-material-tag/delete-category', method: 'POST' }
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 150,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $(".rule-value-container").last().find("input").focus();

            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

        }

        function showCreateRawMaterialTag() {
            vm.detail.category = 'raw_material';
            vm.detail.name = 'IP4-SNPV1-WHE';
            PageViewModel.openModal('#modal-new-filter');
        }

        function createRawMaterialTag() {
            vm.createRawMaterialTagLoading = true;
            serverGateway.ajax('create-raw-material-tag', '', vm.detail).then(function (response) {
                $.bootstrapGrowl('Raw Material Tag Created.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-new-filter');
            }, function (response) {
                vm.createRawMaterialTagLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateRawMaterialTag() {
            vm.updateRawMaterialTagLoading = true;
            serverGateway.ajax('update-raw-material-tag', {id: vm.detail.id}, vm.detail).then(function (response) {
                $.bootstrapGrowl('Raw Material Tag Updated.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-show-filter');
            }, function (response) {
                vm.updateRawMaterialTagLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteRawMaterialTag() {
            if (!confirm("Confirm delete?")) {
                return;
            }
            vm.deleteRawMaterialTagLoading = true;
            serverGateway.ajax('delete-raw-material-tag', {id: vm.detail.id}).then(function (response) {
                $.bootstrapGrowl('Raw Material Tag Deleted.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-show-filter');
            }, function (response) {
                vm.deleteRawMaterialTagLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDetailDialog(row, mode) {
            vm.detail = row;
            PageViewModel.openModal('#modal-show-filter');
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-new-filter');
            PageViewModel.hideModal('#modal-show-filter');
            PageViewModel.hideModal('#modal-delete-raw-material-tag');
        }

        
        function submit() {
            vm.detail.processing = true;
        }

        function deleteRawMaterialTagByCategory(){
            vm.deleteRawMaterialTagLoading = true;

            serverGateway.ajax('delete-raw-material-tag-by-category', '', {category: vm.delete_category}).then(function (response) {
                $.bootstrapGrowl('Category deleted.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-delete-raw-material-tag');
            }, function (response) {
                vm.deleteRawMaterialTagLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showDeleteRawMaterialTag() {
            PageViewModel.openModal('#modal-delete-raw-material-tag');
        }

    }

    function ReorderRequestController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;

        // vm.submit           = submit;
        vm.rows             = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.addedItems       = [];
        vm.detail           = {};
        vm.setFilter        = PageViewModel.setFilter;
        vm.clearFilter      = PageViewModel.clearFilter;
        vm.sortChange       = PageViewModel.sortChange;
        vm.isLoading        = PageViewModel._isLoading;
        vm.updateReorderRequest = updateReorderRequest;
        vm.deleteCancel = deleteCancel;
        vm.updateSaleAvg = updateSaleAvg;
        vm.sorts            = [];

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'update-reorder-request':  { path: 'inventory/reorder-requests/{id}/update', method: 'POST' },
                    'show-reorder-request':  { path: 'inventory/reorder-requests/{id}', method: 'GET' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 150,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);


            // setInterval(PageViewModel.update, 5000);

        }

        function updateSaleAvg (data) {
            var sum = 0;
            var isInRange = false;
            for (var index in vm.detail.rawMaterial.salesHistory) {
                if (index == data.from_value) {
                    isInRange = true;
                }

                if (isInRange) {
                    sum += vm.detail.rawMaterial.salesHistory[index];
                }
                if (index == data.to_value) {
                    isInRange = false;
                }
            }
            $(".sales-avg").text("" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)");
            // vm.salesAvg = "" + ((sum / (data.to - data.from)).toFixed(2)) + " item per day ("+(data.to - data.from) + " days avg)";
        }

        function openDetailDialog(row, mode) {
            vm.detail = row;
            vm.detail.ignoreUntil = vm.detail.ignoreUntil ? new Date(vm.detail.ignoreUntil) : null;
            vm.detail.loadingDetail = true;
            PageViewModel.openModal('#modal-show-reorder');
            serverGateway.ajax('show-reorder-request', {id: vm.detail.id}).then(function (response) {
                vm.detail.loadingDetail = false;
                if (response.data.data.id != row.id) return;
                vm.detail = $.extend(true, {}, response.data.data);
                vm.detail.ignoreUntil = vm.detail.ignoreUntil ? new Date(vm.detail.ignoreUntil) : null;
                var idx = _.findIndex(vm.rows, function (row) {
                    return vm.detail.id == row.id;
                });
                vm.rows[idx] = vm.detail;

                $timeout(function() {
                    if ($("#sale-slider").data("ionRangeSlider")) {
                        $("#sale-slider").data("ionRangeSlider").destroy();
                    }
                    if (vm.detail.rawMaterial.salesHistoryDates && vm.detail.rawMaterial.salesHistoryDates.length) {
                        $("#sale-slider").ionRangeSlider({
                            type: 'double',
                            values: vm.detail.rawMaterial.salesHistoryDates,
                            onChange: vm.updateSaleAvg,
                            onFinish: vm.updateSaleAvg,
                            onUpdate: vm.updateSaleAvg,
                            onStart: vm.updateSaleAvg
                        });
                    }
                }, 1000);
            }, function (response) {
                vm.detail.loadingDetail = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function updateReorderRequest() {
            var data = $.extend(true, {}, vm.detail);
            if (data.ignoreUntil) {
                data.ignoreUntil = data.ignoreUntil.dateFormat('Y-m-d 00:00:00')
            }
            vm.detail.reorderRequestLoading = true;
            serverGateway.ajax('update-reorder-request', {id: vm.detail.id}, data).then(function (response) {
                vm.detail.reorderRequestLoading = false;
                $.bootstrapGrowl('Request Updated.', {ele: 'body', type: 'success'});
                PageViewModel.update()
                PageViewModel.hideModal('#modal-show-reorder');
            }, function (response) {
                vm.detail.reorderRequestLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-show-reorder');
        }
    }

    function StockInSessionController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.printer          = config.printerList[0].key;

        vm.printerList           = config.printerList;
        vm.openDetailDialog = openDetailDialog;
        vm.deleteCancel = deleteCancel;
        vm.createStockInSession = createStockInSession;
        vm.removeStockInSession = removeStockInSession;
        vm.updateStockinSession = updateStockinSession;
        vm.confirmStockinSession = confirmStockinSession;
        vm.printStockinSessionItemsLabel = printStockinSessionItemsLabel;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-stockin-session':  { path: 'inventory/stockin-sessions/create', method: 'POST' },
                    'show-stockin-session': { path: 'inventory/stockin-sessions/{id}', method: 'GET' },
                    'remove-stockin-session': { path: 'inventory/stockin-sessions/{id}/delete', method: 'POST' },
                    'update-stockin-session': { path: 'inventory/stockin-sessions/{id}', method: 'POST' },
                    'confirm-stockin-session': { path: 'inventory/stockin-sessions/{id}/confirm', method: 'POST' },
                    'print-stockin-session-items-label': { path: 'inventory/stockin-sessions/{id}/print', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                // perPage: 10,
                locationId: config.locationId,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $('#modal-show-request')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/inventory/stock-transit");
                });
            PageViewModel.update();
            if (config.stockTransitRequestId) {
                vm.openDetailDialog({id: config.stockTransitRequestId})
            }
            setInterval(PageViewModel.update, 5000);
        }

        function createStockInSession() {
            vm.detail.createSessionLoading = false;
            serverGateway.ajax('create-stockin-session', null, {locationId: config.locationId}).then(function (response) {
                vm.detail.createSessionLoading = false;
                $.bootstrapGrowl('Session Created.', {ele: 'body', type: 'success'});
                PageViewModel.update()
            }, function (response) {
                vm.detail.createSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDetailDialog(row) {
            window.history.pushState(null, null, "/inventory/stockin-sessions/" + row.id + "?locationId=" + config.locationId);
            PageViewModel._isLoading = true;
            serverGateway.ajax('show-stockin-session', {id: row.id}).then(function(response) {
                PageViewModel._isLoading = false;
                PageViewModel.openModal('#modal-show-session');
                vm.detail = response.data.data;
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function removeStockInSession(id) {
            PageViewModel._isLoading = true;
            serverGateway.ajax('remove-stockin-session', {id: id}).then(function(response) {
                window.history.pushState(null, null, "/inventory/stockin-sessions?locationId=" + config.locationId);
                PageViewModel._isLoading = false;
                PageViewModel.hideModal('#modal-show-session');
                $.bootstrapGrowl('Session Removed.', {ele: 'body', type: 'success'});
                PageViewModel.update()
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateStockinSession() {
            if (vm.updateStockinSessionLoading) {
                return;
            }
            vm.updateStockinSessionLoading = true;
            serverGateway.ajax('update-stockin-session', {id: vm.detail.id}, {refId: vm.detail.refId, remarks: vm.detail.remarks, order_id: vm.detail.order_id}).then(function(response) {
                vm.updateStockinSessionLoading = false;
                $.bootstrapGrowl('Session Updated.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.updateStockinSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteCancel() {
            window.history.pushState(null, null, "/inventory/stockin-sessions?locationId=" + config.locationId);
            PageViewModel.hideModal('#modal-show-session');
        }

        function confirmStockinSession() {
            if (vm.updateStockinSessionLoading) {
                return;
            }
            vm.updateStockinSessionLoading = true;
            serverGateway.ajax('confirm-stockin-session', {id: vm.detail.id}, {refId: vm.detail.refId, remarks: vm.detail.remarks, order_id: vm.detail.order_id}).then(function(response) {
                vm.updateStockinSessionLoading = false;
                $.bootstrapGrowl('Session Updated.', {ele: 'body', type: 'success'});
                openDetailDialog(vm.detail);
            }, function (response) {
                vm.updateStockinSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printStockinSessionItemsLabel(){
            serverGateway.ajax('print-stockin-session-items-label', {id: vm.detail.id},{ printer: vm.printer }).then(function (response) {
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    function StockOutSessionController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.printer          = config.printerList[0].key;

        vm.printerList           = config.printerList;
        vm.openDetailDialog = openDetailDialog;
        vm.deleteCancel = deleteCancel;
        vm.createStockOutSession = createStockOutSession;
        vm.removeStockOutSession = removeStockOutSession;
        vm.updateStockoutSession = updateStockoutSession;
        vm.confirmStockoutSession = confirmStockoutSession;
        vm.printStockoutSessionItemsLabel = printStockoutSessionItemsLabel;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-stockout-session':  { path: 'inventory/stockout-sessions/create', method: 'POST' },
                    'show-stockout-session': { path: 'inventory/stockout-sessions/{id}', method: 'GET' },
                    'remove-stockout-session': { path: 'inventory/stockout-sessions/{id}/delete', method: 'POST' },
                    'update-stockout-session': { path: 'inventory/stockout-sessions/{id}', method: 'POST' },
                    'confirm-stockout-session': { path: 'inventory/stockout-sessions/{id}/confirm', method: 'POST' },
                    'print-stockout-session-items-label': { path: 'inventory/stockout-sessions/{id}/print', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                // perPage: 10,
                locationId: config.locationId,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $('#modal-show-request')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/inventory/stockout-sessions");
                });
            PageViewModel.update();
            if (config.stockTransitRequestId) {
                vm.openDetailDialog({id: config.stockTransitRequestId})
            }
            setInterval(PageViewModel.update, 5000);
        }

        function createStockOutSession() {
            vm.detail.createSessionLoading = false;
            serverGateway.ajax('create-stockout-session', null, {locationId: config.locationId}).then(function (response) {
                vm.detail.createSessionLoading = false;
                $.bootstrapGrowl('Session Created.', {ele: 'body', type: 'success'});
                PageViewModel.update()
            }, function (response) {
                vm.detail.createSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDetailDialog(row) {
            window.history.pushState(null, null, "/inventory/stockout-sessions/" + row.id + "?locationId=" + config.locationId);
            PageViewModel._isLoading = true;
            serverGateway.ajax('show-stockout-session', {id: row.id}).then(function(response) {
                PageViewModel._isLoading = false;
                PageViewModel.openModal('#modal-show-session');
                vm.detail = response.data.data;
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function removeStockOutSession(id) {
            PageViewModel._isLoading = true;
            serverGateway.ajax('remove-stockout-session', {id: id}).then(function(response) {
                window.history.pushState(null, null, "/inventory/stockout-sessions?locationId=" + config.locationId);
                PageViewModel._isLoading = false;
                PageViewModel.hideModal('#modal-show-session');
                $.bootstrapGrowl('Session Removed.', {ele: 'body', type: 'success'});
                PageViewModel.update()
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateStockoutSession() {
            if (vm.updateStockoutSessionLoading) {
                return;
            }
            vm.updateStockoutSessionLoading = true;
            let updateMap = {};
            vm.detail.items.forEach((row) => {
                if (!updateMap[row.sku]) {
                    updateMap[row.sku] = 0;
                }
                updateMap[row.sku] += row.quantity;
            })
            serverGateway.ajax('update-stockout-session', {id: vm.detail.id}, {refId: vm.detail.refId, remarks: vm.detail.remarks, order_id: vm.detail.order_id, items: updateMap}).then(function(response) {
                vm.updateStockoutSessionLoading = false;
                $.bootstrapGrowl('Session Updated.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.updateStockoutSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteCancel() {
            window.history.pushState(null, null, "/inventory/stockout-sessions?locationId=" + config.locationId);
            PageViewModel.hideModal('#modal-show-session');
        }

        function confirmStockoutSession() {
            if (vm.updateStockoutSessionLoading) {
                return;
            }
            vm.updateStockoutSessionLoading = true;
            serverGateway.ajax('confirm-stockout-session', {id: vm.detail.id}, {refId: vm.detail.refId, remarks: vm.detail.remarks, order_id: vm.detail.order_id}).then(function(response) {
                vm.updateStockoutSessionLoading = false;
                $.bootstrapGrowl('Session Updated.', {ele: 'body', type: 'success'});
                openDetailDialog(vm.detail);
            }, function (response) {
                vm.updateStockoutSessionLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printStockoutSessionItemsLabel(){
            serverGateway.ajax('print-stockout-session-items-label', {id: vm.detail.id},{ printer: vm.printer }).then(function (response) {
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    function InputSessionController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        vm.openDetailDialog = openDetailDialog;
        vm.deleteCancel = deleteCancel;
        vm.showCreateInputSessionItem = showCreateInputSessionItem;
        vm.createInputSessionItem = createInputSessionItem;
        vm.showScan = showScan;
        vm.proceedInputScanItem = proceedInputScanItem;
        vm.clearInputScan = clearInputScan;
        vm.removeInputSession = removeInputSession;
        vm.showEdit = showEdit;
        vm.updateInputSessionItem = updateInputSessionItem;
        vm.showQRUrl = showQRUrl;
        vm.showRequestItems = showRequestItems;
        vm.showImportDialog = showImportDialog;
        vm.showRFIDImportDialog = showRFIDImportDialog;
        vm.submitRFIDContent = submitRFIDContent;
        vm.rfidLabels = '';
        vm.publish = publish;
        vm.fillStockTransitId = fillStockTransitId;
        vm.salesChannelList = config.salesChannelList;

        vm.requestScanItem = requestScanItem;
        vm.adjustCurrentQty = adjustCurrentQty;
        vm.changeCurrentQty = changeCurrentQty;
        vm.updateScannedItem = updateScannedItem;
        vm.updatingScannedItem = false;
        vm.scannedRequestedItem = '';
        vm.scannedRequestedItemKeyPress = scannedRequestedItemKeyPress;
        vm.clearScannedItem = clearScannedItem;
        vm.requestedItem = {};
        vm.successSound = new Audio('/media/success.mp3');
        vm.warningSound = new Audio('/media/warning.mp3');
        vm.publishAndConfirm = publishAndConfirm;
        config.mobilePrinters.unshift({id: 0, description: 'Select Printer'});
        vm.mobilePrinters = config.mobilePrinters;
        vm.selectedMobilePrinterId = localStorage.getItem('selectedMobilePrinterId') == null ? 0 : parseInt(localStorage.getItem('selectedMobilePrinterId'));
        vm.onChangeMobilePrinter = onChangeMobilePrinter;
        vm.waitForDoubleScan = false;
        vm.showConfirmPrint = false;
        vm.confirmAndPrint = confirmAndPrint;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'save-session-item':  { path: 'inventory/input-sessions/{id}/items/save', method: 'POST' },
                    'delete-session-item':  { path: 'inventory/input-sessions/{id}/items/delete', method: 'POST' },
                    'list-session-item':  { path: 'inventory/input-sessions/{id}/items', method: 'GET' },
                    'publish':  { path: 'inventory/input-sessions/{id}/publish', method: 'POST' },
                    'show-session':  { path: 'inventory/input-sessions/{id}', method: 'GET' },
                    'fill-stock-transit-id':  { path: 'inventory/input-sessions/{id}/fill-stock-transit-id', method: 'POST' },
                    'fulfill-transit-request': { path: 'inventory/transit-requests/{id}/fulfill', method: 'POST' },
                    'received-transit-request': { path: 'inventory/transit-requests/{id}/received', method: 'POST' },
                    'importByRFID': { path: 'inventory/input-sessions/{id}/importByRfid', method: 'POST'},
                    'updateScannedItem':  { path: 'inventory/input-sessions/{id}/items/update', method: 'POST' },
                }
            });

            loadList();
            setInterval(processSavingQueue, 5000);
        }

        function processSavingQueue() {
            for (let i in vm.rows) {
                let row = vm.rows[vm.rows.length - i - 1];
                if (row.isSaving && !row.saveItemRequestFired) {
                    saveSessionItem(row, (success, message) => {
                        if (success) {
                            row.isSaving = false;
                            console.log('silent save item done: ' + row.hash);
                        } else {
                            console.log('silent save item failed: ' + row.hash + ' (' + message + ')');
                        }
                    })
                }
            }
        }

        function showCreateInputSessionItem() {
            vm.mode = 'create';
            vm.detail = null;
            PageViewModel.openModal('#modal-show-session');
            return false;
        }

        function openDetailDialog(row) {
            window.history.pushState(null, null, "/inventory/stockin-sessions/" + row.id + "?locationId=" + config.locationId);
            PageViewModel._isLoading = true;
            serverGateway.ajax('show-stockin-session', {id: row.id}).then(function(response) {
                PageViewModel._isLoading = false;
                PageViewModel.openModal('#modal-show-session');
                vm.detail = response.data.data;
            }, function (response) {
                PageViewModel._isLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function deleteCancel() {
            PageViewModel.hideModal('#modal-show-session');
            PageViewModel.hideModal('#modal-show-scan');
            PageViewModel.hideModal('#modal-import');
            return false;
        }

        function showScan() {
            vm.barcode = null;
            // vm.barcode = '{"SKU":"CTF-C-CIM-1811314.7RDX","QTY":100,"PO":"PO-00001","DATECODE":"AA1909"}'
            PageViewModel.openModal('#modal-show-scan');
            setTimeout(() => {
                $("#barcode").focus();
            }, 500)
            return false;
        }

        function clearInputScan() {
            vm.barcode = '';
            $("#barcode").focus();
            return false;
        }

        function proceedInputScanItem() {
            let barcode = vm.barcode;
            vm.deleteCancel();
            vm.showCreateInputSessionItem();
            let data = {};
            let parsed = false;
            try {
                let barcodeData = JSON.parse(barcode);
                if (barcodeData) {
                    if (typeof barcodeData != 'object') throw new Error('invalid format')
                    for (var key in barcodeData) {
                        barcodeData[key.toUpperCase()] = barcodeData[key];
                    }
                    if (barcodeData['SKU']) {
                        data.sku = barcodeData['SKU'];
                    }
                    if (barcodeData['QTY']) {
                        data.perBoxCount = barcodeData['QTY'];
                        data.boxCount = 1;
                    }
                    if (barcodeData['PO']) {
                        data.po = barcodeData['PO'];
                    }
                    if (barcodeData['DATECODE']) {
                        data.remarks = barcodeData['DATECODE'];
                    }
                    vm.detail = data;
                    parsed = true;
                }
            } catch (e) {
            }
            if (!parsed) {
                let lines = barcode.split("\n");
                if (lines.length) {
                    lines.forEach((line) => {
                        let lineDatas = line.split(/[:：]+/);
                        if (lineDatas.length == 2) {
                            let contentKey = lineDatas[0].trim().toUpperCase();
                            let contentValue = lineDatas[1].trim();
                            if (contentKey == 'SKU') {
                                data.sku = contentValue;
                                parsed = true;
                            }
                            if (contentKey == 'QTY') {
                                data.perBoxCount = parseInt(contentValue);
                                data.boxCount = 1;
                            }
                            if (contentKey == 'PO') {
                                data.po = contentValue;
                            }
                            if (contentKey == 'DATECODE') {
                                data.remarks = contentValue;
                            }
                        }
                    })
                }
                if (parsed) {
                    vm.detail = data;
                }
            }
            if (!parsed) {
                vm.detail = {
                    sku: barcode,
                    boxCount: 1,
                    perBoxCount: 1,
                }
            }
            
            $('input#sku').one('focus', function() {
              $(this).blur();
            });
            return false;
        }

        function fillPreview(data) {
            let items = [];
            if (data['sku']) {
                items.push('SKU: ' + data['sku']);
            }
            if (data['perBoxCount']) {
                items.push('Item Count: ' + data['perBoxCount']);
            }
            if (data['boxCount']) {
                items.push('Box Count: ' + data['boxCount']);
            }

            if(data['expiry_date']){
                items.push('Expiry Date: ' + moment(data['expiry_date']).format("YYYY-MM-DD") );
            }
            
            data.preview = items.join(', ');
        }

        function parseData(row, responseData) {
            let itemData = JSON.parse(responseData.data);

            row.id = responseData.id;
            for (var key in itemData) {
                row[key] = itemData[key];
            }

            row.expiry_date = ( row.expiry_date == null )? null : new Date(row.expiry_date);

            row.createdAt = responseData.createdAt;
            row.updatedBy = responseData.updatedBy;
            row.errors = responseData.errors;
            row.isValid = responseData.isValid;
            row.hasPOMismatchError = row.errors && !!row.errors.find((error) => error == 'Raw material not found in PO');
            row.hash = responseData.hash;
            row.rawMaterial = responseData.rawMaterial;
            row.patchedRawMaterial = responseData.patchedRawMaterial;
            row.patchedRawMaterialSKU = responseData.patchedRawMaterial ? responseData.patchedRawMaterial.name : null;
            row.finishedGood = responseData.finishedGood;
            row.requestedQTY = responseData.requestedQTY;
            row.locationHistory = responseData.locationHistory;
            return row;
        }

        function loadList() {
            serverGateway.ajax('list-session-item', {id: config.inputSessionId}).then(function(response) {
                vm.rows = [];
                response.data.data.forEach((row) => {
                    let data = parseData({}, row)
                    fillPreview(data);
                    vm.rows.push(data);
                })
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function createInputSessionItem() {
            let item = [];
            for (var key in vm.detail) {
                let value = vm.detail[key];
                item.push(key.toUpperCase() + " : " + value);
            }
            if (!vm.detail.hash) {
                vm.detail.hash = makeid(16);
            }
            vm.detail.createdAt = new Date();
            vm.detail.isSaving = true;
            fillPreview(vm.detail);
            let data = JSON.parse(JSON.stringify(vm.detail));
            vm.rows.unshift(data)
            PageViewModel.hideModal('#modal-show-session');
            saveSessionItem(data, (success, message) => {
                if (!success) {
                    $.bootstrapGrowl(message, {ele: 'body', type: 'error'});
                } else {
                    data.isSaving = false;
                }
            });
            return false;
        }

        function saveSessionItem(row, callback) {
            if (row.saveItemRequestFired) {
                return;
            }
            let sendData = JSON.parse(JSON.stringify(row));
            delete sendData['isSaving'];
            delete sendData['id'];
            delete sendData['hash'];
            row.saveItemRequestFired = true;
            if (sendData.isDelete) {
                serverGateway.ajax('delete-session-item', {id: config.inputSessionId}, {hash: row.hash}).then(function (response) {
                    row.saveItemRequestFired = false;
                    if (callback) {
                        callback(true);
                    }
                }, function () {
                    row.saveItemRequestFired = false;
                    if (callback) {
                        callback(false);
                    }
                });
            } else {
                serverGateway.ajax('save-session-item', {id: config.inputSessionId}, {hash: row.hash, data: JSON.stringify(sendData)}).then(function(response) {
                    row.saveItemRequestFired = false;
                    row = parseData(row, response.data.data);
                    if (callback) {
                        callback(true);
                    };
                }, function (response) {
                    row.saveItemRequestFired = false;
                    if (callback) { 
                        callback(false, response.data.message);
                    };
                });
            }
            return false;
        }

        function removeInputSession(row) {
            if (!confirm("Confirm Delete?")) {
                return false;
            }
            PageViewModel.hideModal('#modal-show-session');
            row.isSaving = true;
            row.isDelete = true;
            saveSessionItem(row, (success, message) => {
                if (success) {
                    let index = vm.rows.findIndex((vmRow, index) => {
                        return row.hash == vmRow.hash;
                    })
                    vm.rows.splice(index, 1);
                } else {
                    $.bootstrapGrowl(message, {ele: 'body', type: 'error'});
                }
            })
            // let index = vm.rows.findIndex((vmRow, index) => {
            //     return row.hash == vmRow.hash;
            // })
            // let hash = row.hash;
            // vm.rows.splice(index, 1);
            // serverGateway.ajax('delete-session-item', {id: config.inputSessionId}, {hash: hash}).then(function(response) {
            // }, function (response) {
            //     $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            // });
            return false;
        }

        function showEdit(row) {
            vm.detail = row;
            vm.mode = 'update';
            PageViewModel.openModal('#modal-show-session');
            return false;
        }

        function updateInputSessionItem() {
            vm.detail.isSaving = true;
            fillPreview(vm.detail);
            let data = vm.detail;
            PageViewModel.hideModal('#modal-show-session');
            saveSessionItem(data, (success, message) => {
                if (!success) {
                    $.bootstrapGrowl(message, {ele: 'body', type: 'error'});
                } else {
                    data.isSaving = false;
                }
            });
            return false;
        }

        function publish(confirmAction = false, confirmCallback = null) {
            if (!confirmAction && !confirm('Confirm publish?')) {
                return false;
            }
            if (vm.updatingScannedItem || vm.publishLoading) {
                return false;
            }
            vm.publishLoading = true;
            serverGateway.ajax('publish', {id: config.inputSessionId}).then(function(response) {
                if(confirmCallback == null){
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    vm.publishLoading = false;
                    window.location.href = config.relatedUrl;
                }
                else{
                    confirmCallback();
                }
            }, function (response) {
                vm.publishLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function showQRUrl(url) {
            PageViewModel.openModal('#modal-qr-url');
            var apiToken = localStorage.getItem('api-auth-token');
            if (!qrCode) {
                qrCode = new QRCode(document.getElementById("qr-url"), { text: url,  width: 300,
                height: 300});
            }
            return false;
        }

        function showRequestItems() {
            vm.requestItemLoading = true;
            clearInterval(vm.requestItemListTimer);
            serverGateway.ajax('show-session', {id: config.inputSessionId}).then(function(response) {
                vm.requestItemLoading = false;
                vm.stockTransitRequest = response.data.data.stockTransitRequest;
                vm.requestedItems = response.data.data.requestedItems;
                vm.requestedItems.sort((a,b) => {
                    if(a.rawMaterial.name.toUpperCase() < b.rawMaterial.name.toUpperCase()){
                        return -1;
                    }
                    if(a.rawMaterial.name.toUpperCase() > b.rawMaterial.name.toUpperCase()){
                        return 1;
                    }
                    return 0;
                });
                vm.uncompleteItems = vm.requestedItems.filter(requestedItem => {
                    return requestedItem.fulfilledQTY == 0;
                });
                PageViewModel.openModal('#modal-request-items');
                // $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                vm.requestItemListTimer = setInterval(function() {
                    updateShowRequestItems();
                }, 3000);
            }, function (response) {
                vm.requestItemLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function updateShowRequestItems(){
            serverGateway.ajax('show-session', {id: config.inputSessionId}).then(function(response) {
                vm.stockTransitRequest = response.data.data.stockTransitRequest;
                let tempRequestedItems = response.data.data.requestedItems;
                tempRequestedItems.sort((a,b) => {
                    if(a.rawMaterial.name.toUpperCase() < b.rawMaterial.name.toUpperCase()){
                        return -1;
                    }
                    if(a.rawMaterial.name.toUpperCase() > b.rawMaterial.name.toUpperCase()){
                        return 1;
                    }
                    return 0;
                });
                vm.requestedItems = tempRequestedItems;
                vm.uncompleteItems = vm.requestedItems.filter(requestedItem => {
                    return requestedItem.fulfilledQTY == 0;
                });
            });
        }

        function showImportDialog() {
            PageViewModel.openModal('#modal-import');
            return false;
        }

        function fillStockTransitId() {
            vm.fillStockTransitLoading = true;
            serverGateway.ajax('fill-stock-transit-id', {id: config.inputSessionId}).then(function(response) {
                vm.fillStockTransitLoading = false;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.fillStockTransitLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function showRFIDImportDialog(){
            PageViewModel.openModal('#modal-rfid-import');
            return false;
        }

        function submitRFIDContent(){
            serverGateway.ajax('importByRFID',{id: config.inputSessionId},{data: vm.rfidLabels}).then(function(response){
                console.log(response.data.status);
                if(response.data.status == 'OK'){
                    window.location.reload();
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function requestScanItem(requestedItem){
            if(!config.useMobileScan){
                return;
            }
            if(config.stockTransitStatus == 'pending_fulfill' && vm.selectedMobilePrinterId == 0){
                $.bootstrapGrowl('Please select mobile printer', {ele: 'body', type: 'error'});
                return 
            }
            vm.scannedRequestedItem = '';
            vm.waitForDoubleScan = false;
            vm.requestedItem = requestedItem;
            vm.currentItemQty = requestedItem.fulfilledQTY > 0 ? requestedItem.fulfilledQTY : requestedItem.requestedQTY;
            vm.showConfirmPrint = false;
            PageViewModel.openModal('#modal-request-items-scan');
            $('#modal-request-items-scan .sku-input').focus();
        }

        function adjustCurrentQty(value){
            if(vm.updatingScannedItem || vm.publishLoading){
                return;
            }
            const newCurrentQty = parseInt(vm.currentItemQty) + parseInt(value);
            vm.currentItemQty = newCurrentQty < 0 ? 0 : newCurrentQty;
            // $('#modal-request-items-scan .sku-input').focus();
        }

        function changeCurrentQty(){
            vm.currentItemQty = parseInt(vm.currentItemQty) < 0 ? 0 : parseInt(vm.currentItemQty);
        }
        
        function updateScannedItem(){
            if(vm.updatingScannedItem || vm.publishLoading || vm.showConfirmPrint){
                return;
            }
            vm.updatingScannedItem = true;
            if(config.stockTransitStatus == 'in_transit' && !vm.waitForDoubleScan){//need to scan input session item label first
                const labelData = convertInputSessionItemLabel(vm.scannedRequestedItem);
                if(labelData == false){
                    warningSound();
                    $.bootstrapGrowl('Invalid label format', {ele: 'body', type: 'error'});
                    vm.updatingScannedItem = false;
                    return;
                }
                if(labelData.TRANSITID != config.stockTransitId){
                    warningSound();
                    $.bootstrapGrowl('Wrong transit', {ele: 'body', type: 'error'});
                    vm.updatingScannedItem = false;
                    return;
                }
                if(labelData.SKU != vm.requestedItem.rawMaterial.name){
                    warningSound();
                    $.bootstrapGrowl('Wrong sku is scanned', {ele: 'body', type: 'error'});
                    vm.updatingScannedItem = false;
                    return;
                }
                successSound();
                vm.waitForDoubleScan = true;
                vm.updatingScannedItem = false;
                clearScannedItem();
                return;
            }
            const barcodeData = convertWarehouseBarcode(vm.scannedRequestedItem);
            if(barcodeData == false){
                warningSound();
                $.bootstrapGrowl('Invalid label format', {ele: 'body', type: 'error'});
                vm.updatingScannedItem = false;
                vm.showConfirmPrint = false;
                return;
            }
            vm.scannedRequestedItem = barcodeData.sku;
            if(vm.requestedItem.rawMaterial.name != vm.scannedRequestedItem){
                warningSound();
                if(config.stockTransitStatus == 'in_transit'){
                    $.bootstrapGrowl('Wrong location sku is scanned', {ele: 'body', type: 'error'});
                }
                else{
                    $.bootstrapGrowl('Wrong sku is scanned', {ele: 'body', type: 'error'});
                }
                vm.updatingScannedItem = false;
                vm.showConfirmPrint = false;
                return;
            }
            if(vm.stockTransitRequest.status == 'pending_fulfill' && !config.isPrintingBatchTransit){
                successSound();
                vm.updatingScannedItem = false;
                vm.showConfirmPrint = true;
            }
            else if(vm.stockTransitRequest.status == 'in_transit' || (vm.stockTransitRequest.status == 'pending_fulfill' && config.isPrintingBatchTransit)){
                confirmAndPrint();
            }
        }

        function scannedRequestedItemKeyPress(event){
            if(event.which == 13){//is pressed enter
                if(vm.lockEnterKey){
                    return;
                }
                vm.lockEnterKey = true;
                setTimeout(() => {

                    vm.lockEnterKey = false;
                }, 1000);
                updateScannedItem();
            }
        }

        function clearScannedItem(){
            if(vm.updatingScannedItem || vm.publishLoading || vm.showConfirmPrint){
                return;
            }
            vm.scannedRequestedItem = '';
            $('#modal-request-items-scan .sku-input').focus();
        }

        function confirmAndPrint(){
            vm.updatingScannedItem = true;
            const data = {
                sku: vm.scannedRequestedItem,
                qty: vm.currentItemQty,
                hash: makeid(16),
                mobilePrinterId: vm.selectedMobilePrinterId
            };
            serverGateway.ajax('updateScannedItem', {id: config.inputSessionId}, data).then(function(response){
                // vm.requestedItems.map(requestedItem => {
                //     if(requestedItem.rawMaterial.name != vm.scannedRequestedItem){
                //         return requestedItem;
                //     }
                //     requestedItem.fulfilledQTY = vm.currentItemQty;
                //     requestedItem.outstandingQTY = requestedItem.requestedQTY - vm.currentItemQty;
                //     return requestedItem;
                // });
                let tempRequestedItems = response.data.data.requestedItems;
                tempRequestedItems.sort((a,b) => {
                    if(a.rawMaterial.name.toUpperCase() < b.rawMaterial.name.toUpperCase()){
                        return -1;
                    }
                    if(a.rawMaterial.name.toUpperCase() > b.rawMaterial.name.toUpperCase()){
                        return 1;
                    }
                    return 0;
                });
                vm.requestedItems = tempRequestedItems;
                successSound();
                if(vm.stockTransitRequest.status == 'pending_fulfill'){
                    $.bootstrapGrowl("Printing", {ele: 'body', type: 'success'});
                }
                else if(vm.stockTransitRequest.status == 'in_transit'){
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }
                PageViewModel.hideModal('#modal-request-items-scan');
                vm.uncompleteItems = vm.requestedItems.filter(requestedItem => {
                    return requestedItem.fulfilledQTY == 0;
                });
                vm.updatingScannedItem = false;
                if(vm.uncompleteItems.length == 0){
                    $.bootstrapGrowl("All done! Publish and confirm now", {ele: 'body', type: 'success'});
                    publishAndConfirm(false);
                }
            }, function(response){
                warningSound();
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.updatingScannedItem = false;
            });
        }

        function successSound(){
            vm.successSound.pause();
            vm.successSound.currentTime = 0;
            vm.successSound.play();
        }
        function warningSound(){
            // return;
            vm.warningSound.pause();
            vm.warningSound.currentTime = 0;
            vm.warningSound.play();
        }

        function publishAndConfirm(confirmAction = true){
            if(confirmAction && !confirm('Confirm publish and fulfilled/received?')){
                return false;
            }
            const confirmCallback = () => {
                let confirmAction = null;
                if(vm.stockTransitRequest.status == 'pending_fulfill'){
                    confirmAction = 'fulfill-transit-request';
                }
                else if(vm.stockTransitRequest.status == 'in_transit'){
                    confirmAction = 'received-transit-request';
                }
                else{
                    $.bootstrapGrowl('Wrong status, cannot confirm', {ele: 'body', type: 'error'});
                    return;
                }
                serverGateway.ajax(confirmAction, {id: vm.stockTransitRequest.id}, {mobilePrinterId: vm.selectedMobilePrinterId}).then(function(response) {
                    vm.publishLoading = true;//wait for moving page
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                    window.location.href = config.relatedUrl;
                }, function (response) {
                    vm.publishLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            };
            publish(true, confirmCallback);
        }

        function convertWarehouseBarcode(value){
            let barcode = value;
            let data = {};
            let parsed = false;
            try {
                let barcodeData = JSON.parse(barcode);
                if (barcodeData) {
                    if (typeof barcodeData != 'object') throw new Error('invalid format')
                    for (var key in barcodeData) {
                        barcodeData[key.toUpperCase()] = barcodeData[key];
                    }
                    if (barcodeData['SKU']) {
                        data.sku = barcodeData['SKU'];
                    }
                    if (barcodeData['QTY']) {
                        data.perBoxCount = barcodeData['QTY'];
                        data.boxCount = 1;
                    }
                    if (barcodeData['PO']) {
                        data.po = barcodeData['PO'];
                    }
                    if (barcodeData['DATECODE']) {
                        data.remarks = barcodeData['DATECODE'];
                    }
                    if(barcodeData['TRANSITID'] && barcodeData['INPUTSESSIONITEMID']){
                        return false;
                    }
                    parsed = true;
                }
            } catch (e) {
            }
            if (!parsed) {
                let lines = barcode.split("\n");
                if (lines.length) {
                    lines.forEach((line) => {
                        let lineDatas = line.split(/[:：]+/);
                        if (lineDatas.length == 2) {
                            let contentKey = lineDatas[0].trim().toUpperCase();
                            let contentValue = lineDatas[1].trim();
                            if (contentKey == 'SKU') {
                                data.sku = contentValue;
                                parsed = true;
                            }
                            if (contentKey == 'QTY') {
                                data.perBoxCount = parseInt(contentValue);
                                data.boxCount = 1;
                            }
                            if (contentKey == 'PO') {
                                data.po = contentValue;
                            }
                            if (contentKey == 'DATECODE') {
                                data.remarks = contentValue;
                            }
                        }
                    })
                }
            }
            if (!parsed) {
                data = {
                    sku: barcode,
                    boxCount: 1,
                    perBoxCount: 1,
                }
            }
            return data;
        }

        function convertInputSessionItemLabel(value){
            let labelData;
            try{
                labelData = JSON.parse(value);
            }
            catch(e){
                return false;
            }
            if(typeof labelData != 'object'){
                return false;
            }
            for (var key in labelData) {
                labelData[key.toUpperCase()] = labelData[key];
            }
            if(!labelData['SKU'] || !labelData['QTY'] || !labelData['TRANSITID']){
                return false;
            }
            return labelData;
        }

        function onChangeMobilePrinter(){
            localStorage.setItem('selectedMobilePrinterId', vm.selectedMobilePrinterId);
        }
    }

    function FinishedGoodsMappingController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){
        
        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;

        vm.detail           = {};
        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        vm.textFilter = '';

        vm.applyFilter = applyFilter;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {

                }
            });
            

            let query = new URLSearchParams(
                {
                    keyword: vm.textFilter
                }
            );

            vm.gridDataSource = new GridDataSource({
                resoucesUrl : config.dataUrl+"?"+query,
                gateway     : serverGateway,
                perPage     : 99
            });

            var fields = [];
            for(var i, c = config.columns.length; i < c; i++){

                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function(){
                return PageViewModel.rows
            }, function(rows){
                vm.rows = rows
            }, true);

            $timeout(function(){PageViewModel.update();}, 500);

            $interval(function(){
                PageViewModel.update();
            },5000);

        }

        function applyFilter(){
            console.log('applyFilter START');
            console.log(vm.textFilter);
            let query = new URLSearchParams(
              {
                keyword: vm.textFilter
              }  
            );
    
            vm.gridDataSource.setResourceUrl(config.dataUrl+"?"+query);
            PageViewModel.setGridDataSource(vm.gridDataSource);
            PageViewModel.load();
            PageViewModel.update();
        }
    }


    function RobotInventoryController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;

        vm.filterSearch     = filterSearch;
        vm.textFilter       = '';
        vm.showEmpty    = false;

        vm.openDetailDialog = openDetailDialog;
        vm.updateSKU        = updateSKU;
        vm.replenishStock   = replenishStock;
        vm.clearInventory   = clearInventory;
        vm.issueCommand     = issueCommand;
        vm.copyToBox        = copyToBox;
        vm.detail           = {};
        vm.mode             = '';
        vm.isEmpty          = false;
        vm.isLoading        = PageViewModel._isLoading;

        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'createOrUpdateBoxName' : { path: 'robot-inventory/createOrUpdateBoxName', method: 'POST'},
                    'clearInventory' : { path: 'robot-inventory/clearInventory/{id}', method: 'POST'},
                    'issueCommand' : { path: 'robot-inventory/issueCommand', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                perPage: 999,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            var fields = [];
            for (var i, c = config.columns.length; i < c; i++){
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function(){
                return PageViewModel.rows;
            },function(rows){
                vm.rows = rows;
            },true);
            if(config.initKeyFilter){
                vm.textFilter = config.initKeyFilter;
                vm.filterSearch();
            }

            $timeout(function(){PageViewModel.update();}, 500);

            $interval(function () {
                PageViewModel.update();
            }, 5000);

            console.log(config.requestItem);
        }

        function filterSearch(){
            console.log(vm.textFilter);
            let query = new URLSearchParams(
                {
                    keyword: vm.textFilter,
                    showEmpty: vm.showEmpty
                }
            );
            vm.gridDataSource.setResourceUrl(config.dataUrl+"?"+query);
            PageViewModel.setGridDataSource(vm.gridDataSource);
            PageViewModel.load();
        }

        function openDetailDialog(row, mode){
            initDetail(row);
            vm.mode = mode;
            $('#modal').modal('show');
        }

        function initDetail(row){
            vm.detail = row
            if(vm.detail == null){
                vm.detail = {
                    qty: 0
                };
            }
            vm.isEmpty = vm.detail.qty == 0;
            vm.detail.canBeFetched = false;
            vm.detail.isFetched = false;
            if(row){
                vm.detail.canBeFetched = (row.status == 'in_shelf');
                vm.detail.isFetched = (row.current_location == config.workStationPoint);
            } 
        }

        function updateSKU(){
            serverGateway.ajax('createOrUpdateBoxName',null,{mode: vm.mode,detail: vm.detail}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                updateDetailModal(vm.detail.id);
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function replenishStock(){
            let count = prompt("Please enter the amount:");
            if(!count || count == '') return;
            count = Number(count);
            if(count < 0 || isNaN(count)) { $.bootstrapGrowl('Please enter a valid amount', {ele: 'body', type: 'error'}); return;}
            vm.detail.qty = Number(vm.detail.qty) +  count;
            vm.mode = 'edit';
            vm.updateSKU();
        }

        function clearInventory(id){
            if(!confirm('This cannot be undone, are you sure?')) return;
            serverGateway.ajax('clearInventory',{id: id}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                updateDetailModal(id);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function issueCommand(id, command){
            serverGateway.ajax('issueCommand',null,{id: id, command: command}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                updateDetailModal(id);
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateDetailModal(id){
            PageViewModel.update();
                $timeout(function(){
                    vm.rows.forEach((val,index) => {
                        if(val.id == id){
                            initDetail(vm.rows[index]);
                        }
                    });
                },500);
        }


        function copyToBox(sku,qty){
            if(vm.mode == 'create' || vm.isEmpty){
                vm.detail.qty = Number(qty);
                vm.detail.sku = sku;
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
            }
        }
    }

    function StockTransitBulkActionController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){
        var serverGateway, vm = this;

        vm.mode = config.defaultMode ? config.defaultMode : null;
        vm.input = config.input ? config.input : null;

        vm.submitBulkAction = submitBulkAction;
        vm.isLoading        = false;

        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'bulk-print-picking-label': { path: 'inventory/transit-requests/bulk-picking-label/print', method: 'POST' },
                    'bulk-download-picking-label': { path: 'inventory/transit-requests/bulk-picking-label/download', method: 'POST' },
                    'bulk-fulfil-transit': { path: 'inventory/transit-requests/bulk-fulfil', method: 'POST' },
                    'bulk-receive-transit': { path: 'inventory/transit-requests/bulk-receive', method: 'POST' },
                }
            });

            window.addEventListener('keydown', barcodeOnKeydown, false)
        }

        function barcodeOnKeydown(event) {
            if (event.keyCode == 13 && vm.autoNext) {
                // enter
                vm.submitBulkAction(() => {
                    vm.input = '';
                });
            }
            $scope.$apply();
        }

        function submitBulkAction(callback = null) {
            vm.isLoading = true;
            let lines = vm.input.split("\n");
            let ids = []
            for (let i in lines) {
                let line = lines[i]
                let data = null
                try {
                    data = JSON.parse(line)
                } catch (error) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Cannot parse JSON', {ele: 'body', type: 'error'});
                    return;
                }
                if (!data) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Cannot parse JSON', {ele: 'body', type: 'error'});
                    return;
                }
                if (!data.ids) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('ids not found', {ele: 'body', type: 'error'});
                    return;
                }
                for (let j in data.ids) {
                    ids.push(data.ids[j])
                }
            }
            if (vm.mode == 'print_picking_label') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(',')}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_picking_label_summary') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'summary'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_picking_label_transit') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'transits'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_picking_label_pick') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'picks'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_a4_picking_label') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_a4_picking_label_summary') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'summary', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_a4_picking_label_transit') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'transits', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'print_a4_picking_label_pick') {
                serverGateway.ajax('bulk-print-picking-label', '', {ids: ids.join(','), mode: 'picks', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_picking_label_summary') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'summary'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_picking_label_transit') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'transits'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_picking_label_pick') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'picks'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_a4_picking_label_summary') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'summary', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_a4_picking_label_transit') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'transits', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'download_a4_picking_label_pick') {
                serverGateway.ajax('bulk-download-picking-label', '', {ids: ids.join(','), mode: 'picks', paperSize: 'a4'}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    window.open(response.data.data.data)
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'fulfil_transit') {
                serverGateway.ajax('bulk-fulfil-transit', '', {autofill: 'Y', ids: ids.join(',')}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else if (vm.mode == 'receive_transit') {
                serverGateway.ajax('bulk-receive-transit', '', {autofill: 'Y', ids: ids.join(',')}).then(function(response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                    if (callback) callback();
                }, function (response) {
                    vm.isLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else {
                vm.isLoading = false;
            }
        }
    }

    function makeid(length) {
       var result           = '';
       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    function moneyFormat(n, c, d, t) {
        var
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? "," : t,
            s = n < 0 ? "-" : "",
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
           return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
     };


    function isNormalInteger(str) {
        str = String(str);
        var n = ~~Number(str);
        return String(n) === str && n >= 0;
    }
})();
